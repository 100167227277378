import { MyModal } from "./MyModal";
import React from "react";
import "./../../styles/HowTo.css";

interface HowToPlayProps {
  isOpen: boolean;
  close: () => void;
}

export function HowToPlay({ isOpen, close }: HowToPlayProps) {
  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container">
        <h2 className="how-to-h2">How To Play</h2>

        <ul className="how-to-ul" style={{ textAlign: "left" }}>
          <li>You have 8 guesses, you can guess any current NHL player!</li>
          <li>
            A <span className="span-green">green cell</span> means that attibute
            is correct!
          </li>
          <li>
            A <span className="span-yellow">yellow cell</span> in the <b>age</b>{" "}
            column means you are within <b>2</b> years, in the <b>number</b> column
            means you are within <b>5</b> numbers.
          </li>
          <li>
            A <span className="span-yellow">yellow cell</span> in the <b>team</b>{" "}
            column means the mystery player played for that team at one point in their careers.
          </li>
          <li>
            No color indicates no similarities or match to the mystery
            players&#39; attribute.
          </li>
          <li>Out of ideas? Check out the silhouette!</li>
          <li>Too easy? Check out hard mode in settings.</li>
          <li>Come back everyday for a new mystery player!</li>
        </ul>
      </div>
    </MyModal>
  );
}
