import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import '../styles/Search.css';
import {
  players,
  getName,
} from "../core/players2023";

interface PlayerInputProps {
  inputRef: React.RefObject<HTMLInputElement>;
  currentGuess: string;
  setCurrentGuess: (guess: string) => void;
  placeholder: string;
  disabled: boolean;
}

export function PlayerInput({
  inputRef,
  currentGuess,
  setCurrentGuess,
  placeholder,
  disabled,
}: PlayerInputProps) {
  const [suggestions, setSuggestions] = useState<string[]>([]);


  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) =>
        setSuggestions(
          players
            .map((c) => getName(c))
            .filter((playerName) => {
              playerName = playerName.toLowerCase()
              value= value.toLowerCase()
              return playerName.includes(value)
              }
            ).slice(0,5)
        )
      }
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={(suggestion) => suggestion}
      renderSuggestion={(suggestion) => (
        <div className="suggestion">
          {suggestion}
        </div>
      )}
      containerProps={{
        className: "div-container",
      }}
      inputProps={{
        ref: inputRef,
        className: "search-bar",
        placeholder: placeholder,
        disabled: disabled,
        value: currentGuess,
        onChange: (_e, { newValue }) => { 
          setCurrentGuess(newValue)
        },
      }}
      renderSuggestionsContainer={({ containerProps, children }) => (
        <div
          {...containerProps}
          className={"suggestions-dropdown"}
        >
          {children}
        </div>
      )}
    />
  );
}
