import { Guess } from "../core/guess";
import { GuessRow } from "./GuessRow";
import React from "react";
import { SettingsData } from "../hooks/useSettings";
import { useRef, useEffect } from "react";
import { MutableRefObject } from "react";

interface GuessesProps {
  rowCount: number;
  guesses: Guess[];
  settingsData: SettingsData;
  gameOver: boolean;
  winner: boolean;
  playerInputRef?: React.RefObject<HTMLInputElement>;
}

export function Guesses({
  rowCount,
  guesses,
  settingsData,
  gameOver,
  winner,
  playerInputRef,
}: GuessesProps) {
  
  useEffect(() => {
    if (bottomRef.current) {
      scrollToBottom();
    }
  }, [guesses]);

  const bottomRef = useRef() as MutableRefObject<HTMLDivElement>;

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <div className="table-container">
      <div className="table-grid">
        <div className="table-head">
          <div className="table-row">
            <div></div>
            <div>Team</div>
            <div>Conf</div>
            <div>Div</div>
            <div>Pos</div>
            <div>Sh</div>
            <div>Age</div>
            <div>Ctry</div>
            <div>#</div>
          </div>
        </div>
        <div className="table-body">
          {guesses.map((obj, index) => {
            return (
              <GuessRow
                key={index}
                guess={guesses[index]}
                settingsData={settingsData}
              />
            );
          })}
          <div ref={bottomRef}></div>
        </div>
      </div>
    </div>
  );
}
