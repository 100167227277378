export interface Player {
  name: string;
  team: string;
  pos: string;
  ctry: string;
  number: string;
  conference: string;
  division: string;
  DOB: string;
  shoots: string;
  img: string;
  former: Array<string>;
}

export const availablePlayers: Player[] = [
  {
    name: "Derek Grant",
    pos: "C",
    number: "38",
    shoots: "L",
    DOB: "04/20/90",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2525884.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["OTT", "CGY", "BUF", "NSH", "PIT", "PHI"],
  },
  {
    name: "Adam Henrique",
    pos: "C",
    number: "14",
    shoots: "L",
    DOB: "02/06/90",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5074.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["NJD"],
  },
  {
    name: "Mason McTavish",
    pos: "C",
    number: "37",
    shoots: "L",
    DOB: "01/30/03",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4874718.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Ryan Strome",
    pos: "C",
    number: "16",
    shoots: "R",
    DOB: "01/11/93",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562636.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["NYI", "EDM", "NYR"],
  },
  {
    name: "Trevor Zegras",
    pos: "C",
    number: "11",
    shoots: "L",
    DOB: "03/20/01",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565230.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Max Comtois",
    pos: "W",
    number: "44",
    shoots: "L",
    DOB: "01/08/99",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233885.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Max Jones",
    pos: "W",
    number: "49",
    shoots: "L",
    DOB: "02/17/98",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024980.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brock McGinn",
    pos: "W",
    number: "26",
    shoots: "L",
    DOB: "02/02/94",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3067868.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CAR", "PIT"],
  },
  {
    name: "Jakob Silfverberg",
    pos: "W",
    number: "33",
    shoots: "R",
    DOB: "10/13/90",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590549.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["OTT"],
  },
  {
    name: "Troy Terry",
    pos: "W",
    number: "19",
    shoots: "R",
    DOB: "09/10/97",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942905.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Frank Vatrano",
    pos: "W",
    number: "77",
    shoots: "L",
    DOB: "03/14/94",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3527554.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["BOS", "FLA", "NYR"],
  },
  {
    name: "Nathan Beaulieu",
    pos: "D",
    number: "28",
    shoots: "L",
    DOB: "12/05/92",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562598.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["MON", "BUF", "WPG"],
  },
  {
    name: "Cam Fowler",
    pos: "D",
    number: "4",
    shoots: "L",
    DOB: "12/05/91",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5495.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "John Gibson",
    pos: "G",
    number: "36",
    shoots: "L",
    DOB: "07/14/93",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590824.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Anthony Stolarz",
    pos: "G",
    number: "41",
    shoots: "L",
    DOB: "01/20/94",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3067313.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Barrett Hayton",
    pos: "C",
    number: "29",
    shoots: "L",
    DOB: "06/09/00",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352699.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Nick Schmaltz",
    pos: "C",
    number: "8",
    shoots: "R",
    DOB: "02/23/96",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114770.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["CHI"],
  },
  {
    name: "Lawson Crouse",
    pos: "W",
    number: "67",
    shoots: "L",
    DOB: "06/23/97",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899951.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Christian Fischer",
    pos: "W",
    number: "36",
    shoots: "R",
    DOB: "04/15/97",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904170.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Zack Kassian",
    pos: "W",
    number: "44",
    shoots: "R",
    DOB: "01/24/91",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5194.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["BUF", "VAN", "EDM"],
  },
  {
    name: "Clayton Keller",
    pos: "W",
    number: "9",
    shoots: "L",
    DOB: "07/29/98",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024857.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Jakub Voracek",
    pos: "W",
    number: "93",
    shoots: "L",
    DOB: "08/15/89",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3781.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: ["PHI", "CBJ"],
  },
  {
    name: "Connor Ingram",
    pos: "G",
    number: "39",
    shoots: "L",
    DOB: "03/31/97",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063522.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NSH"],
  },
  {
    name: "Karel Vejmelka",
    pos: "G",
    number: "70",
    shoots: "L",
    DOB: "05/25/96",
    team: "ARI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942065.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Patrice Bergeron",
    pos: "C",
    number: "37",
    shoots: "R",
    DOB: "07/24/85",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2280.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Charlie Coyle",
    pos: "C",
    number: "13",
    shoots: "R",
    DOB: "03/02/92",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2555315.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["MIN"],
  },
  {
    name: "David Krejci",
    pos: "C",
    number: "46",
    shoots: "R",
    DOB: "04/28/86",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3343.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Pavel Zacha",
    pos: "C",
    number: "18",
    shoots: "L",
    DOB: "04/06/97",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899949.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: ["NJD"],
  },
  {
    name: "Tyler Bertuzzi",
    pos: "W",
    number: "59",
    shoots: "L",
    DOB: "02/24/95",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042056.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["DET"],
  },
  {
    name: "Jake DeBrusk",
    pos: "W",
    number: "74",
    shoots: "L",
    DOB: "10/17/96",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900240.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Nick Foligno",
    pos: "W",
    number: "17",
    shoots: "L",
    DOB: "10/31/87",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3535.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["CBJ", "TOR"],
  },
  {
    name: "Taylor Hall",
    pos: "W",
    number: "71",
    shoots: "L",
    DOB: "11/14/91",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5428.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["EDM", "NJD", "ARI", "BUF"],
  },
  {
    name: "Brad Marchand",
    pos: "W",
    number: "63",
    shoots: "L",
    DOB: "05/11/88",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3852.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "David Pastrnak",
    pos: "W",
    number: "88",
    shoots: "L",
    DOB: "05/25/96",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114778.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Brandon Carlo",
    pos: "D",
    number: "25",
    shoots: "R",
    DOB: "11/26/96",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904175.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Matt Grzelcyk",
    pos: "D",
    number: "48",
    shoots: "L",
    DOB: "01/05/94",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069448.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Hampus Lindholm",
    pos: "D",
    number: "27",
    shoots: "L",
    DOB: "01/20/94",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2968818.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["ANA"],
  },
  {
    name: "Charlie McAvoy",
    pos: "D",
    number: "73",
    shoots: "R",
    DOB: "12/21/97",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3988803.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Dmitry Orlov",
    pos: "D",
    number: "81",
    shoots: "L",
    DOB: "07/23/91",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5646.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: ["WSH"],
  },
  {
    name: "Jeremy Swayman",
    pos: "G",
    number: "1",
    shoots: "L",
    DOB: "11/24/98",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4712036.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Linus Ullmark",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "07/31/93",
    team: "BOS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069285.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["BUF"],
  },
  {
    name: "Dylan Cozens",
    pos: "C",
    number: "24",
    shoots: "R",
    DOB: "02/09/01",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565228.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Tyson Jost",
    pos: "C",
    number: "17",
    shoots: "L",
    DOB: "03/14/98",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024879.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["COL", "MIN"],
  },
  {
    name: "Peyton Krebs",
    pos: "C",
    number: "19",
    shoots: "L",
    DOB: "01/26/01",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565238.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Casey Mittelstadt",
    pos: "C",
    number: "37",
    shoots: "L",
    DOB: "11/22/98",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233575.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Zemgus Girgensons",
    pos: "W",
    number: "28",
    shoots: "L",
    DOB: "01/05/94",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2968829.png",
    conference: "East",
    division: "Atl",
    ctry: "LVA",
    former: [],
  },
  {
    name: "Jordan Greenway",
    pos: "W",
    number: "12",
    shoots: "L",
    DOB: "02/16/97",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900260.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Victor Olofsson",
    pos: "W",
    number: "71",
    shoots: "L",
    DOB: "07/18/95",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151096.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Jeff Skinner",
    pos: "W",
    number: "53",
    shoots: "L",
    DOB: "05/16/92",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5540.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CAR"],
  },
  {
    name: "Kyle Okposo",
    pos: "W",
    number: "21",
    shoots: "R",
    DOB: "04/16/88",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3859.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["NYI"],
  },
  {
    name: "Jack Quinn",
    pos: "W",
    number: "22",
    shoots: "R",
    DOB: "09/19/01",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697389.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Tage Thompson",
    pos: "W",
    number: "72",
    shoots: "R",
    DOB: "10/30/97",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024988.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Alex Tuch",
    pos: "W",
    number: "89",
    shoots: "R",
    DOB: "05/10/96",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114766.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["VGK"],
  },
  {
    name: "Rasmus Dahlin",
    pos: "D",
    number: "26",
    shoots: "L",
    DOB: "04/13/00",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4294163.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Henri Jokiharju",
    pos: "D",
    number: "10",
    shoots: "R",
    DOB: "06/17/99",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233705.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Ilya Lyubushkin",
    pos: "D",
    number: "46",
    shoots: "R",
    DOB: "04/06/94",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4342107.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: ["ARI", "TOR"],
  },
  {
    name: "Owen Power",
    pos: "D",
    number: "25",
    shoots: "L",
    DOB: "11/22/02",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4781556.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Mattias Samuelsson",
    pos: "D",
    number: "23",
    shoots: "--",
    DOB: "03/14/00",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4378656.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Craig Anderson",
    pos: "G",
    number: "41",
    shoots: "L",
    DOB: "05/21/81",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/1929.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["CHI", "COL", "FLA", "OTT", "WSH"],
  },
  {
    name: "Eric Comrie",
    pos: "G",
    number: "31",
    shoots: "L",
    DOB: "07/06/95",
    team: "BUF",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042057.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["WPG", "DET", "NJD"],
  },
  {
    name: "Mikael Backlund",
    pos: "C",
    number: "11",
    shoots: "L",
    DOB: "03/17/89",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3797.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Blake Coleman",
    pos: "C",
    number: "20",
    shoots: "L",
    DOB: "11/28/91",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563026.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["NJD", "TBL"],
  },
  {
    name: "Dillon Dube",
    pos: "C",
    number: "29",
    shoots: "L",
    DOB: "07/20/98",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063268.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jonathan Huberdeau",
    pos: "C",
    number: "10",
    shoots: "L",
    DOB: "06/04/93",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562606.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["FLA"],
  },
  {
    name: "Nazem Kadri",
    pos: "C",
    number: "91",
    shoots: "L",
    DOB: "10/06/90",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5349.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["TOR", "COL"],
  },
  {
    name: "Trevor Lewis",
    pos: "C",
    number: "22",
    shoots: "R",
    DOB: "01/08/87",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3454.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["LAK", "WPG"],
  },
  {
    name: "Elias Lindholm",
    pos: "C",
    number: "28",
    shoots: "R",
    DOB: "12/02/94",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041994.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["CAR"],
  },
  {
    name: "Milan Lucic",
    pos: "W",
    number: "17",
    shoots: "L",
    DOB: "06/07/88",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3780.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["BOS", "LAK", "EDM"],
  },
  {
    name: "Andrew Mangiapane",
    pos: "W",
    number: "88",
    shoots: "L",
    DOB: "04/04/96",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3149619.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Nick Ritchie",
    pos: "W",
    number: "27",
    shoots: "L",
    DOB: "12/05/95",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114742.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["ANA", "BOS", "TOR", "ARI"],
  },
  {
    name: "Tyler Toffoli",
    pos: "W",
    number: "73",
    shoots: "R",
    DOB: "04/24/92",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5550.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["LAK", "VAN", "MTL"],
  },
  {
    name: "Rasmus Andersson",
    pos: "D",
    number: "4",
    shoots: "R",
    DOB: "10/27/96",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904186.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Noah Hanifin",
    pos: "D",
    number: "55",
    shoots: "L",
    DOB: "01/25/97",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3652964.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["CAR"],
  },
  {
    name: "MacKenzie Weegar",
    pos: "D",
    number: "52",
    shoots: "R",
    DOB: "01/07/94",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042269.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["FLA"],
  },
  {
    name: "Nikita Zadorov",
    pos: "D",
    number: "16",
    shoots: "L",
    DOB: "04/16/95",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042021.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: ["BUF", "COL", "CHI"],
  },
  {
    name: "Jacob Markstrom",
    pos: "G",
    number: "25",
    shoots: "L",
    DOB: "01/31/90",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5452.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["VAN"],
  },
  {
    name: "Dan Vladar",
    pos: "G",
    number: "80",
    shoots: "L",
    DOB: "08/20/97",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942459.png",
    conference: "West",
    division: "Pac",
    ctry: "CZE",
    former: ["BOS"],
  },
  {
    name: "Seth Jarvis",
    pos: "C",
    number: "24",
    shoots: "R",
    DOB: "02/01/02",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697396.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jesperi Kotkaniemi",
    pos: "C",
    number: "82",
    shoots: "L",
    DOB: "07/06/00",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352692.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["MTL"],
  },
  {
    name: "Martin Necas",
    pos: "C",
    number: "88",
    shoots: "R",
    DOB: "01/15/99",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233586.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Jordan Staal",
    pos: "C",
    number: "11",
    shoots: "L",
    DOB: "09/10/88",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3541.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["PIT"],
  },
  {
    name: "Paul Stastny",
    pos: "C",
    number: "26",
    shoots: "L",
    DOB: "12/27/85",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3467.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["STL", "COL", "VGK", "WPG"],
  },
  {
    name: "Derek Stepan",
    pos: "C",
    number: "21",
    shoots: "R",
    DOB: "06/18/90",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5731.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NYR", "ARI", "OTT"],
  },
  {
    name: "Jordan Martinook",
    pos: "W",
    number: "48",
    shoots: "L",
    DOB: "07/25/92",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2989376.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["ARI"],
  },
  {
    name: "Jesper Fast",
    pos: "W",
    number: "71",
    shoots: "R",
    DOB: "12/02/91",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590847.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["NYR"],
  },
  {
    name: "Jesse Puljujarvi",
    pos: "W",
    number: "13",
    shoots: "R",
    DOB: "05/07/98",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024839.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["EDM"],
  },
  {
    name: "Andrei Svechnikov",
    pos: "W",
    number: "37",
    shoots: "L",
    DOB: "03/26/00",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352683.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Teuvo Teravainen",
    pos: "W",
    number: "86",
    shoots: "L",
    DOB: "09/11/94",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2592095.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["CHI"],
  },
  {
    name: "Brent Burns",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "03/09/85",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2300.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["MIN", "SJS"],
  },
  {
    name: "Dylan Coghlan",
    pos: "D",
    number: "15",
    shoots: "R",
    DOB: "02/19/98",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063606.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["VGK"],
  },
  {
    name: "Shayne Gostisbehere",
    pos: "D",
    number: "41",
    shoots: "L",
    DOB: "04/20/93",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3025662.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["PHI", "ARI"],
  },
  {
    name: "Brett Pesce",
    pos: "D",
    number: "22",
    shoots: "R",
    DOB: "11/15/94",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3025535.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brady Skjei",
    pos: "D",
    number: "76",
    shoots: "L",
    DOB: "03/26/94",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976856.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NYR"],
  },
  {
    name: "Jaccob Slavin",
    pos: "D",
    number: "74",
    shoots: "L",
    DOB: "05/01/94",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069836.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Frederik Andersen",
    pos: "G",
    number: "31",
    shoots: "L",
    DOB: "10/02/89",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2517899.png",
    conference: "East",
    division: "Met",
    ctry: "DNK",
    former: ["ANA", "TOR"],
  },
  {
    name: "Antti Raanta",
    pos: "G",
    number: "32",
    shoots: "L",
    DOB: "05/12/89",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3037703.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["NYR", "ARI", "CHI"],
  },
  {
    name: "Andreas Athanasiou",
    pos: "C",
    number: "89",
    shoots: "L",
    DOB: "08/06/94",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3068035.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["DET", "EDM"],
  },
  {
    name: "Colin Blackwell",
    pos: "C",
    number: "43",
    shoots: "R",
    DOB: "03/28/93",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590857.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NYR", "SEA", "NSH", "TOR"],
  },
  {
    name: "Tyler Johnson",
    pos: "C",
    number: "90",
    shoots: "R",
    DOB: "07/29/90",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5706.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["TBL"],
  },
  {
    name: "Jonathan Toews",
    pos: "C",
    number: "19",
    shoots: "L",
    DOB: "04/29/88",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3669.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Taylor Raddysh",
    pos: "W",
    number: "11",
    shoots: "R",
    DOB: "02/18/98",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063502.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["TBL"],
  },
  {
    name: "Seth Jones",
    pos: "D",
    number: "4",
    shoots: "R",
    DOB: "10/03/94",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041992.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NSH", "CBJ"],
  },
  {
    name: "Connor Murphy",
    pos: "D",
    number: "5",
    shoots: "R",
    DOB: "03/26/93",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562618.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["ARI"],
  },
  {
    name: "Nikita Zaitsev",
    pos: "D",
    number: "22",
    shoots: "R",
    DOB: "10/29/91",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4010739.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: ["TOR", "OTT"],
  },
  {
    name: "Petr Mrazek",
    pos: "G",
    number: "34",
    shoots: "L",
    DOB: "02/14/92",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5619.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: ["DET", "CAR", "TOR"],
  },
  {
    name: "Alex Stalock",
    pos: "G",
    number: "32",
    shoots: "L",
    DOB: "07/28/87",
    team: "CHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5223.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["SJS", "MIN"],
  },
  {
    name: "Andrew Cogliano",
    pos: "C",
    number: "11",
    shoots: "L",
    DOB: "06/14/87",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3655.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["EDM", "ANA", "DAL", "SJS"],
  },
  {
    name: "Lars Eller",
    pos: "C",
    number: "20",
    shoots: "L",
    DOB: "05/08/89",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3946.png",
    conference: "West",
    division: "Cen",
    ctry: "DNK",
    former: ["MTL", "WSH"],
  },
  {
    name: "Nathan MacKinnon",
    pos: "C",
    number: "29",
    shoots: "R",
    DOB: "09/01/95",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041969.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Denis Malgin",
    pos: "C",
    number: "81",
    shoots: "R",
    DOB: "01/18/97",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942715.png",
    conference: "West",
    division: "Cen",
    ctry: "CHE",
    former: ["FLA", "TOR"],
  },
  {
    name: "Alex Newhook",
    pos: "C",
    number: "18",
    shoots: "L",
    DOB: "01/28/01",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565237.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Evan Rodrigues",
    pos: "C",
    number: "9",
    shoots: "R",
    DOB: "07/28/93",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3648008.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["BUF", "PIT"],
  },
  {
    name: "J.T. Compher",
    pos: "W",
    number: "37",
    shoots: "R",
    DOB: "04/08/95",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041995.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Artturi Lehkonen",
    pos: "W",
    number: "62",
    shoots: "L",
    DOB: "07/04/95",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042050.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: ["MTL"],
  },
  {
    name: "Matt Nieto",
    pos: "W",
    number: "83",
    shoots: "L",
    DOB: "11/05/92",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563076.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["SJS"],
  },
  {
    name: "Valeri Nichushkin",
    pos: "W",
    number: "13",
    shoots: "L",
    DOB: "03/04/95",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042003.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: ["DAL"],
  },
  {
    name: "Logan O'Connor",
    pos: "W",
    number: "25",
    shoots: "R",
    DOB: "08/14/96",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3988782.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Mikko Rantanen",
    pos: "W",
    number: "96",
    shoots: "L",
    DOB: "10/29/96",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899938.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Bowen Byram",
    pos: "D",
    number: "4",
    shoots: "L",
    DOB: "06/13/01",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565225.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Samuel Girard",
    pos: "D",
    number: "49",
    shoots: "L",
    DOB: "05/12/98",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063401.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Erik Johnson",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "03/21/88",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3649.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["STL"],
  },
  {
    name: "Jack Johnson",
    pos: "D",
    number: "3",
    shoots: "L",
    DOB: "01/13/87",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3583.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["LAK", "CBJ", "PIT", "NYR", "CHI"],
  },
  {
    name: "Cale Makar",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "10/30/98",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233563.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Josh Manson",
    pos: "D",
    number: "42",
    shoots: "R",
    DOB: "10/07/91",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590829.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["ANA"],
  },
  {
    name: "Devon Toews",
    pos: "D",
    number: "7",
    shoots: "L",
    DOB: "02/21/94",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3096249.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NYI"],
  },
  {
    name: "Pavel Francouz",
    pos: "G",
    number: "39",
    shoots: "R",
    DOB: "06/03/90",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4294197.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Alexandar Georgiev",
    pos: "G",
    number: "40",
    shoots: "L",
    DOB: "02/10/96",
    team: "COL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4239677.png",
    conference: "West",
    division: "Cen",
    ctry: "BGR",
    former: ["NYR"],
  },
  {
    name: "Boone Jenner",
    pos: "C",
    number: "38",
    shoots: "L",
    DOB: "06/15/93",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563054.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kent Johnson",
    pos: "C",
    number: "91",
    shoots: "L",
    DOB: "10/18/02",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4781553.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Sean Kuraly",
    pos: "C",
    number: "7",
    shoots: "L",
    DOB: "01/20/93",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2564154.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["BOS", "CBJ"],
  },
  {
    name: "Jack Roslovic",
    pos: "C",
    number: "96",
    shoots: "R",
    DOB: "01/29/97",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904098.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["WPG"],
  },
  {
    name: "Johnny Gaudreau",
    pos: "W",
    number: "13",
    shoots: "L",
    DOB: "08/13/93",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563039.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["CGY"],
  },
  {
    name: "Patrik Laine",
    pos: "W",
    number: "29",
    shoots: "R",
    DOB: "04/19/98",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024820.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["WPG"],
  },
  {
    name: "Adam Boqvist",
    pos: "D",
    number: "27",
    shoots: "R",
    DOB: "08/15/00",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352706.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Erik Gudbranson",
    pos: "D",
    number: "44",
    shoots: "R",
    DOB: "01/07/92",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5503.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["FLA", "PIT", "VAN", "OTT", "ANA", "NSH", "CGY"],
  },
  {
    name: "Elvis Merzlikins",
    pos: "G",
    number: "90",
    shoots: "L",
    DOB: "04/13/94",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151038.png",
    conference: "East",
    division: "Met",
    ctry: "LVA",
    former: [],
  },
  {
    name: "Max Domi",
    pos: "C",
    number: "18",
    shoots: "L",
    DOB: "03/02/95",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042014.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["ARI", "MTL", "CBJ", "CAR", "CHI"],
  },
  {
    name: "Radek Faksa",
    pos: "C",
    number: "12",
    shoots: "L",
    DOB: "01/09/94",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976842.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Roope Hintz",
    pos: "C",
    number: "24",
    shoots: "L",
    DOB: "11/17/96",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904183.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Joe Pavelski",
    pos: "C",
    number: "16",
    shoots: "R",
    DOB: "07/11/84",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3451.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["SJS"],
  },
  {
    name: "Tyler Seguin",
    pos: "C",
    number: "91",
    shoots: "R",
    DOB: "01/31/92",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5430.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["BOS"],
  },
  {
    name: "Jamie Benn",
    pos: "W",
    number: "14",
    shoots: "L",
    DOB: "07/18/89",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3998.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Joel Kiviranta",
    pos: "W",
    number: "25",
    shoots: "L",
    DOB: "03/23/96",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4587910.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Mason Marchment",
    pos: "W",
    number: "27",
    shoots: "L",
    DOB: "06/18/95",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4272192.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["TOR", "FLA"],
  },
  {
    name: "Jason Robertson",
    pos: "W",
    number: "21",
    shoots: "L",
    DOB: "07/22/99",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233875.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Evgenii Dadonov",
    pos: "W",
    number: "63",
    shoots: "L",
    DOB: "03/12/89",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4969.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: ["FLA", "OTT", "VGK", "MTL"],
  },
  {
    name: "Miro Heiskanen",
    pos: "D",
    number: "4",
    shoots: "L",
    DOB: "07/18/99",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233536.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Esa Lindell",
    pos: "D",
    number: "23",
    shoots: "L",
    DOB: "05/23/94",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069352.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Nils Lundkvist",
    pos: "D",
    number: "5",
    shoots: "R",
    DOB: "07/27/00",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352800.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["NYR"],
  },
  {
    name: "Ryan Suter",
    pos: "D",
    number: "20",
    shoots: "L",
    DOB: "01/21/85",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3047.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["MIN", "NSH"],
  },
  {
    name: "Jake Oettinger",
    pos: "G",
    number: "29",
    shoots: "L",
    DOB: "12/18/98",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4196914.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Scott Wedgewood",
    pos: "G",
    number: "41",
    shoots: "L",
    DOB: "08/14/92",
    team: "DAL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5622.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NJD", "ARI"],
  },
  {
    name: "Andrew Copp",
    pos: "C",
    number: "18",
    shoots: "L",
    DOB: "07/08/94",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042114.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["WPG", "NYR"],
  },
  {
    name: "Dylan Larkin",
    pos: "C",
    number: "71",
    shoots: "L",
    DOB: "07/30/96",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114755.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Pius Suter",
    pos: "C",
    number: "24",
    shoots: "L",
    DOB: "05/24/96",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4271732.png",
    conference: "East",
    division: "Atl",
    ctry: "CHE",
    former: ["CHI"],
  },
  {
    name: "Joe Veleno",
    pos: "C",
    number: "90",
    shoots: "L",
    DOB: "01/13/00",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352804.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Adam Erne",
    pos: "W",
    number: "73",
    shoots: "L",
    DOB: "04/20/95",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041991.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["TBL"],
  },
  {
    name: "Dominik Kubalik",
    pos: "W",
    number: "81",
    shoots: "L",
    DOB: "08/21/95",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042245.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: ["CHI"],
  },
  {
    name: "David Perron",
    pos: "W",
    number: "57",
    shoots: "R",
    DOB: "05/28/88",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3792.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["EDM", "PIT", "ANA", "VGK", "STL"],
  },
  {
    name: "Lucas Raymond",
    pos: "W",
    number: "23",
    shoots: "R",
    DOB: "03/28/02",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697385.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Filip Zadina",
    pos: "W",
    number: "11",
    shoots: "L",
    DOB: "11/27/99",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352700.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Ben Chiarot",
    pos: "D",
    number: "8",
    shoots: "L",
    DOB: "05/09/91",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5246.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["WPG", "MTL", "FLA"],
  },
  {
    name: "Olli Maatta",
    pos: "D",
    number: "2",
    shoots: "L",
    DOB: "08/22/94",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976850.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: ["PIT", "CHI", "LAK"],
  },
  {
    name: "Moritz Seider",
    pos: "D",
    number: "53",
    shoots: "R",
    DOB: "04/06/01",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565227.png",
    conference: "East",
    division: "Atl",
    ctry: "DEU",
    former: [],
  },
  {
    name: "Ville Husso",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "02/06/95",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151137.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: ["STL"],
  },
  {
    name: "Alex Nedeljkovic",
    pos: "G",
    number: "39",
    shoots: "L",
    DOB: "01/07/96",
    team: "DET",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114994.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["CAR"],
  },
  {
    name: "Leon Draisaitl",
    pos: "C",
    number: "29",
    shoots: "L",
    DOB: "10/27/95",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114727.png",
    conference: "West",
    division: "Pac",
    ctry: "DEU",
    former: [],
  },
  {
    name: "Connor McDavid",
    pos: "C",
    number: "97",
    shoots: "L",
    DOB: "01/13/97",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3895074.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Ryan McLeod",
    pos: "C",
    number: "71",
    shoots: "L",
    DOB: "09/21/99",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4378669.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Ryan Nugent-Hopkins",
    pos: "C",
    number: "93",
    shoots: "L",
    DOB: "04/12/93",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562624.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Warren Foegele",
    pos: "W",
    number: "37",
    shoots: "L",
    DOB: "04/01/96",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151036.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CAR"],
  },
  {
    name: "Zach Hyman",
    pos: "W",
    number: "18",
    shoots: "R",
    DOB: "06/09/92",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5509.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["TOR"],
  },
  {
    name: "Evander Kane",
    pos: "W",
    number: "91",
    shoots: "L",
    DOB: "08/02/91",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5251.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["BUF", "SJS"],
  },
  {
    name: "Kailer Yamamoto",
    pos: "W",
    number: "56",
    shoots: "R",
    DOB: "09/29/98",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233648.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Evan Bouchard",
    pos: "D",
    number: "2",
    shoots: "R",
    DOB: "10/20/99",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352722.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Cody Ceci",
    pos: "D",
    number: "5",
    shoots: "R",
    DOB: "12/21/93",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976843.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["OTT", "TOR", "PIT"],
  },
  {
    name: "Mattias Ekholm",
    pos: "D",
    number: "14",
    shoots: "L",
    DOB: "05/24/90",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2558631.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["NSH"],
  },
  {
    name: "Brett Kulak",
    pos: "D",
    number: "27",
    shoots: "L",
    DOB: "01/06/94",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3068665.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CGY", "MTL"],
  },
  {
    name: "Ryan Murray",
    pos: "D",
    number: "28",
    shoots: "L",
    DOB: "09/27/93",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2966501.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CBJ", "NJD", "COL"],
  },
  {
    name: "Darnell Nurse",
    pos: "D",
    number: "25",
    shoots: "L",
    DOB: "02/04/95",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041997.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jack Campbell",
    pos: "G",
    number: "36",
    shoots: "L",
    DOB: "01/09/92",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5473.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["LAK", "TOR"],
  },
  {
    name: "Stuart Skinner",
    pos: "G",
    number: "74",
    shoots: "L",
    DOB: "11/01/98",
    team: "EDM",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4268767.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Aleksander Barkov",
    pos: "C",
    number: "16",
    shoots: "L",
    DOB: "09/02/95",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041970.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Sam Bennett",
    pos: "C",
    number: "9",
    shoots: "L",
    DOB: "06/20/96",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114732.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CGY"],
  },
  {
    name: "Anton Lundell",
    pos: "C",
    number: "15",
    shoots: "L",
    DOB: "10/03/01",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697395.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Eetu Luostarinen",
    pos: "C",
    number: "27",
    shoots: "L",
    DOB: "09/02/98",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233877.png",
    conference: "East",
    division: "Atl",
    ctry: "FIN",
    former: ["CAR"],
  },
  {
    name: "Sam Reinhart",
    pos: "C",
    number: "13",
    shoots: "R",
    DOB: "11/06/95",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114722.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["BUF"],
  },
  {
    name: "Carter Verhaeghe",
    pos: "C",
    number: "23",
    shoots: "L",
    DOB: "08/14/95",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042088.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["TBL"],
  },
  {
    name: "Anthony Duclair",
    pos: "W",
    number: "10",
    shoots: "L",
    DOB: "08/26/95",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042086.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NYR", "ARI", "CHI", "OTT", "CBJ"],
  },
  {
    name: "Matthew Tkachuk",
    pos: "W",
    number: "19",
    shoots: "L",
    DOB: "12/11/97",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024854.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["CGY"],
  },
  {
    name: "Patric Hornqvist",
    pos: "W",
    number: "70",
    shoots: "R",
    DOB: "01/01/87",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3699.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["PIT", "NSH"],
  },
  {
    name: "Aaron Ekblad",
    pos: "D",
    number: "5",
    shoots: "R",
    DOB: "02/07/96",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114717.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Gustav Forsling",
    pos: "D",
    number: "42",
    shoots: "L",
    DOB: "06/12/96",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151784.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["CHI"],
  },
  {
    name: "Radko Gudas",
    pos: "D",
    number: "7",
    shoots: "R",
    DOB: "06/05/90",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5502.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: ["TBL", "PHI", "WSH"],
  },
  {
    name: "Brandon Montour",
    pos: "D",
    number: "62",
    shoots: "R",
    DOB: "04/11/94",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3115032.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["ANA"],
  },
  {
    name: "Marc Staal",
    pos: "D",
    number: "18",
    shoots: "L",
    DOB: "01/13/87",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3250.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NYR", "DET"],
  },
  {
    name: "Sergei Bobrovsky",
    pos: "G",
    number: "72",
    shoots: "L",
    DOB: "09/20/88",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5571.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: ["PHI", "CBJ"],
  },
  {
    name: "Spencer Knight",
    pos: "G",
    number: "30",
    shoots: "L",
    DOB: "04/19/01",
    team: "FLA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565234.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Quinton Byfield",
    pos: "C",
    number: "55",
    shoots: "L",
    DOB: "08/19/02",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697383.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Phillip Danault",
    pos: "C",
    number: "24",
    shoots: "L",
    DOB: "02/24/93",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562602.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["MTL"],
  },
  {
    name: "Adrian Kempe",
    pos: "C",
    number: "9",
    shoots: "L",
    DOB: "09/13/96",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114802.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Anze Kopitar",
    pos: "C",
    number: "11",
    shoots: "L",
    DOB: "08/24/87",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3183.png",
    conference: "West",
    division: "Pac",
    ctry: "SVN",
    former: [],
  },
  {
    name: "Trevor Moore",
    pos: "C",
    number: "12",
    shoots: "L",
    DOB: "03/31/95",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3096186.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["TOR"],
  },
  {
    name: "Gabriel Vilardi",
    pos: "C",
    number: "13",
    shoots: "R",
    DOB: "08/16/99",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233583.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Viktor Arvidsson",
    pos: "W",
    number: "33",
    shoots: "R",
    DOB: "04/08/93",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3120307.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["NSH"],
  },
  {
    name: "Kevin Fiala",
    pos: "W",
    number: "22",
    shoots: "L",
    DOB: "07/22/96",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114743.png",
    conference: "West",
    division: "Pac",
    ctry: "CHE",
    former: ["NSH", "MIN"],
  },
  {
    name: "Alex Iafallo",
    pos: "W",
    number: "19",
    shoots: "L",
    DOB: "12/21/93",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3652598.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Drew Doughty",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "12/08/89",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3995.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Sean Durzi",
    pos: "D",
    number: "50",
    shoots: "R",
    DOB: "10/21/98",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4378683.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Alexander Edler",
    pos: "D",
    number: "2",
    shoots: "L",
    DOB: "04/21/86",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3464.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["LAK"],
  },
  {
    name: "Vladislav Gavrikov",
    pos: "D",
    number: "84",
    shoots: "L",
    DOB: "11/21/95",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942292.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: ["CBJ"],
  },
  {
    name: "Matt Roy",
    pos: "D",
    number: "3",
    shoots: "R",
    DOB: "09/01/95",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942924.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Cal Petersen",
    pos: "G",
    number: "40",
    shoots: "R",
    DOB: "10/19/94",
    team: "LAK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042182.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Joel Eriksson Ek",
    pos: "C",
    number: "14",
    shoots: "L",
    DOB: "01/29/97",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904091.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Frederick Gaudreau",
    pos: "C",
    number: "89",
    shoots: "R",
    DOB: "05/01/93",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3149649.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NSH", "PIT"],
  },
  {
    name: "Sam Steel",
    pos: "C",
    number: "13",
    shoots: "L",
    DOB: "02/03/98",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024998.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["ANA"],
  },
  {
    name: "Oskar Sundqvist",
    pos: "C",
    number: "70",
    shoots: "R",
    DOB: "03/23/94",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069277.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["PIT", "STL"],
  },
  {
    name: "Matt Boldy",
    pos: "W",
    number: "12",
    shoots: "L",
    DOB: "04/05/01",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565233.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Marcus Foligno",
    pos: "W",
    number: "17",
    shoots: "L",
    DOB: "08/10/91",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5172.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["BUF"],
  },
  {
    name: "Marcus Johansson",
    pos: "W",
    number: "90",
    shoots: "L",
    DOB: "10/06/90",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5714.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["NJD", "BOS", "BUF", "SEA", "WSH"],
  },
  {
    name: "Kirill Kaprizov",
    pos: "W",
    number: "97",
    shoots: "L",
    DOB: "04/26/97",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942335.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Gustav Nyquist",
    pos: "W",
    number: "14",
    shoots: "L",
    DOB: "09/01/89",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5823.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["DET", "SJS", "CBJ"],
  },
  {
    name: "Ryan Hartman",
    pos: "W",
    number: "38",
    shoots: "R",
    DOB: "09/20/94",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042063.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["CHI", "NSH", "PHI"],
  },
  {
    name: "Ryan Reaves",
    pos: "W",
    number: "75",
    shoots: "R",
    DOB: "01/20/87",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3683.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["STL", "PIT", "VGK", "NYR"],
  },
  {
    name: "Mats Zuccarello",
    pos: "W",
    number: "36",
    shoots: "L",
    DOB: "09/01/87",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5560.png",
    conference: "West",
    division: "Cen",
    ctry: "NOR",
    former: ["NYR", "DAL"],
  },
  {
    name: "Jonas Brodin",
    pos: "D",
    number: "25",
    shoots: "L",
    DOB: "07/12/93",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562600.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Matt Dumba",
    pos: "D",
    number: "24",
    shoots: "R",
    DOB: "07/25/94",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2970689.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Alex Goligoski",
    pos: "D",
    number: "33",
    shoots: "L",
    DOB: "07/30/85",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3757.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["PIT", "DAL", "ARI"],
  },
  {
    name: "John Klingberg",
    pos: "D",
    number: "3",
    shoots: "R",
    DOB: "08/14/92",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590751.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["DAL", "ANA"],
  },
  {
    name: "Jon Merrill",
    pos: "D",
    number: "4",
    shoots: "L",
    DOB: "02/03/92",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3059403.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NJD", "VGK", "DET", "MTL"],
  },
  {
    name: "Jared Spurgeon",
    pos: "D",
    number: "46",
    shoots: "R",
    DOB: "11/29/89",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5079.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Marc-Andre Fleury",
    pos: "G",
    number: "29",
    shoots: "L",
    DOB: "11/28/84",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2346.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["PIT", "VGK", "CHI"],
  },
  {
    name: "Filip Gustavsson",
    pos: "G",
    number: "32",
    shoots: "L",
    DOB: "06/07/98",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4272674.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: ["OTT"],
  },
  {
    name: "Kirby Dach",
    pos: "C",
    number: "77",
    shoots: "R",
    DOB: "01/21/01",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565224.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CHI"],
  },
  {
    name: "Christian Dvorak",
    pos: "C",
    number: "28",
    shoots: "L",
    DOB: "02/02/96",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3115035.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["ARI"],
  },
  {
    name: "Sean Monahan",
    pos: "C",
    number: "91",
    shoots: "L",
    DOB: "10/12/94",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041996.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CGY"],
  },
  {
    name: "Nick Suzuki",
    pos: "C",
    number: "14",
    shoots: "R",
    DOB: "08/10/99",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233594.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jonathan Drouin",
    pos: "W",
    number: "27",
    shoots: "L",
    DOB: "03/28/95",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041971.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["TBL"],
  },
  {
    name: "Mike Hoffman",
    pos: "W",
    number: "68",
    shoots: "L",
    DOB: "11/24/89",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5103.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["OTT", "FLA", "STL"],
  },
  {
    name: "Juraj Slafkovsky",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "03/30/04",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4915349.png",
    conference: "East",
    division: "Atl",
    ctry: "SVK",
    former: [],
  },
  {
    name: "Josh Anderson",
    pos: "W",
    number: "17",
    shoots: "R",
    DOB: "05/07/94",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069687.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CBJ"],
  },
  {
    name: "Cole Caufield",
    pos: "W",
    number: "22",
    shoots: "R",
    DOB: "01/02/01",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565236.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brendan Gallagher",
    pos: "W",
    number: "11",
    shoots: "R",
    DOB: "05/06/92",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5614.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Denis Gurianov",
    pos: "W",
    number: "25",
    shoots: "L",
    DOB: "06/07/97",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900147.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: ["DAL"],
  },
  {
    name: "Kaiden Guhle",
    pos: "D",
    number: "21",
    shoots: "L",
    DOB: "01/18/02",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697399.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Mike Matheson",
    pos: "D",
    number: "8",
    shoots: "L",
    DOB: "02/27/94",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976851.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["FLA", "PIT"],
  },
  {
    name: "David Savard",
    pos: "D",
    number: "58",
    shoots: "R",
    DOB: "10/22/90",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5427.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CBJ", "TBL"],
  },
  {
    name: "Chris Wideman",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "01/07/90",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2334912.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["OTT", "EDM", "FLA"],
  },
  {
    name: "Jake Allen",
    pos: "G",
    number: "34",
    shoots: "L",
    DOB: "08/07/90",
    team: "MTL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5111.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["STL"],
  },
  {
    name: "Matt Duchene",
    pos: "C",
    number: "95",
    shoots: "L",
    DOB: "01/16/91",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5161.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["COL", "OTT", "CBJ"],
  },
  {
    name: "Cody Glass",
    pos: "C",
    number: "8",
    shoots: "R",
    DOB: "04/01/99",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233568.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Ryan Johansen",
    pos: "C",
    number: "92",
    shoots: "R",
    DOB: "07/31/92",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5434.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["CBJ"],
  },
  {
    name: "Colton Sissons",
    pos: "C",
    number: "10",
    shoots: "R",
    DOB: "11/05/93",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2993474.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Yakov Trenin",
    pos: "C",
    number: "13",
    shoots: "L",
    DOB: "01/13/97",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904188.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Filip Forsberg",
    pos: "W",
    number: "9",
    shoots: "R",
    DOB: "08/13/94",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2968772.png",
    conference: "West",
    division: "Cen",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Tyson Barrie",
    pos: "D",
    number: "22",
    shoots: "R",
    DOB: "07/26/91",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5181.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["COL", "TOR", "EDM"],
  },
  {
    name: "Alexandre Carrier",
    pos: "D",
    number: "45",
    shoots: "R",
    DOB: "10/08/96",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942064.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Dante Fabbro",
    pos: "D",
    number: "57",
    shoots: "R",
    DOB: "06/20/98",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024959.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Cal Foote",
    pos: "D",
    number: "52",
    shoots: "R",
    DOB: "12/13/98",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233598.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["TBL"],
  },
  {
    name: "Roman Josi",
    pos: "D",
    number: "59",
    shoots: "L",
    DOB: "06/01/90",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5436.png",
    conference: "West",
    division: "Cen",
    ctry: "CHE",
    former: [],
  },
  {
    name: "Jeremy Lauzon",
    pos: "D",
    number: "3",
    shoots: "L",
    DOB: "04/28/97",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904185.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["BOS", "SEA"],
  },
  {
    name: "Ryan McDonagh",
    pos: "D",
    number: "27",
    shoots: "L",
    DOB: "06/13/89",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4954.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NYR", "TBL"],
  },
  {
    name: "Kevin Lankinen",
    pos: "G",
    number: "32",
    shoots: "L",
    DOB: "04/28/95",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4341584.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: ["CHI"],
  },
  {
    name: "Juuse Saros",
    pos: "G",
    number: "74",
    shoots: "L",
    DOB: "04/19/95",
    team: "NSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042109.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Jack Hughes",
    pos: "C",
    number: "86",
    shoots: "L",
    DOB: "05/14/01",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565222.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Michael McLeod",
    pos: "C",
    number: "20",
    shoots: "R",
    DOB: "02/03/98",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024902.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Dawson Mercer",
    pos: "C",
    number: "91",
    shoots: "R",
    DOB: "10/27/01",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697401.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Yegor Sharangovich",
    pos: "C",
    number: "17",
    shoots: "L",
    DOB: "06/06/98",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4587843.png",
    conference: "East",
    division: "Met",
    ctry: "BLR",
    former: [],
  },
  {
    name: "Jesper Bratt",
    pos: "W",
    number: "63",
    shoots: "L",
    DOB: "07/30/98",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4268771.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Erik Haula",
    pos: "W",
    number: "56",
    shoots: "L",
    DOB: "03/23/91",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2593311.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["MIN", "NSH", "CAR", "VGK", "FLA", "BOS"],
  },
  {
    name: "Ondrej Palat",
    pos: "W",
    number: "18",
    shoots: "L",
    DOB: "03/28/91",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590389.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: ["TBL"],
  },
  {
    name: "Tomas Tatar",
    pos: "W",
    number: "90",
    shoots: "L",
    DOB: "12/01/90",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5227.png",
    conference: "East",
    division: "Met",
    ctry: "SVK",
    former: ["DET", "VGK", "MTL"],
  },
  {
    name: "Timo Meier",
    pos: "W",
    number: "96",
    shoots: "L",
    DOB: "10/08/96",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899978.png",
    conference: "East",
    division: "Met",
    ctry: "CHE",
    former: ["SJS"],
  },
  {
    name: "Ryan Graves",
    pos: "D",
    number: "33",
    shoots: "L",
    DOB: "05/21/95",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042122.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["COL"],
  },
  {
    name: "Dougie Hamilton",
    pos: "D",
    number: "7",
    shoots: "R",
    DOB: "06/17/93",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562605.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["BOS", "CAR"],
  },
  {
    name: "John Marino",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "05/21/97",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3941974.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["PIT"],
  },
  {
    name: "Jonas Siegenthaler",
    pos: "D",
    number: "71",
    shoots: "L",
    DOB: "05/06/97",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904190.png",
    conference: "East",
    division: "Met",
    ctry: "CHE",
    former: [],
  },
  {
    name: "Brendan Smith",
    pos: "D",
    number: "2",
    shoots: "L",
    DOB: "02/08/89",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4973.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["DET", "NYR", "CAR"],
  },
  {
    name: "Mackenzie Blackwood",
    pos: "G",
    number: "29",
    shoots: "L",
    DOB: "12/09/96",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904177.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Vitek Vanecek",
    pos: "G",
    number: "41",
    shoots: "L",
    DOB: "01/09/96",
    team: "NJD",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114996.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: ["WSH"],
  },
  {
    name: "Mathew Barzal",
    pos: "C",
    number: "13",
    shoots: "R",
    DOB: "05/26/97",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899946.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Casey Cizikas",
    pos: "C",
    number: "53",
    shoots: "L",
    DOB: "02/27/91",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5262.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Bo Horvat",
    pos: "C",
    number: "14",
    shoots: "L",
    DOB: "04/05/95",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042002.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["VAN"],
  },
  {
    name: "Brock Nelson",
    pos: "C",
    number: "29",
    shoots: "L",
    DOB: "10/15/91",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5798.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Jean-Gabriel Pageau",
    pos: "C",
    number: "44",
    shoots: "R",
    DOB: "11/11/92",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2593131.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["OTT"],
  },
  {
    name: "Kyle Palmieri",
    pos: "C",
    number: "21",
    shoots: "R",
    DOB: "02/01/91",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5517.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["ANA", "NJD"],
  },
  {
    name: "Pierre Engvall",
    pos: "W",
    number: "18",
    shoots: "L",
    DOB: "05/31/96",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151763.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["TOR"],
  },
  {
    name: "Anders Lee",
    pos: "W",
    number: "27",
    shoots: "L",
    DOB: "07/03/90",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5793.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Matt Martin",
    pos: "W",
    number: "17",
    shoots: "L",
    DOB: "05/08/89",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5050.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["TOR"],
  },
  {
    name: "Zach Parise",
    pos: "W",
    number: "11",
    shoots: "L",
    DOB: "07/28/84",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2975.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NJD", "MIN"],
  },
  {
    name: "Josh Bailey",
    pos: "W",
    number: "12",
    shoots: "L",
    DOB: "10/02/89",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5056.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Oliver Wahlstrom",
    pos: "W",
    number: "26",
    shoots: "R",
    DOB: "06/13/00",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352727.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Noah Dobson",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "01/07/00",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352732.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Scott Mayfield",
    pos: "D",
    number: "24",
    shoots: "R",
    DOB: "10/14/92",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563069.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Adam Pelech",
    pos: "D",
    number: "3",
    shoots: "L",
    DOB: "08/16/94",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069621.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Ryan Pulock",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "10/06/94",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042019.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Alexander Romanov",
    pos: "D",
    number: "28",
    shoots: "L",
    DOB: "01/06/00",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4587854.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: ["MTL"],
  },
  {
    name: "Ilya Sorokin",
    pos: "G",
    number: "30",
    shoots: "L",
    DOB: "08/04/95",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151981.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Semyon Varlamov",
    pos: "G",
    number: "40",
    shoots: "L",
    DOB: "04/27/88",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3759.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: ["COL", "WSH"],
  },
  {
    name: "Filip Chytil",
    pos: "C",
    number: "72",
    shoots: "L",
    DOB: "09/05/99",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233643.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Barclay Goodrow",
    pos: "C",
    number: "21",
    shoots: "L",
    DOB: "02/26/93",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069411.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["SJS", "TBL"],
  },
  {
    name: "Tyler Motte",
    pos: "C",
    number: "14",
    shoots: "L",
    DOB: "03/10/95",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042138.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["CHI", "CBJ", "VAN", "OTT"],
  },
  {
    name: "Vincent Trocheck",
    pos: "C",
    number: "16",
    shoots: "R",
    DOB: "07/11/93",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563036.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["FLA", "CGY"],
  },
  {
    name: "Mika Zibanejad",
    pos: "C",
    number: "93",
    shoots: "R",
    DOB: "04/18/93",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562637.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["OTT"],
  },
  {
    name: "Chris Kreider",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "04/30/91",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5833.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Alexis Lafreniere",
    pos: "W",
    number: "13",
    shoots: "L",
    DOB: "10/11/01",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697382.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Artemi Panarin",
    pos: "W",
    number: "10",
    shoots: "R",
    DOB: "10/30/91",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3891952.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: ["CHI", "CBJ"],
  },
  {
    name: "Jimmy Vesey",
    pos: "W",
    number: "26",
    shoots: "L",
    DOB: "05/26/93",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069397.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["BUF", "TOR", "VAN", "NJD"],
  },
  {
    name: "Kaapo Kakko",
    pos: "W",
    number: "24",
    shoots: "L",
    DOB: "02/13/01",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565223.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: [],
  },
  {
    name: "Patrick Kane",
    pos: "W",
    number: "88",
    shoots: "L",
    DOB: "11/19/88",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3735.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["CHI"],
  },
  {
    name: "Vladimir Tarasenko",
    pos: "W",
    number: "91",
    shoots: "L",
    DOB: "12/13/91",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5837.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: ["STL"],
  },
  {
    name: "Adam Fox",
    pos: "D",
    number: "23",
    shoots: "R",
    DOB: "02/17/98",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4197146.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Ryan Lindgren",
    pos: "D",
    number: "55",
    shoots: "L",
    DOB: "02/11/98",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4271998.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Niko Mikkola",
    pos: "D",
    number: "77",
    shoots: "L",
    DOB: "04/27/96",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942354.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["STL"],
  },
  {
    name: "K'Andre Miller",
    pos: "D",
    number: "79",
    shoots: "L",
    DOB: "01/21/00",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352770.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Braden Schneider",
    pos: "D",
    number: "4",
    shoots: "R",
    DOB: "09/20/01",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697402.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jacob Trouba",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "02/26/94",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976839.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["WPG"],
  },
  {
    name: "Jaroslav Halak",
    pos: "G",
    number: "41",
    shoots: "L",
    DOB: "05/13/85",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3116.png",
    conference: "East",
    division: "Met",
    ctry: "SVK",
    former: ["MTL", "STL", "WSH", "NYI", "BOS", "VAN"],
  },
  {
    name: "Igor Shesterkin",
    pos: "G",
    number: "31",
    shoots: "L",
    DOB: "12/30/95",
    team: "NYR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151297.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Derick Brassard",
    pos: "C",
    number: "61",
    shoots: "L",
    DOB: "09/22/87",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3506.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CBJ", "NYR", "PIT", "FLA", "COL", "NYI", "ARI", "PHI"],
  },
  {
    name: "Josh Norris",
    pos: "C",
    number: "9",
    shoots: "L",
    DOB: "05/05/99",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233627.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Shane Pinto",
    pos: "C",
    number: "57",
    shoots: "R",
    DOB: "11/12/00",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565255.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Tim Stutzle",
    pos: "W",
    number: "18",
    shoots: "L",
    DOB: "01/15/02",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697384.png",
    conference: "East",
    division: "Atl",
    ctry: "DEU",
    former: [],
  },
  {
    name: "Brady Tkachuk",
    pos: "W",
    number: "7",
    shoots: "L",
    DOB: "09/16/99",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4319858.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Drake Batherson",
    pos: "W",
    number: "19",
    shoots: "R",
    DOB: "04/27/98",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4271734.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Alex DeBrincat",
    pos: "W",
    number: "12",
    shoots: "R",
    DOB: "12/18/97",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063433.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["CHI"],
  },
  {
    name: "Claude Giroux",
    pos: "W",
    number: "28",
    shoots: "R",
    DOB: "01/12/88",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3775.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["PHI", "FLA"],
  },
  {
    name: "Mathieu Joseph",
    pos: "W",
    number: "21",
    shoots: "R",
    DOB: "02/09/97",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3941965.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["TBL"],
  },
  {
    name: "Erik Brannstrom",
    pos: "D",
    number: "26",
    shoots: "L",
    DOB: "09/02/99",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233606.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Thomas Chabot",
    pos: "D",
    number: "72",
    shoots: "L",
    DOB: "01/30/97",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900219.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Travis Hamonic",
    pos: "D",
    number: "23",
    shoots: "R",
    DOB: "08/16/90",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5073.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NYI", "CGY", "VAN"],
  },
  {
    name: "Jake Sanderson",
    pos: "D",
    number: "85",
    shoots: "L",
    DOB: "07/08/02",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697386.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Artem Zub",
    pos: "D",
    number: "2",
    shoots: "R",
    DOB: "10/03/95",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4712021.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Anton Forsberg",
    pos: "G",
    number: "31",
    shoots: "L",
    DOB: "11/27/92",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3036851.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["CBJ", "CHI", "CAR"],
  },
  {
    name: "Cam Talbot",
    pos: "G",
    number: "33",
    shoots: "L",
    DOB: "07/05/87",
    team: "OTT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5734.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NYR", "EDM", "PHI", "CGY", "MIN"],
  },
  {
    name: "Morgan Frost",
    pos: "C",
    number: "48",
    shoots: "L",
    DOB: "05/14/99",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233685.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kevin Hayes",
    pos: "C",
    number: "13",
    shoots: "L",
    DOB: "05/08/92",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5767.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NYR", "WPG"],
  },
  {
    name: "Scott Laughton",
    pos: "C",
    number: "21",
    shoots: "L",
    DOB: "05/30/94",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976848.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kieffer Bellows",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "06/10/98",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024971.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NYI"],
  },
  {
    name: "Nicolas Deslauriers",
    pos: "W",
    number: "44",
    shoots: "L",
    DOB: "02/22/91",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5193.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["BUF", "MTL", "ANA", "MIN"],
  },
  {
    name: "Joel Farabee",
    pos: "W",
    number: "86",
    shoots: "L",
    DOB: "02/25/00",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352750.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brendan Lemieux",
    pos: "W",
    number: "22",
    shoots: "L",
    DOB: "03/15/96",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114983.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NYR", "WPG", "LAK"],
  },
  {
    name: "James van Riemsdyk",
    pos: "W",
    number: "25",
    shoots: "L",
    DOB: "05/04/89",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3822.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["TOR"],
  },
  {
    name: "Cam Atkinson",
    pos: "W",
    number: "89",
    shoots: "R",
    DOB: "06/05/89",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2501107.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["CBJ"],
  },
  {
    name: "Travis Konecny",
    pos: "W",
    number: "11",
    shoots: "R",
    DOB: "03/11/97",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900169.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Owen Tippett",
    pos: "W",
    number: "74",
    shoots: "R",
    DOB: "02/16/99",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4392072.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["FLA"],
  },
  {
    name: "Justin Braun",
    pos: "D",
    number: "61",
    shoots: "R",
    DOB: "02/10/87",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5470.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["PHI", "SJS", "NYR"],
  },
  {
    name: "Tony DeAngelo",
    pos: "D",
    number: "77",
    shoots: "R",
    DOB: "10/24/95",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114769.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["ARI", "NYR", "CAR"],
  },
  {
    name: "Ivan Provorov",
    pos: "D",
    number: "9",
    shoots: "L",
    DOB: "01/13/97",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899939.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Rasmus Ristolainen",
    pos: "D",
    number: "55",
    shoots: "R",
    DOB: "10/27/94",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3041999.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["BUF"],
  },
  {
    name: "Travis Sanheim",
    pos: "D",
    number: "6",
    shoots: "L",
    DOB: "03/29/96",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114757.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Carter Hart",
    pos: "G",
    number: "79",
    shoots: "L",
    DOB: "08/13/98",
    team: "PHI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4064582.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Nick Bonino",
    pos: "C",
    number: "13",
    shoots: "L",
    DOB: "04/20/88",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4927.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["ANA", "VAN", "MIN", "NSH", "SJS"],
  },
  {
    name: "Jeff Carter",
    pos: "C",
    number: "77",
    shoots: "R",
    DOB: "01/01/85",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2383.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["PHI", "CBJ", "LAK"],
  },
  {
    name: "Sidney Crosby",
    pos: "C",
    number: "87",
    shoots: "L",
    DOB: "08/07/87",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Mikael Granlund",
    pos: "C",
    number: "64",
    shoots: "L",
    DOB: "02/26/92",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5831.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: ["MIN", "NSH"],
  },
  {
    name: "Evgeni Malkin",
    pos: "C",
    number: "71",
    shoots: "L",
    DOB: "07/31/86",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3124.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Jake Guentzel",
    pos: "W",
    number: "59",
    shoots: "L",
    DOB: "10/06/94",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042083.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Danton Heinen",
    pos: "W",
    number: "43",
    shoots: "L",
    DOB: "07/05/95",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151741.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["BOS", "ANA"],
  },
  {
    name: "Jason Zucker",
    pos: "W",
    number: "16",
    shoots: "L",
    DOB: "01/16/92",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2593315.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["MIN"],
  },
  {
    name: "Rickard Rakell",
    pos: "W",
    number: "67",
    shoots: "R",
    DOB: "05/05/93",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562629.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["ANA"],
  },
  {
    name: "Bryan Rust",
    pos: "W",
    number: "17",
    shoots: "R",
    DOB: "05/11/92",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2591155.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brian Dumoulin",
    pos: "D",
    number: "8",
    shoots: "L",
    DOB: "09/06/91",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5738.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Pierre-Olivier Joseph",
    pos: "D",
    number: "73",
    shoots: "L",
    DOB: "07/01/99",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233653.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kris Letang",
    pos: "D",
    number: "58",
    shoots: "R",
    DOB: "04/24/87",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3539.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jeff Petry",
    pos: "D",
    number: "26",
    shoots: "R",
    DOB: "12/09/87",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5407.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["EDM", "MTL"],
  },
  {
    name: "Marcus Pettersson",
    pos: "D",
    number: "28",
    shoots: "L",
    DOB: "05/08/96",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114995.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["ANA"],
  },
  {
    name: "Jan Rutta",
    pos: "D",
    number: "44",
    shoots: "R",
    DOB: "07/29/90",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4230583.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: ["CHI", "TBL"],
  },
  {
    name: "Casey DeSmith",
    pos: "G",
    number: "1",
    shoots: "L",
    DOB: "08/13/91",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3025540.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Tristan Jarry",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "04/29/95",
    team: "PIT",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042020.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Logan Couture",
    pos: "C",
    number: "39",
    shoots: "L",
    DOB: "03/28/89",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3773.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Noah Gregor",
    pos: "C",
    number: "73",
    shoots: "L",
    DOB: "07/28/98",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063460.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Tomas Hertl",
    pos: "C",
    number: "48",
    shoots: "L",
    DOB: "11/12/93",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976844.png",
    conference: "West",
    division: "Pac",
    ctry: "CZE",
    former: [],
  },
  {
    name: "Luke Kunin",
    pos: "C",
    number: "11",
    shoots: "R",
    DOB: "12/04/97",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024933.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["MIN"],
  },
  {
    name: "Steven Lorentz",
    pos: "C",
    number: "16",
    shoots: "L",
    DOB: "04/13/96",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3939658.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CAR"],
  },
  {
    name: "Nico Sturm",
    pos: "C",
    number: "7",
    shoots: "L",
    DOB: "05/03/95",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4320625.png",
    conference: "West",
    division: "Pac",
    ctry: "DEU",
    former: ["MIN", "COL"],
  },
  {
    name: "Alexander Barabanov",
    pos: "W",
    number: "94",
    shoots: "L",
    DOB: "06/17/97",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4294800.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: ["TOR"],
  },
  {
    name: "Oskar Lindblom",
    pos: "W",
    number: "23",
    shoots: "L",
    DOB: "08/15/96",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151326.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["PHI"],
  },
  {
    name: "Kevin Labanc",
    pos: "W",
    number: "62",
    shoots: "R",
    DOB: "12/12/95",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151792.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Matt Benning",
    pos: "D",
    number: "5",
    shoots: "R",
    DOB: "05/25/94",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069449.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["EDM", "NSH"],
  },
  {
    name: "Mario Ferraro",
    pos: "D",
    number: "38",
    shoots: "L",
    DOB: "09/17/98",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233884.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Erik Karlsson",
    pos: "D",
    number: "65",
    shoots: "R",
    DOB: "05/31/90",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5164.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["OTT"],
  },
  {
    name: "Marc-Edouard Vlasic",
    pos: "D",
    number: "44",
    shoots: "L",
    DOB: "03/30/87",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3371.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kaapo Kahkonen",
    pos: "G",
    number: "36",
    shoots: "L",
    DOB: "08/16/96",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151341.png",
    conference: "West",
    division: "Pac",
    ctry: "FIN",
    former: ["MIN"],
  },
  {
    name: "James Reimer",
    pos: "G",
    number: "47",
    shoots: "L",
    DOB: "03/15/88",
    team: "SJS",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3870.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["TOR", "CAR", "FLA"],
  },
  {
    name: "Matty Beniers",
    pos: "C",
    number: "10",
    shoots: "L",
    DOB: "11/05/02",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4781552.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Ryan Donato",
    pos: "C",
    number: "9",
    shoots: "L",
    DOB: "04/09/96",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3115033.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["MIN", "BOS"],
  },
  {
    name: "Yanni Gourde",
    pos: "C",
    number: "37",
    shoots: "L",
    DOB: "12/15/91",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3094261.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["TBL"],
  },
  {
    name: "Jaden Schwartz",
    pos: "C",
    number: "17",
    shoots: "L",
    DOB: "06/25/92",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5835.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["STL"],
  },
  {
    name: "Andre Burakovsky",
    pos: "W",
    number: "95",
    shoots: "L",
    DOB: "02/09/95",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042044.png",
    conference: "West",
    division: "Pac",
    ctry: "AUT",
    former: ["WSH", "COL"],
  },
  {
    name: "Jared McCann",
    pos: "W",
    number: "19",
    shoots: "L",
    DOB: "05/31/96",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114777.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["PIT"],
  },
  {
    name: "Oliver Bjorkstrand",
    pos: "W",
    number: "22",
    shoots: "R",
    DOB: "04/10/95",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042095.png",
    conference: "West",
    division: "Pac",
    ctry: "DNK",
    former: ["CBJ"],
  },
  {
    name: "Jordan Eberle",
    pos: "W",
    number: "7",
    shoots: "R",
    DOB: "05/15/90",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5032.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["EDM", "NYI"],
  },
  {
    name: "Eeli Tolvanen",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "04/22/99",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233720.png",
    conference: "West",
    division: "Pac",
    ctry: "FIN",
    former: ["NSH"],
  },
  {
    name: "Vince Dunn",
    pos: "D",
    number: "29",
    shoots: "L",
    DOB: "10/29/96",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904189.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["STL"],
  },
  {
    name: "Cale Fleury",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "11/19/98",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4393055.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["MTL"],
  },
  {
    name: "Adam Larsson",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "11/12/92",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562610.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["NJD", "EDM"],
  },
  {
    name: "Jamie Oleksiak",
    pos: "D",
    number: "24",
    shoots: "L",
    DOB: "12/21/92",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562625.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["DAL", "PIT"],
  },
  {
    name: "Justin Schultz",
    pos: "D",
    number: "4",
    shoots: "R",
    DOB: "07/06/90",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2501067.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["EDM", "PIT"],
  },
  {
    name: "Philipp Grubauer",
    pos: "G",
    number: "31",
    shoots: "L",
    DOB: "11/25/91",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5657.png",
    conference: "West",
    division: "Pac",
    ctry: "DEU",
    former: ["COL", "WSH"],
  },
  {
    name: "Martin Jones",
    pos: "G",
    number: "30",
    shoots: "R",
    DOB: "01/10/90",
    team: "SEA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5096.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["SJS", "LAK", "PHI"],
  },
  {
    name: "Jordan Kyrou",
    pos: "C",
    number: "25",
    shoots: "R",
    DOB: "05/05/98",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4062251.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Brayden Schenn",
    pos: "C",
    number: "10",
    shoots: "L",
    DOB: "08/22/91",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5219.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["PHI"],
  },
  {
    name: "Robert Thomas",
    pos: "C",
    number: "18",
    shoots: "R",
    DOB: "07/02/99",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233637.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Sammy Blais",
    pos: "W",
    number: "79",
    shoots: "L",
    DOB: "06/17/96",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3149697.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NYR"],
  },
  {
    name: "Pavel Buchnevich",
    pos: "W",
    number: "89",
    shoots: "L",
    DOB: "04/17/95",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042081.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: ["NYR"],
  },
  {
    name: "Brandon Saad",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "10/27/92",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563065.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["CHI", "CBJ", "COL"],
  },
  {
    name: "Jakub Vrana",
    pos: "W",
    number: "15",
    shoots: "L",
    DOB: "02/28/96",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114747.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: ["WSH", "DET"],
  },
  {
    name: "Kasperi Kapanen",
    pos: "W",
    number: "42",
    shoots: "R",
    DOB: "07/23/96",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114775.png",
    conference: "West",
    division: "Cen",
    ctry: "FIN",
    former: ["TOR", "PIT"],
  },
  {
    name: "Robert Bortuzzo",
    pos: "D",
    number: "41",
    shoots: "R",
    DOB: "03/18/89",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4916.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["PIT"],
  },
  {
    name: "Justin Faulk",
    pos: "D",
    number: "72",
    shoots: "R",
    DOB: "03/20/92",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5746.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["CAR"],
  },
  {
    name: "Torey Krug",
    pos: "D",
    number: "47",
    shoots: "L",
    DOB: "04/12/91",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2952729.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["BOS"],
  },
  {
    name: "Nick Leddy",
    pos: "D",
    number: "4",
    shoots: "L",
    DOB: "03/20/91",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5447.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NYI", "DET", "CHI"],
  },
  {
    name: "Colton Parayko",
    pos: "D",
    number: "55",
    shoots: "R",
    DOB: "05/12/93",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069341.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jordan Binnington",
    pos: "G",
    number: "50",
    shoots: "L",
    DOB: "07/11/93",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590874.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Thomas Greiss",
    pos: "G",
    number: "1",
    shoots: "L",
    DOB: "01/29/86",
    team: "STL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3375.png",
    conference: "West",
    division: "Cen",
    ctry: "DEU",
    former: ["NYI", "SJS", "ARI", "PIT", "DET"],
  },
  {
    name: "Ross Colton",
    pos: "C",
    number: "79",
    shoots: "L",
    DOB: "09/11/96",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4392471.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Brayden Point",
    pos: "C",
    number: "21",
    shoots: "R",
    DOB: "03/13/96",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151187.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Steven Stamkos",
    pos: "C",
    number: "91",
    shoots: "R",
    DOB: "02/07/90",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5037.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Pierre-Edouard Bellemare",
    pos: "W",
    number: "41",
    shoots: "L",
    DOB: "03/06/85",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3111908.png",
    conference: "East",
    division: "Atl",
    ctry: "FRA",
    former: ["PHI", "VGK", "COL"],
  },
  {
    name: "Brandon Hagel",
    pos: "W",
    number: "38",
    shoots: "L",
    DOB: "08/27/98",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4065019.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CHI"],
  },
  {
    name: "Tanner Jeannot",
    pos: "W",
    number: "84",
    shoots: "L",
    DOB: "05/29/97",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4064780.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NSH"],
  },
  {
    name: "Alex Killorn",
    pos: "W",
    number: "17",
    shoots: "L",
    DOB: "09/14/89",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4968.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Pat Maroon",
    pos: "W",
    number: "14",
    shoots: "L",
    DOB: "04/23/88",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3853.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["ANA", "STL"],
  },
  {
    name: "Nicholas Paul",
    pos: "W",
    number: "20",
    shoots: "L",
    DOB: "03/20/95",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042111.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["OTT"],
  },
  {
    name: "Nikita Kucherov",
    pos: "W",
    number: "86",
    shoots: "R",
    DOB: "06/17/93",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563060.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Corey Perry",
    pos: "W",
    number: "10",
    shoots: "R",
    DOB: "05/16/85",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2273.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["ANA", "DAL", "MTL"],
  },
  {
    name: "Erik Cernak",
    pos: "D",
    number: "81",
    shoots: "R",
    DOB: "05/28/97",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904178.png",
    conference: "East",
    division: "Atl",
    ctry: "SVK",
    former: [],
  },
  {
    name: "Victor Hedman",
    pos: "D",
    number: "77",
    shoots: "L",
    DOB: "12/18/90",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5157.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Mikhail Sergachev",
    pos: "D",
    number: "98",
    shoots: "L",
    DOB: "06/25/98",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024868.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Brian Elliott",
    pos: "G",
    number: "1",
    shoots: "L",
    DOB: "04/09/85",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2865.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["OTT", "COL", "STL", "CGY", "PHI"],
  },
  {
    name: "Andrei Vasilevskiy",
    pos: "G",
    number: "88",
    shoots: "L",
    DOB: "07/25/94",
    team: "TBL",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976847.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Noel Acciari",
    pos: "C",
    number: "52",
    shoots: "R",
    DOB: "12/01/91",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3096237.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["BOS", "FLA", "STL"],
  },
  {
    name: "Zach Aston-Reese",
    pos: "C",
    number: "12",
    shoots: "L",
    DOB: "08/10/94",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3988830.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: ["PIT"],
  },
  {
    name: "Calle Jarnkrok",
    pos: "C",
    number: "19",
    shoots: "R",
    DOB: "09/25/91",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2555316.png",
    conference: "East",
    division: "Atl",
    ctry: "SWE",
    former: ["SEA", "NSH"],
  },
  {
    name: "David Kampf",
    pos: "C",
    number: "64",
    shoots: "L",
    DOB: "01/12/95",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4267976.png",
    conference: "East",
    division: "Atl",
    ctry: "CZE",
    former: ["CHI"],
  },
  {
    name: "Alexander Kerfoot",
    pos: "C",
    number: "15",
    shoots: "L",
    DOB: "08/11/94",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3069394.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["COL"],
  },
  {
    name: "Auston Matthews",
    pos: "C",
    number: "34",
    shoots: "L",
    DOB: "09/17/97",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024123.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Ryan O'Reilly",
    pos: "C",
    number: "90",
    shoots: "L",
    DOB: "02/07/91",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5208.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["COL", "BUF", "STL"],
  },
  {
    name: "John Tavares",
    pos: "C",
    number: "91",
    shoots: "L",
    DOB: "09/20/90",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5160.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["NYI"],
  },
  {
    name: "Michael Bunting",
    pos: "W",
    number: "58",
    shoots: "L",
    DOB: "09/17/95",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3149603.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["ARI"],
  },
  {
    name: "Mitchell Marner",
    pos: "W",
    number: "16",
    shoots: "R",
    DOB: "05/05/97",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899937.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "William Nylander",
    pos: "W",
    number: "88",
    shoots: "R",
    DOB: "05/01/96",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114736.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "TJ Brodie",
    pos: "D",
    number: "78",
    shoots: "L",
    DOB: "06/07/90",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5162.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CGY"],
  },
  {
    name: "Mark Giordano",
    pos: "D",
    number: "55",
    shoots: "L",
    DOB: "10/03/83",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3006.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["CGY", "SEA"],
  },
  {
    name: "Justin Holl",
    pos: "D",
    number: "3",
    shoots: "R",
    DOB: "01/30/92",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5763.png",
    conference: "East",
    division: "Atl",
    ctry: "USA",
    former: [],
  },
  {
    name: "Jake Muzzin",
    pos: "D",
    number: "8",
    shoots: "L",
    DOB: "02/21/89",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4906.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["LAK"],
  },
  {
    name: "Morgan Rielly",
    pos: "D",
    number: "44",
    shoots: "L",
    DOB: "03/09/94",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976833.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Luke Schenn",
    pos: "D",
    number: "2",
    shoots: "R",
    DOB: "11/02/89",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5092.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["VAN", "PHI", "LAK", "ARI", "ANA", "TBL"],
  },
  {
    name: "Matt Murray",
    pos: "G",
    number: "30",
    shoots: "L",
    DOB: "05/25/94",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3067862.png",
    conference: "East",
    division: "Atl",
    ctry: "CAN",
    former: ["PIT", "OTT"],
  },
  {
    name: "Ilya Samsonov",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "02/22/97",
    team: "TOR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3900505.png",
    conference: "East",
    division: "Atl",
    ctry: "RUS",
    former: ["WSH"],
  },
  {
    name: "J.T. Miller",
    pos: "C",
    number: "9",
    shoots: "L",
    DOB: "03/14/93",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590852.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["NYR", "TBL"],
  },
  {
    name: "Elias Pettersson",
    pos: "C",
    number: "40",
    shoots: "L",
    DOB: "11/12/98",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4233566.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: [],
  },
  {
    name: "Anthony Beauvillier",
    pos: "W",
    number: "72",
    shoots: "L",
    DOB: "06/08/97",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904107.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["NYI"],
  },
  {
    name: "Tanner Pearson",
    pos: "W",
    number: "70",
    shoots: "L",
    DOB: "08/10/92",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2976860.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["LAK"],
  },
  {
    name: "Brock Boeser",
    pos: "W",
    number: "6",
    shoots: "R",
    DOB: "02/25/97",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899979.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Conor Garland",
    pos: "W",
    number: "8",
    shoots: "R",
    DOB: "03/11/96",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3939718.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["ARI"],
  },
  {
    name: "Vitali Kravtsov",
    pos: "W",
    number: "91",
    shoots: "R",
    DOB: "12/23/99",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352711.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: ["NYR"],
  },
  {
    name: "Vasily Podkolzin",
    pos: "W",
    number: "92",
    shoots: "L",
    DOB: "06/24/01",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4565231.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Ethan Bear",
    pos: "D",
    number: "74",
    shoots: "R",
    DOB: "06/26/97",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3941966.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["EDM", "CAR"],
  },
  {
    name: "Oliver Ekman-Larsson",
    pos: "D",
    number: "23",
    shoots: "L",
    DOB: "07/17/91",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5488.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["ARI"],
  },
  {
    name: "Quinn Hughes",
    pos: "D",
    number: "43",
    shoots: "L",
    DOB: "10/14/99",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4320548.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Thatcher Demko",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "12/08/95",
    team: "VAN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3096217.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: [],
  },
  {
    name: "Ivan Barbashev",
    pos: "C",
    number: "49",
    shoots: "L",
    DOB: "12/14/95",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114985.png",
    conference: "West",
    division: "Pac",
    ctry: "RUS",
    former: ["STL"],
  },
  {
    name: "Teddy Blueger",
    pos: "C",
    number: "53",
    shoots: "L",
    DOB: "08/15/94",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3024916.png",
    conference: "West",
    division: "Pac",
    ctry: "LVA",
    former: ["PIT"],
  },
  {
    name: "Jack Eichel",
    pos: "C",
    number: "9",
    shoots: "R",
    DOB: "10/28/96",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3648002.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["BUF"],
  },
  {
    name: "Brett Howden",
    pos: "C",
    number: "21",
    shoots: "L",
    DOB: "03/29/98",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024989.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["NYR"],
  },
  {
    name: "William Karlsson",
    pos: "C",
    number: "71",
    shoots: "L",
    DOB: "01/08/93",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2563057.png",
    conference: "West",
    division: "Pac",
    ctry: "SWE",
    former: ["CBJ"],
  },
  {
    name: "Phil Kessel",
    pos: "C",
    number: "8",
    shoots: "R",
    DOB: "10/02/87",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3479.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["BOS", "TOR", "PIT", "ARI"],
  },
  {
    name: "Jonathan Marchessault",
    pos: "C",
    number: "81",
    shoots: "R",
    DOB: "12/27/90",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2967072.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["FLA", "CBJ", "TBL"],
  },
  {
    name: "Nicolas Roy",
    pos: "C",
    number: "10",
    shoots: "R",
    DOB: "02/05/97",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3943996.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["CAR"],
  },
  {
    name: "Chandler Stephenson",
    pos: "C",
    number: "20",
    shoots: "L",
    DOB: "04/22/94",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3067858.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["WSH"],
  },
  {
    name: "William Carrier",
    pos: "W",
    number: "28",
    shoots: "L",
    DOB: "12/20/94",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042054.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["BUF"],
  },
  {
    name: "Keegan Kolesar",
    pos: "W",
    number: "55",
    shoots: "R",
    DOB: "04/08/97",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3941546.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Reilly Smith",
    pos: "W",
    number: "19",
    shoots: "L",
    DOB: "04/01/91",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2500990.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["FLA"],
  },
  {
    name: "Mark Stone",
    pos: "W",
    number: "61",
    shoots: "R",
    DOB: "05/13/92",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5545.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["OTT"],
  },
  {
    name: "Alec Martinez",
    pos: "D",
    number: "23",
    shoots: "L",
    DOB: "07/26/87",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3927.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["LAK"],
  },
  {
    name: "Brayden McNabb",
    pos: "D",
    number: "3",
    shoots: "L",
    DOB: "01/21/91",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5202.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["BUF", "LAK"],
  },
  {
    name: "Alex Pietrangelo",
    pos: "D",
    number: "7",
    shoots: "R",
    DOB: "01/18/90",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4013.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["STL"],
  },
  {
    name: "Shea Theodore",
    pos: "D",
    number: "27",
    shoots: "L",
    DOB: "08/03/95",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042055.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["ANA"],
  },
  {
    name: "Zach Whitecloud",
    pos: "D",
    number: "2",
    shoots: "R",
    DOB: "11/28/96",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4312877.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Jonathan Quick",
    pos: "G",
    number: "32",
    shoots: "L",
    DOB: "01/21/86",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3634.png",
    conference: "West",
    division: "Pac",
    ctry: "USA",
    former: ["LAK"],
  },
  {
    name: "Logan Thompson",
    pos: "G",
    number: "36",
    shoots: "R",
    DOB: "02/25/97",
    team: "VGK",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4272888.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Evgeny Kuznetsov",
    pos: "C",
    number: "92",
    shoots: "L",
    DOB: "05/19/92",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5647.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Craig Smith",
    pos: "C",
    number: "16",
    shoots: "R",
    DOB: "09/05/89",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2570752.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["NSH", "BOS"],
  },
  {
    name: "Dylan Strome",
    pos: "C",
    number: "17",
    shoots: "L",
    DOB: "03/07/97",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899933.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["ARI", "CHI"],
  },
  {
    name: "Alex Ovechkin",
    pos: "W",
    number: "8",
    shoots: "R",
    DOB: "09/17/85",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3101.png",
    conference: "East",
    division: "Met",
    ctry: "RUS",
    former: [],
  },
  {
    name: "Conor Sheary",
    pos: "W",
    number: "73",
    shoots: "L",
    DOB: "06/08/92",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3149827.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["PIT", "BUF"],
  },
  {
    name: "Nicolas Aube-Kubel",
    pos: "W",
    number: "96",
    shoots: "R",
    DOB: "05/10/96",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3115014.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["PHI", "COL", "TOR"],
  },
  {
    name: "Anthony Mantha",
    pos: "W",
    number: "39",
    shoots: "L",
    DOB: "09/16/94",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042037.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["DET"],
  },
  {
    name: "T.J. Oshie",
    pos: "W",
    number: "77",
    shoots: "R",
    DOB: "12/23/86",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5034.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["STL"],
  },
  {
    name: "John Carlson",
    pos: "D",
    number: "74",
    shoots: "R",
    DOB: "01/10/90",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5118.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: [],
  },
  {
    name: "Nick Jensen",
    pos: "D",
    number: "3",
    shoots: "R",
    DOB: "09/21/90",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3025608.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["DET"],
  },
  {
    name: "Rasmus Sandin",
    pos: "D",
    number: "38",
    shoots: "L",
    DOB: "03/07/00",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4352803.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: ["TOR"],
  },
  {
    name: "Trevor van Riemsdyk",
    pos: "D",
    number: "57",
    shoots: "R",
    DOB: "07/24/91",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3025524.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["CHI", "CAR"],
  },
  {
    name: "Darcy Kuemper",
    pos: "G",
    number: "35",
    shoots: "L",
    DOB: "05/05/90",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2610321.png",
    conference: "East",
    division: "Met",
    ctry: "CAN",
    former: ["MIN", "ARI", "COL"],
  },
  {
    name: "Charlie Lindgren",
    pos: "G",
    number: "79",
    shoots: "R",
    DOB: "12/18/93",
    team: "WSH",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3095975.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: ["MTL", "STL"],
  },
  {
    name: "Mason Appleton",
    pos: "C",
    number: "22",
    shoots: "R",
    DOB: "01/15/96",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3942043.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Morgan Barron",
    pos: "C",
    number: "36",
    shoots: "R",
    DOB: "12/02/98",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4316970.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["NYR"],
  },
  {
    name: "Vladislav Namestnikov",
    pos: "C",
    number: "7",
    shoots: "L",
    DOB: "11/22/92",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562622.png",
    conference: "West",
    division: "Cen",
    ctry: "RUS",
    former: ["NYR", "OTT", "COL", "DET", "TBL"],
  },
  {
    name: "Cole Perfetti",
    pos: "C",
    number: "91",
    shoots: "L",
    DOB: "01/01/02",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697392.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Mark Scheifele",
    pos: "C",
    number: "55",
    shoots: "R",
    DOB: "03/15/93",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2562632.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Kyle Connor",
    pos: "W",
    number: "81",
    shoots: "L",
    DOB: "12/09/96",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899952.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "Pierre-Luc Dubois",
    pos: "W",
    number: "80",
    shoots: "L",
    DOB: "06/24/98",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024833.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["CBJ"],
  },
  {
    name: "Nikolaj Ehlers",
    pos: "W",
    number: "27",
    shoots: "L",
    DOB: "02/14/96",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3114741.png",
    conference: "West",
    division: "Cen",
    ctry: "DNK",
    former: [],
  },
  {
    name: "Nino Niederreiter",
    pos: "W",
    number: "62",
    shoots: "L",
    DOB: "09/08/92",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5511.png",
    conference: "West",
    division: "Cen",
    ctry: "CHE",
    former: ["NYI", "MIN", "CAR", "NSH"],
  },
  {
    name: "Blake Wheeler",
    pos: "W",
    number: "26",
    shoots: "R",
    DOB: "08/31/86",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3961.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["ARI", "BOS"],
  },
  {
    name: "Dylan DeMelo",
    pos: "D",
    number: "2",
    shoots: "R",
    DOB: "05/01/93",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2590861.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["SJS", "OTT"],
  },
  {
    name: "Brenden Dillon",
    pos: "D",
    number: "5",
    shoots: "L",
    DOB: "11/13/90",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/2554903.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: ["DAL", "SJS", "WSH"],
  },
  {
    name: "Josh Morrissey",
    pos: "D",
    number: "44",
    shoots: "L",
    DOB: "03/28/95",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3042016.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Neal Pionk",
    pos: "D",
    number: "4",
    shoots: "R",
    DOB: "07/29/95",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3988847.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["NYR"],
  },
  {
    name: "Nate Schmidt",
    pos: "D",
    number: "88",
    shoots: "L",
    DOB: "07/16/91",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3024798.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: ["WSH", "VGK", "VAN"],
  },
  {
    name: "Logan Stanley",
    pos: "D",
    number: "64",
    shoots: "L",
    DOB: "05/26/98",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4024968.png",
    conference: "West",
    division: "Cen",
    ctry: "CAN",
    former: [],
  },
  {
    name: "Connor Hellebuyck",
    pos: "G",
    number: "37",
    shoots: "L",
    DOB: "05/19/93",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3020225.png",
    conference: "West",
    division: "Cen",
    ctry: "USA",
    former: [],
  },
  {
    name: "David Rittich",
    pos: "G",
    number: "33",
    shoots: "L",
    DOB: "08/19/92",
    team: "WPG",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4063288.png",
    conference: "West",
    division: "Cen",
    ctry: "CZE",
    former: ["CGY", "TOR", "NSH"],
  },
  {
    name: "Jamie Drysdale",
    pos: "D",
    number: "6",
    shoots: "R",
    DOB: "04/08/02",
    team: "ANA",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697387.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: []
  },
  {
    name: "Christopher Tanev",
    pos: "D",
    number: "8",
    shoots: "R",
    DOB: "12/20/89",
    team: "CGY",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5592.png",
    conference: "West",
    division: "Pac",
    ctry: "CAN",
    former: ["VAN"]
  },
  {
    name: "Sebastian Aho (CAR)",
    pos: "C",
    number: "20",
    shoots: "L",
    DOB: "07/26/97",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3904173.png",
    conference: "East",
    division: "Met",
    ctry: "FIN",
    former: []
  },
  {
    name: "Ondrej Kase",
    pos: "W",
    number: "73",
    shoots: "R",
    DOB: "11/08/95",
    team: "CAR",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3151430.png",
    conference: "East",
    division: "Met",
    ctry: "CZE",
    former: ["ANA", "BOS", "TOR"]
  },
  {
    name: "Cole Sillinger",
    pos: "C",
    number: "34",
    shoots: "L",
    DOB: "05/16/03",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4874725.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: []
  },
  {
    name: "Zach Werenski",
    pos: "D",
    number: "8",
    shoots: "L",
    DOB: "07/19/97",
    team: "CBJ",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/3899972.png",
    conference: "East",
    division: "Met",
    ctry: "USA",
    former: []
  },
  {
    name: "Marco Rossi",
    pos: "C",
    number: "23",
    shoots: "L",
    DOB: "09/23/01",
    team: "MIN",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4697391.png",
    conference: "West",
    division: "Cen",
    ctry: "AUT",
    former: []
  },
  {
    name: "Sebastian Aho (NYI)",
    pos: "D",
    number: "25",
    shoots: "L",
    DOB: "02/17/96",
    team: "NYI",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/4272688.png",
    conference: "East",
    division: "Met",
    ctry: "SWE",
    former: []
  },
  {
    name: "Robin Lehner",
    team: "VGK",
    pos: "G",
    ctry: "SWE",
    shoots: "L",
    DOB: "07/24/91",
    number: "90",
    conference: "West",
    division: "Pac",
    img: "https://a.espncdn.com/i/headshots/nhl/players/full/5283.png",
    former: ["OTT", "BUF", "NYI", "CHI"]
  },
];
