import Modal from "react-modal";
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface PanelProps {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
}

export function MyModal({ isOpen, close, children }: PanelProps) {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0, 0.25)",
    },
  };
  return (
    <StyledModal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={close}
      className="styled-modal"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <ModalWrapper>
        <header>
          <a className="modal-x" type="button" onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </header>
        {children}
      </ModalWrapper>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  max-height: 400px;
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-width: 600px;
  outline: none;
  display: flex;
  flex-direction: column;
`;

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  max-height: 100%;
  align-items: center;
  backface-visibility: hidden;
  padding: 1.25rem;
  transform: translate(-50%, -50%);
  width: calc(100vw - 40px);
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 1em;
`;
