interface Country {
  name: string;
  emoji: string;
}

interface CountryList {
  [key: string]: Country;
}

export const countries: CountryList = {
  AUS: {
    name: "Australia",
    emoji: "🇦🇺",
  },
  AUT: {
    name: "Austria",
    emoji: "🇦🇹",
  },
  BGR: {
    name: "Bulgaria",
    emoji: "🇧🇬",
  },
  BLR: {
    name: "Belarus",
    emoji: "🇧🇾",
  },
  CAN: {
    name: "Canada",
    emoji: "🇨🇦",
  },
  CHE: {
    name: "Switz",
    emoji: "🇨🇭",
  },
  CZE: {
    name: "Czechia",
    emoji: "🇨🇿",
  },
  DEU: {
    name: "Germany",
    emoji: "🇩🇪",
  },
  DNK: {
    name: "Denmark",
    emoji: "🇩🇰",
  },
  FIN: {
    name: "Finland",
    emoji: "🇫🇮",
  },
  FRA: {
    name: "France",
    emoji: "🇫🇷",
  },
  GBR: {
    name: "UK",
    emoji: "🇬🇧",
  },
  KAZ: {
    name: "Kazakhstan",
    emoji: "🇰🇿",
  },
  LVA: {
    name: "Latvia",
    emoji: "🇱🇻",
  },
  NLD: {
    name: "Nethlnds",
    emoji: "🇳🇱",
  },
  NOR: {
    name: "Norway",
    emoji: "🇳🇴",
  },
  RUS: {
    name: "Russia",
    emoji: "🇷🇺",
  },
  SWE: {
    name: "Sweden",
    emoji: "🇸🇪",
  },
  SVN: {
    name: "Slovenia",
    emoji: "🇸🇮",
  },
  SVK: {
    name: "Slovakia",
    emoji: "🇸🇰",
  },
  USA: {
    name: "USA",
    emoji: "🇺🇸",
  },
  UZB: {
    name: "Uzbekistan",
    emoji: "🇺🇿",
  },
};

export default countries;
