import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ReactGA from 'react-ga';

const TRACKING_ID = "G-FLL4X05B7X";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.Fragment>
      <App />
  </React.Fragment>,
  document.getElementById("root")
);
