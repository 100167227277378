import React from "react";
import '../styles/Dangle.css';

export function Dangle() {
  return (
    <div>
      <img className="header-img" src="../logo-main.png" />
    </div>
  );
}
