import { MyModal } from "./MyModal";
import React, { useMemo, useState } from "react";
import { getDayString, useTodays } from "../../hooks/useTodays";
import { SettingsData } from "../../hooks/useSettings";
import { CountDownTimer } from "../../helpers/CountDownTimer";
import { Share } from "../../helpers/Share";
import "./../../styles/GameOverModal.css";
import { getAge, getCtry } from "../../core/players2023";

interface GameOverModalProps {
  isOpen: boolean;
  close: () => void;
  winner: boolean;
  guessNumber: number;
  settingsData: SettingsData;
}

export function GameOverModal({
  isOpen,
  close,
  winner,
  guessNumber,
  settingsData,
}: GameOverModalProps) {
  const date: Date = new Date();
  let hours: number = date.getHours();
  let minutes: number = date.getMinutes();
  let seconds: number = date.getSeconds();

  if (seconds === 0) {
    minutes = 60 - minutes;
  } else {
    minutes = 59 - minutes;
  }
  if (minutes === 0) {
    hours = 24 - hours;
  } else {
    hours = 23 - hours;
  }

  seconds = 60 - seconds;

  const dayString = useMemo(
    () => getDayString(settingsData.shiftDayCount),
    [settingsData.shiftDayCount]
  );
  const [todays] = useTodays(dayString);
  const { player, guesses } = todays;
  const src = player?.img;
  const [statsOpen, setStats] = useState(false);

  function handleStats() {
    setStats(!statsOpen);
  }

  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container">
        <div
          className="silhouette-image reveal"
          style={{
            backgroundImage: "url(" + src + ")",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
          }}
        />
        {/* <img alt="player headshot" className="silhouette-image reveal" src={player?.img} /> */}
        <hr className={winner ? "green" : "red"}></hr>

        <h3 className="result-h3">
          {winner ? "Great job!" : "The correct answer was"}
        </h3>
        <h2 className="result-player-name">{player?.name}</h2>
        <h3 className="result-h3">
          {winner && guessNumber === 1 && "You solved it in " + 1 + " guess!"}
          {winner &&
            guessNumber > 1 &&
            "You solved it in " + guessNumber + " guesses!"}
          {!winner && "Better luck next time!"}
        </h3>
        <Share winner={winner} guesses={guesses} dayString={dayString} settingsData={settingsData}/>

        <hr className={winner ? "green" : "red"}></hr>
        {statsOpen === false ? (
          <>
            <p className="new-mystery">Next Dangle in:</p>
            <CountDownTimer hours={hours} minutes={minutes} seconds={seconds} />
          </>
        ) : (
          <div className="table-container2">
            <div className="table-head2">
              <div className="table-row2">
                <div>Team</div>
                <div>Conf</div>
                <div>Div</div>
                <div>Pos</div>
              </div>
            </div>
            <div className="table-body2">
              <div className="table-row2">
                <div className="table-cell2">
                  <div>
                    <img
                      alt="team logo"
                      className="table-img"
                      src={"nhl/" + player?.team + ".png"}
                    />
                  </div>
                </div>
                <div className="table-cell2">
                  <div>
                    <img
                      alt="team logo"
                      className="conference-logo"
                      src={"nhl/" + player?.conference + ".png"}
                    />
                  </div>
                </div>
                <div className="table-cell2">
                  <div>
                    <img
                      alt="team logo"
                      className="division-logo"
                      src={"nhl/" + player?.division + ".png"}
                    />
                  </div>
                </div>
                <div className="table-cell2">{player?.pos}</div>
              </div>
            </div>
            <div className="table-head2">
              <div className="table-row2">
                <div>Sh</div>
                <div>Age</div>
                <div>Ctry</div>
                <div>#</div>
              </div>
            </div>
            <div className="table-body2">
              <div className="table-row2">
                <div className="table-cell2">{player?.shoots}</div>

                <div className="table-cell2">{getAge(player)}</div>

                <div className="table-cell2">
                  <div>
                    <img
                      alt="player country"
                      className="table-img"
                      src={"flags/" + player?.ctry + ".svg"}
                    />
                  </div>{" "}
                </div>
                <div className="table-cell2">{player?.number}</div>
              </div>
            </div>
            {/* <button onClick={handleStats}>Toggle Stats</button> */}
          </div>
        )}
        {!winner && (
          <button className="toggle-button" onClick={handleStats}>
            {statsOpen ? "Toggle Countdown" : "Toggle Player Info"}
          </button>
        )}
      </div>
    </MyModal>
  );
}
