import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import seedrandom from "seedrandom";
import { players, Player } from "../core/players2023";
import { availablePlayers } from "./../core/availablePlayers";
import { Guess, loadAllGuesses, saveGuesses } from "../core/guess";

export function getDayString(shiftDayCount?: number) {
  return DateTime.now()
    .plus({ days: shiftDayCount ?? 0 })
    .toFormat("yyyy-MM-dd");
}

const forcedPlayers: Record<string, string> = {
  "2023-10-19": "Anze Kopitar",
  "2023-10-20": "Adam Fox",
  "2023-10-21": "Brad Marchand",
  "2023-10-22": "Cale Makar",
  "2023-10-23": "Cam Atkinson",
};

// const obj: Record<string, string> = {};

// for (let i = 0; i < 100; i++) {
//   const today = new Date();
//   today.setDate(today.getDate() + i);
//   const dd = String(today.getDate()).padStart(2, "0");
//   const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
//   const yyyy = today.getFullYear();

//   const todaysDate = yyyy + "-" + mm + "-" + dd;

//   obj[todaysDate] = availablePlayers[
//   Math.floor(seedrandom.alea(todaysDate)() * availablePlayers.length)].name
// }

// console.log(JSON.stringify(obj))

export function useTodays(dayString: string): [
  {
    player?: Player;
    guesses: Guess[];
  },
  (guess: Guess) => void
] {
  const [todays, setTodays] = useState<{
    player?: Player;
    guesses: Guess[];
  }>({ guesses: [] });

  const addGuess = useCallback(
    (newGuess: Guess) => {
      if (todays == null) {
        return;
      }

      const newGuesses = [...todays.guesses, newGuess];

      setTodays((prev) => ({ player: prev.player, guesses: newGuesses }));
      saveGuesses(dayString, newGuesses);
    },
    [dayString, todays]
  );

  useEffect(() => {
    const guesses = loadAllGuesses()[dayString] ?? [];
    const player = getPlayer(dayString);

    setTodays({ player, guesses });
  }, [dayString]);

  return [todays, addGuess];
}

// console.log(availlayers[
//   Math.floor(seedrandom.alea("2022-04-02")() * 500)
// ])
function getPlayer(dayString: string) {
  const forcedPlayer = forcedPlayers[dayString];
  const forced =
    forcedPlayer != null
      ? players.find((player) => player.name === forcedPlayer)
      : undefined;

  const pickedPlayer =
    forced ??
    availablePlayers[
      Math.floor(seedrandom.alea(dayString)() * availablePlayers.length)
    ];

  // console.log(
  //   availablePlayers[
  //     Math.floor(seedrandom.alea("2023-03-23")() * availablePlayers.length)
  //   ]
  // );

  return pickedPlayer;
}
