import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { players, getName } from "../core/players2023";
import { PlayerInput } from "./PlayerInput";
import { Guesses } from "./Guesses";
import { SettingsData } from "../hooks/useSettings";
import { getDayString, useTodays } from "../hooks/useTodays";
import "../styles/Search.css";
import { GameOverModal } from "./modals/GameOverModal";
import "../styles/header.css";
import { SilhouetteModal } from "./modals/SilhouetteModal";
import { saveResultsData } from "./../core/stats";
import ReactGA from "react-ga";
import { browserName } from "react-device-detect";

const MAX_TRY_COUNT = 8;

interface GameProps {
  settingsData: SettingsData;
  updateSettings: (newSettings: Partial<SettingsData>) => void;
}

export function Game({ settingsData, updateSettings }: GameProps) {
  const dayString = useMemo(
    () => getDayString(settingsData.shiftDayCount),
    [settingsData.shiftDayCount]
  );

  const playerInputRef = useRef<HTMLInputElement>(null);

  const [todays, addGuess] = useTodays(dayString);
  const { player, guesses } = todays;

  const [currentGuess, setCurrentGuess] = useState("");

  const [winner, setWinner] = useState(false);
  const [gameOverModalOpen, setGameOverModalOpen] = useState(false);

  const [silhouetteModalOpen, setSilhouetteModalOpen] = useState(false);

  const [isDisabled, setDisabled] = useState(false);

  const gameEnded =
    guesses.length === MAX_TRY_COUNT ||
    guesses[guesses.length - 1]?.name === player?.name;

  if (`${browserName}` === "Safari" || `${browserName}` === "Mobile Safari") {
    if (
      document.getElementsByClassName("suggestions-dropdown")[0] !== undefined
    ) {
      document
        .getElementsByClassName("suggestions-dropdown")[0]
        .classList.add("not-safari");
    }
  }

  //  console.log(browserName)

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (player == null) {
        return;
      }
      e.preventDefault();
      const guessedPlayer = players.find(
        (item) => getName(item) === currentGuess
      );

      ReactGA.event({
        category: "Buttons",
        action: "Guess Player",
        label: "Guess player button clicked",
        value: 1,
      });

      if (guessedPlayer == null) {
        document
          .getElementsByClassName("search-bar")[0]
          .classList.add("red-border");
        const p = document.createElement("p");
        p.innerHTML = "Invalid player name, try again!";
        p.style.color = "red";
        p.style.fontFamily = "Cairo, sans-serif";

        document
          .getElementsByClassName("search-bar")[0]
          .parentNode?.appendChild(p);
        setTimeout(() => {
          document
            .getElementsByClassName("search-bar")[0]
            .classList.remove("red-border");
          document
            .getElementsByClassName("search-bar")[0]
            .parentNode?.removeChild(p);
        }, 2000);

        return;
      }

      const newGuess = {
        name: currentGuess,
      };

      addGuess(newGuess);
      setCurrentGuess("");

      if (newGuess.name === getName(player)) {
        saveResultsData(dayString, {
          attempts: guesses.length + 1,
          result: "won",
        });
        setWinner(true);
        setGameOverModalOpen(true);
        setDisabled(true);
      } else if (
        newGuess.name !== getName(player) &&
        guesses.length === MAX_TRY_COUNT - 1
      ) {
        saveResultsData(dayString, { attempts: 8, result: "fail" });
        setWinner(false);
        setDisabled(true);
        setGameOverModalOpen(true);
      }
    },
    [addGuess, player, currentGuess]
  );

  useEffect(() => {
    const { player, guesses } = todays;
    if (
      guesses.length >= 1 &&
      player &&
      guesses[guesses.length - 1].name === getName(player)
    ) {
      setWinner(true);
      setDisabled(true);
      setGameOverModalOpen(true);
    } else if (
      player &&
      guesses.length === MAX_TRY_COUNT &&
      guesses[guesses.length - 1].name !== getName(player)
    ) {
      saveResultsData(dayString, { attempts: 8, result: "fail" });
      setWinner(false);
      setDisabled(true);
      setGameOverModalOpen(true);
    }
  }, [todays]);

  function gameOverModalHandler() {
    setGameOverModalOpen(true);
  }

  function inGameModalHandler() {
    setSilhouetteModalOpen(true);
  }

  return (
    <div className="game-container">
      <div className="search-bar-container">
        <GameOverModal
          isOpen={gameOverModalOpen}
          winner={winner}
          guessNumber={guesses.length}
          close={() => setGameOverModalOpen(false)}
          settingsData={settingsData}
        />
        <SilhouetteModal
          isOpen={silhouetteModalOpen}
          close={() => setSilhouetteModalOpen(false)}
          settingsData={settingsData}
        />

        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <PlayerInput
              inputRef={playerInputRef}
              currentGuess={currentGuess}
              setCurrentGuess={setCurrentGuess}
              disabled={isDisabled}
              placeholder={
                gameEnded
                  ? "Come Back Tomorrow!"
                  : "Guess " + (guesses.length + 1) + " of " + MAX_TRY_COUNT
              }
            />
            <button
              className="search-button"
              type="submit"
              disabled={isDisabled}
            >
              Guess
            </button>
          </div>
          <br></br>
          {settingsData.hardMode && gameEnded && (
            <a className="modal-reveal-link" onClick={gameOverModalHandler}>
              Show Results
            </a>
          )}
          {!settingsData.hardMode && gameEnded && (
            <a className="modal-reveal-link" onClick={gameOverModalHandler}>
              Show Results
            </a>
          )}

          {!settingsData.hardMode && !gameEnded && (
            <a className="modal-reveal-link" onClick={inGameModalHandler}>
              Show Silhouette
            </a>
          )}
        </form>
      </div>

      <Guesses
        rowCount={MAX_TRY_COUNT}
        guesses={guesses}
        settingsData={settingsData}
        playerInputRef={playerInputRef}
        gameOver={gameEnded}
        winner={winner}
      />
    </div>
  );
}
