export interface Player {
    name: string;
    team: string;
    pos: string;
    ctry: string;
    number: string;
    conference: string;
    division: string;
    DOB: string;
    shoots: string;
    img: string;
    former: Array<string>;
  }
  
  export const players: Player[] = [
    {
      "name": "Leo Carlsson",
      "pos": "C",
      "number": "91",
      "shoots": "--",
      "DOB": "12/26/04",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5149153.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Sam Carrick",
      "pos": "C",
      "number": "39",
      "shoots": "R",
      "DOB": "02/04/92",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5474.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Benoit-Olivier Groulx",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "02/06/00",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378685.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Adam Henrique",
      "pos": "C",
      "number": "14",
      "shoots": "L",
      "DOB": "02/06/90",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5074.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NJD"]
    },
    {
      "name": "Isac Lundestrom",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "11/06/99",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352775.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Mason McTavish",
      "pos": "C",
      "number": "23",
      "shoots": "L",
      "DOB": "01/30/03",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874718.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Strome",
      "pos": "C",
      "number": "16",
      "shoots": "R",
      "DOB": "01/11/93",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562636.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYI", "EDM", "NYR"]
    },
    {
      "name": "Trevor Zegras",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "03/20/01",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565230.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Chase De Leo",
      "pos": "W",
      "number": "69",
      "shoots": "L",
      "DOB": "10/25/95",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3150085.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ross Johnston",
      "pos": "W",
      "number": "44",
      "shoots": "L",
      "DOB": "02/18/94",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067822.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYI"]
    },
    {
      "name": "Max Jones",
      "pos": "W",
      "number": "49",
      "shoots": "L",
      "DOB": "02/17/98",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024980.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alex Killorn",
      "pos": "W",
      "number": "17",
      "shoots": "L",
      "DOB": "09/14/89",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4968.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["TBL"]
    },
    {
      "name": "Brock McGinn",
      "pos": "W",
      "number": "26",
      "shoots": "L",
      "DOB": "02/02/94",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067868.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CAR"]
    },
    {
      "name": "Brett Leason",
      "pos": "W",
      "number": "20",
      "shoots": "R",
      "DOB": "04/30/99",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565278.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jakob Silfverberg",
      "pos": "W",
      "number": "33",
      "shoots": "R",
      "DOB": "10/13/90",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590549.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["OTT"]
    },
    {
      "name": "Troy Terry",
      "pos": "W",
      "number": "19",
      "shoots": "R",
      "DOB": "09/10/97",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942905.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Frank Vatrano",
      "pos": "W",
      "number": "77",
      "shoots": "L",
      "DOB": "03/14/94",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3527554.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["BOS", "FLA", "NYR"]
    },
    {
      "name": "Jamie Drysdale",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "04/08/02",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697387.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Cam Fowler",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "12/05/91",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5495.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Radko Gudas",
      "pos": "D",
      "number": "7",
      "shoots": "R",
      "DOB": "06/05/90",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5502.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": ["TBL", "PHI", "WSH", "FLA"]
    },
    {
      "name": "Jackson LaCombe",
      "pos": "D",
      "number": "60",
      "shoots": "--",
      "DOB": "01/09/01",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565262.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Tristan Luneau",
      "pos": "D",
      "number": "67",
      "shoots": "R",
      "DOB": "01/12/04",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080220.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ilya Lyubushkin",
      "pos": "D",
      "number": "46",
      "shoots": "R",
      "DOB": "04/06/94",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4342107.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": ["ARI", "TOR", "BUF"]
    },
    {
      "name": "Pavel Mintyukov",
      "pos": "D",
      "number": "34",
      "shoots": "L",
      "DOB": "11/25/03",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080154.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Urho Vaakanainen",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "01/01/99",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233619.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Lukas Dostal",
      "pos": "G",
      "number": "1",
      "shoots": "L",
      "DOB": "06/22/00",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588165.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "John Gibson",
      "pos": "G",
      "number": "36",
      "shoots": "L",
      "DOB": "07/14/93",
      "team": "ANA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590824.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nick Bjugstad",
      "pos": "C",
      "number": "17",
      "shoots": "R",
      "DOB": "07/17/92",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5828.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["FLA", "PIT", "MIN", "EDM"]
    },
    {
      "name": "Travis Boyd",
      "pos": "C",
      "number": "72",
      "shoots": "R",
      "DOB": "09/14/93",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024784.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["WSH", "TOR", "VAN"]
    },
    {
      "name": "Logan Cooley",
      "pos": "C",
      "number": "92",
      "shoots": "L",
      "DOB": "05/04/04",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080143.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Barrett Hayton",
      "pos": "C",
      "number": "29",
      "shoots": "L",
      "DOB": "06/09/00",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352699.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alexander Kerfoot",
      "pos": "C",
      "number": "15",
      "shoots": "L",
      "DOB": "08/11/94",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069394.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["COL", "TOR"]
    },
    {
      "name": "Jack McBain",
      "pos": "C",
      "number": "22",
      "shoots": "L",
      "DOB": "01/06/00",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4755695.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Liam O'Brien",
      "pos": "C",
      "number": "38",
      "shoots": "L",
      "DOB": "07/29/94",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3150468.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["WSH", "COL"]
    },
    {
      "name": "Nick Schmaltz",
      "pos": "C",
      "number": "8",
      "shoots": "R",
      "DOB": "02/23/96",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114770.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["CHI"]
    },
    {
      "name": "Michael Carcone",
      "pos": "W",
      "number": "53",
      "shoots": "L",
      "DOB": "05/19/96",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4031645.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Lawson Crouse",
      "pos": "W",
      "number": "67",
      "shoots": "L",
      "DOB": "06/23/97",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899951.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Matias Maccelli",
      "pos": "W",
      "number": "63",
      "shoots": "L",
      "DOB": "10/14/00",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587580.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Jason Zucker",
      "pos": "W",
      "number": "16",
      "shoots": "L",
      "DOB": "01/16/92",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2593315.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["MIN", "PIT"]
    },
    {
      "name": "Clayton Keller",
      "pos": "W",
      "number": "9",
      "shoots": "L",
      "DOB": "07/29/98",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024857.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Josh Brown",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "01/21/94",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042228.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["FLA", "OTT", "BOS"]
    },
    {
      "name": "Travis Dermott",
      "pos": "D",
      "number": "33",
      "shoots": "L",
      "DOB": "12/22/96",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904172.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["VAN"]
    },
    {
      "name": "Matt Dumba",
      "pos": "D",
      "number": "24",
      "shoots": "R",
      "DOB": "07/25/94",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2970689.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["MIN"]
    },
    {
      "name": "Sean Durzi",
      "pos": "D",
      "number": "50",
      "shoots": "R",
      "DOB": "10/21/98",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378683.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["LAK"]
    },
    {
      "name": "J.J. Moser",
      "pos": "D",
      "number": "90",
      "shoots": "L",
      "DOB": "01/06/00",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874929.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Troy Stecher",
      "pos": "D",
      "number": "51",
      "shoots": "R",
      "DOB": "04/07/94",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096102.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["VAN", "DET", "LAK", "CGY"]
    },
    {
      "name": "Juuso Valimaki",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "10/06/98",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233611.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["CGY"]
    },
    {
      "name": "Connor Ingram",
      "pos": "G",
      "number": "39",
      "shoots": "L",
      "DOB": "03/31/97",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063522.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NSH"]
    },
    {
      "name": "Karel Vejmelka",
      "pos": "G",
      "number": "70",
      "shoots": "L",
      "DOB": "05/25/96",
      "team": "ARI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942065.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Johnny Beecher",
      "pos": "C",
      "number": "19",
      "shoots": "L",
      "DOB": "04/05/01",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565251.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Patrick Brown",
      "pos": "C",
      "number": "38",
      "shoots": "R",
      "DOB": "05/29/92",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2556063.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["OTT"]
    },
    {
      "name": "Charlie Coyle",
      "pos": "C",
      "number": "13",
      "shoots": "R",
      "DOB": "03/02/92",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2555315.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["MIN"]
    },
    {
      "name": "Trent Frederic",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "02/11/98",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024997.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Morgan Geekie",
      "pos": "C",
      "number": "39",
      "shoots": "R",
      "DOB": "07/20/98",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4268466.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CAR", "SEA"]
    },
    {
      "name": "Jakub Lauko",
      "pos": "C",
      "number": "94",
      "shoots": "L",
      "DOB": "03/28/00",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4391789.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Matthew Poitras",
      "pos": "C",
      "number": "51",
      "shoots": "R",
      "DOB": "03/10/04",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080221.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Pavel Zacha",
      "pos": "C",
      "number": "18",
      "shoots": "L",
      "DOB": "04/06/97",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899949.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CZE",
      "former": ["NJD"]
    },
    {
      "name": "Jake DeBrusk",
      "pos": "W",
      "number": "74",
      "shoots": "L",
      "DOB": "10/17/96",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900240.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Milan Lucic",
      "pos": "W",
      "number": "17",
      "shoots": "L",
      "DOB": "06/07/88",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3780.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["LAK", "EDM", "CGY"]
    },
    {
      "name": "Brad Marchand",
      "pos": "W",
      "number": "63",
      "shoots": "L",
      "DOB": "05/11/88",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3852.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "James van Riemsdyk",
      "pos": "W",
      "number": "21",
      "shoots": "L",
      "DOB": "05/04/89",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3822.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["TOR", "PHI"]
    },
    {
      "name": "David Pastrnak",
      "pos": "W",
      "number": "88",
      "shoots": "L",
      "DOB": "05/25/96",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114778.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Brandon Carlo",
      "pos": "D",
      "number": "25",
      "shoots": "R",
      "DOB": "11/26/96",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904175.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Derek Forbort",
      "pos": "D",
      "number": "28",
      "shoots": "L",
      "DOB": "03/04/92",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5829.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["LAK", "CGY", "WPG"]
    },
    {
      "name": "Matt Grzelcyk",
      "pos": "D",
      "number": "48",
      "shoots": "L",
      "DOB": "01/05/94",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069448.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Hampus Lindholm",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "01/20/94",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2968818.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["ANA"]
    },
    {
      "name": "Charlie McAvoy",
      "pos": "D",
      "number": "73",
      "shoots": "R",
      "DOB": "12/21/97",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3988803.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ian Mitchell",
      "pos": "D",
      "number": "14",
      "shoots": "R",
      "DOB": "01/18/99",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4319916.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CHI"]
    },
    {
      "name": "Kevin Shattenkirk",
      "pos": "D",
      "number": "12",
      "shoots": "R",
      "DOB": "01/29/89",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5008.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["ANA"]
    },
    {
      "name": "Jeremy Swayman",
      "pos": "G",
      "number": "1",
      "shoots": "L",
      "DOB": "11/24/98",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4712036.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Linus Ullmark",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "07/31/93",
      "team": "BOS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069285.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["BUF"]
    },
    {
      "name": "Dylan Cozens",
      "pos": "C",
      "number": "24",
      "shoots": "R",
      "DOB": "02/09/01",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565228.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tyson Jost",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "03/14/98",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024879.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["COL"]
    },
    {
      "name": "Peyton Krebs",
      "pos": "C",
      "number": "19",
      "shoots": "L",
      "DOB": "01/26/01",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565238.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Casey Mittelstadt",
      "pos": "C",
      "number": "37",
      "shoots": "L",
      "DOB": "11/22/98",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233575.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Matt Savoie",
      "pos": "C",
      "number": "93",
      "shoots": "R",
      "DOB": "01/01/04",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080153.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Zach Benson",
      "pos": "W",
      "number": "9",
      "shoots": "L",
      "DOB": "05/12/05",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5149186.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Zemgus Girgensons",
      "pos": "W",
      "number": "28",
      "shoots": "L",
      "DOB": "01/05/94",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2968829.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "LVA",
      "former": []
    },
    {
      "name": "Jordan Greenway",
      "pos": "W",
      "number": "12",
      "shoots": "L",
      "DOB": "02/16/97",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900260.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Victor Olofsson",
      "pos": "W",
      "number": "71",
      "shoots": "L",
      "DOB": "07/18/95",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151096.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Jeff Skinner",
      "pos": "W",
      "number": "53",
      "shoots": "L",
      "DOB": "05/16/92",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5540.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CAR"]
    },
    {
      "name": "Kyle Okposo",
      "pos": "W",
      "number": "21",
      "shoots": "R",
      "DOB": "04/16/88",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3859.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["NYI"]
    },
    {
      "name": "JJ Peterka",
      "pos": "W",
      "number": "77",
      "shoots": "L",
      "DOB": "01/14/02",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697438.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Tage Thompson",
      "pos": "W",
      "number": "72",
      "shoots": "R",
      "DOB": "10/30/97",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024988.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alex Tuch",
      "pos": "W",
      "number": "89",
      "shoots": "R",
      "DOB": "05/10/96",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114766.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["VGK"]
    },
    {
      "name": "Jacob Bryson",
      "pos": "D",
      "number": "78",
      "shoots": "L",
      "DOB": "11/18/97",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4197014.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Connor Clifton",
      "pos": "D",
      "number": "75",
      "shoots": "R",
      "DOB": "04/28/95",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042191.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["BOS"]
    },
    {
      "name": "Rasmus Dahlin",
      "pos": "D",
      "number": "26",
      "shoots": "L",
      "DOB": "04/13/00",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4294163.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Erik Johnson",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "03/21/88",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3649.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["STL", "COL"]
    },
    {
      "name": "Henri Jokiharju",
      "pos": "D",
      "number": "10",
      "shoots": "R",
      "DOB": "06/17/99",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233705.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Owen Power",
      "pos": "D",
      "number": "25",
      "shoots": "L",
      "DOB": "11/22/02",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4781556.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mattias Samuelsson",
      "pos": "D",
      "number": "23",
      "shoots": "L",
      "DOB": "03/14/00",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378656.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Eric Comrie",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "07/06/95",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042057.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["WPG", "DET", "NJD"]
    },
    {
      "name": "Devon Levi",
      "pos": "G",
      "number": "27",
      "shoots": "L",
      "DOB": "12/27/01",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4894487.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ukko-Pekka Luukkonen",
      "pos": "G",
      "number": "1",
      "shoots": "L",
      "DOB": "03/09/99",
      "team": "BUF",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233889.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Mikael Backlund",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "03/17/89",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3797.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Blake Coleman",
      "pos": "C",
      "number": "20",
      "shoots": "L",
      "DOB": "11/28/91",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563026.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["NJD", "TBL"]
    },
    {
      "name": "Dillon Dube",
      "pos": "C",
      "number": "29",
      "shoots": "L",
      "DOB": "07/20/98",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063268.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jonathan Huberdeau",
      "pos": "C",
      "number": "10",
      "shoots": "L",
      "DOB": "06/04/93",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562606.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["FLA"]
    },
    {
      "name": "Nazem Kadri",
      "pos": "C",
      "number": "91",
      "shoots": "L",
      "DOB": "10/06/90",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5349.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["TOR", "COL"]
    },
    {
      "name": "Oliver Kylington",
      "pos": "C",
      "number": "58",
      "shoots": "L",
      "DOB": "05/19/97",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900247.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Elias Lindholm",
      "pos": "C",
      "number": "28",
      "shoots": "R",
      "DOB": "12/02/94",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041994.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["CAR"]
    },
    {
      "name": "Jakob Pelletier",
      "pos": "C",
      "number": "22",
      "shoots": "L",
      "DOB": "03/07/01",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565247.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Kevin Rooney",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "05/21/93",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096235.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Adam Ruzicka",
      "pos": "C",
      "number": "63",
      "shoots": "L",
      "DOB": "05/11/99",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392768.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SVK",
      "former": []
    },
    {
      "name": "Yegor Sharangovich",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "06/06/98",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587843.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "BLR",
      "former": ["NJD"]
    },
    {
      "name": "A.J. Greer",
      "pos": "W",
      "number": "18",
      "shoots": "L",
      "DOB": "12/14/96",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3648015.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["COL", "NJD", "BOS"]
    },
    {
      "name": "Dryden Hunt",
      "pos": "W",
      "number": "15",
      "shoots": "L",
      "DOB": "11/24/95",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063377.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Andrew Mangiapane",
      "pos": "W",
      "number": "88",
      "shoots": "L",
      "DOB": "04/04/96",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149619.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Rasmus Andersson",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "10/27/96",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904186.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Dennis Gilbert",
      "pos": "D",
      "number": "48",
      "shoots": "L",
      "DOB": "10/30/96",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942030.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Noah Hanifin",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "01/25/97",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3652964.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["CAR"]
    },
    {
      "name": "Jordan Oesterle",
      "pos": "D",
      "number": "82",
      "shoots": "L",
      "DOB": "06/25/92",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3097115.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["EDM", "CHI", "ARI", "DET"]
    },
    {
      "name": "Chris Tanev",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "12/20/89",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5592.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "MacKenzie Weegar",
      "pos": "D",
      "number": "52",
      "shoots": "R",
      "DOB": "01/07/94",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042269.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["FLA"]
    },
    {
      "name": "Nikita Zadorov",
      "pos": "D",
      "number": "16",
      "shoots": "L",
      "DOB": "04/16/95",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042021.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": ["BUF", "COL", "CHI"]
    },
    {
      "name": "Jacob Markstrom",
      "pos": "G",
      "number": "25",
      "shoots": "L",
      "DOB": "01/31/90",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5452.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["VAN"]
    },
    {
      "name": "Dan Vladar",
      "pos": "G",
      "number": "80",
      "shoots": "L",
      "DOB": "08/20/97",
      "team": "CGY",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942459.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": ["BOS"]
    },
    {
      "name": "Sebastian Aho (CAR)",
      "pos": "C",
      "number": "20",
      "shoots": "L",
      "DOB": "07/26/97",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904173.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Jack Drury",
      "pos": "C",
      "number": "18",
      "shoots": "R",
      "DOB": "02/03/00",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378671.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Seth Jarvis",
      "pos": "C",
      "number": "24",
      "shoots": "R",
      "DOB": "02/01/02",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697396.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jesperi Kotkaniemi",
      "pos": "C",
      "number": "82",
      "shoots": "L",
      "DOB": "07/06/00",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352692.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["MTL"]
    },
    {
      "name": "Martin Necas",
      "pos": "C",
      "number": "88",
      "shoots": "R",
      "DOB": "01/15/99",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233586.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Vasiliy Ponomarev",
      "pos": "C",
      "number": "92",
      "shoots": "L",
      "DOB": "03/13/02",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697456.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Jordan Staal",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "09/10/88",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3541.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["PIT"]
    },
    {
      "name": "Ryan Suzuki",
      "pos": "C",
      "number": "61",
      "shoots": "L",
      "DOB": "05/28/01",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565249.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Michael Bunting",
      "pos": "W",
      "number": "58",
      "shoots": "L",
      "DOB": "09/17/95",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149603.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["ARI", "TOR"]
    },
    {
      "name": "Brendan Lemieux",
      "pos": "W",
      "number": "28",
      "shoots": "L",
      "DOB": "03/15/96",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114983.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["NYR", "WPG", "PHI"]
    },
    {
      "name": "Jordan Martinook",
      "pos": "W",
      "number": "48",
      "shoots": "L",
      "DOB": "07/25/92",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2989376.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["ARI"]
    },
    {
      "name": "Teuvo Teravainen",
      "pos": "W",
      "number": "86",
      "shoots": "L",
      "DOB": "09/11/94",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2592095.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["CHI"]
    },
    {
      "name": "Jesper Fast",
      "pos": "W",
      "number": "71",
      "shoots": "R",
      "DOB": "12/02/91",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590847.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["NYR"]
    },
    {
      "name": "Stefan Noesen",
      "pos": "W",
      "number": "23",
      "shoots": "R",
      "DOB": "02/12/93",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562623.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["ANA", "NJD", "PIT", "SJS", "TOR"]
    },
    {
      "name": "Andrei Svechnikov",
      "pos": "W",
      "number": "37",
      "shoots": "L",
      "DOB": "03/26/00",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352683.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Brent Burns",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "03/09/85",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2300.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["MIN", "SJS"]
    },
    {
      "name": "Jalen Chatfield",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "05/15/96",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063449.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["VAN"]
    },
    {
      "name": "Tony DeAngelo",
      "pos": "D",
      "number": "77",
      "shoots": "R",
      "DOB": "10/24/95",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114769.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["ARI", "NYR", "PHI"]
    },
    {
      "name": "Dmitry Orlov",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "07/23/91",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5646.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": ["WSH", "BOS"]
    },
    {
      "name": "Brett Pesce",
      "pos": "D",
      "number": "22",
      "shoots": "R",
      "DOB": "11/15/94",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025535.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Brady Skjei",
      "pos": "D",
      "number": "76",
      "shoots": "L",
      "DOB": "03/26/94",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976856.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["NYR"]
    },
    {
      "name": "Jaccob Slavin",
      "pos": "D",
      "number": "74",
      "shoots": "L",
      "DOB": "05/01/94",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069836.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Frederik Andersen",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "10/02/89",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2517899.png",
      "conference": "East",
      "division": "Met",
      "ctry": "DNK",
      "former": ["ANA", "TOR"]
    },
    {
      "name": "Antti Raanta",
      "pos": "G",
      "number": "32",
      "shoots": "L",
      "DOB": "05/12/89",
      "team": "CAR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3037703.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["NYR", "ARI", "CHI"]
    },
    {
      "name": "Andreas Athanasiou",
      "pos": "C",
      "number": "89",
      "shoots": "L",
      "DOB": "08/06/94",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3068035.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["DET", "EDM"]
    },
    {
      "name": "Connor Bedard",
      "pos": "C",
      "number": "98",
      "shoots": "R",
      "DOB": "07/17/05",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5149125.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Colin Blackwell",
      "pos": "C",
      "number": "43",
      "shoots": "R",
      "DOB": "03/28/93",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590857.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NYR", "SEA", "NSH", "TOR"]
    },
    {
      "name": "Jason Dickinson",
      "pos": "C",
      "number": "16",
      "shoots": "L",
      "DOB": "07/04/95",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042062.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Donato",
      "pos": "C",
      "number": "8",
      "shoots": "L",
      "DOB": "04/09/96",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3115033.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["MIN", "BOS", "SEA"]
    },
    {
      "name": "Cole Guttman",
      "pos": "C",
      "number": "70",
      "shoots": "R",
      "DOB": "04/06/99",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588042.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Reese Johnson",
      "pos": "C",
      "number": "52",
      "shoots": "R",
      "DOB": "07/10/98",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4586824.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tyler Johnson",
      "pos": "C",
      "number": "90",
      "shoots": "R",
      "DOB": "07/29/90",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5706.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["TBL"]
    },
    {
      "name": "Luke Philp",
      "pos": "C",
      "number": "39",
      "shoots": "R",
      "DOB": "11/06/95",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064872.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Nick Foligno",
      "pos": "W",
      "number": "17",
      "shoots": "L",
      "DOB": "10/31/87",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3535.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["CBJ", "TOR", "BOS"]
    },
    {
      "name": "Taylor Hall",
      "pos": "W",
      "number": "71",
      "shoots": "L",
      "DOB": "11/14/91",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5428.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["EDM", "NJD", "ARI", "BUF", "BOS"]
    },
    {
      "name": "Boris Katchouk",
      "pos": "W",
      "number": "14",
      "shoots": "L",
      "DOB": "06/18/98",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063495.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Lukas Reichel",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "05/17/02",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697400.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Samuel Savoie",
      "pos": "W",
      "number": "67",
      "shoots": "--",
      "DOB": "03/25/04",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5103701.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "MacKenzie Entwistle",
      "pos": "W",
      "number": "58",
      "shoots": "R",
      "DOB": "07/14/99",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4393072.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Corey Perry",
      "pos": "W",
      "number": "94",
      "shoots": "R",
      "DOB": "05/16/85",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2273.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["ANA", "DAL", "MTL", "TBL"]
    },
    {
      "name": "Taylor Raddysh",
      "pos": "W",
      "number": "11",
      "shoots": "R",
      "DOB": "02/18/98",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063502.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["TBL"]
    },
    {
      "name": "Seth Jones",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "10/03/94",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041992.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NSH", "CBJ"]
    },
    {
      "name": "Wyatt Kaiser",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "07/31/02",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697717.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Kevin Korchinski",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "06/21/04",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080148.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Connor Murphy",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "03/26/93",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562618.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["ARI"]
    },
    {
      "name": "Jarred Tinordi",
      "pos": "D",
      "number": "25",
      "shoots": "L",
      "DOB": "02/20/92",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5549.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alex Vlasic",
      "pos": "D",
      "number": "72",
      "shoots": "L",
      "DOB": "06/05/01",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565266.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nikita Zaitsev",
      "pos": "D",
      "number": "22",
      "shoots": "R",
      "DOB": "10/29/91",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4010739.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["TOR"]
    },
    {
      "name": "Petr Mrazek",
      "pos": "G",
      "number": "34",
      "shoots": "L",
      "DOB": "02/14/92",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5619.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CZE",
      "former": ["DET", "CAR", "TOR"]
    },
    {
      "name": "Arvid Soderblom",
      "pos": "G",
      "number": "40",
      "shoots": "L",
      "DOB": "08/19/99",
      "team": "CHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4894729.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Andrew Cogliano",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "06/14/87",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3655.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["EDM", "ANA", "DAL", "SJS"]
    },
    {
      "name": "Ross Colton",
      "pos": "C",
      "number": "20",
      "shoots": "L",
      "DOB": "09/11/96",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392471.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["TBL"]
    },
    {
      "name": "Jean-Luc Foudy",
      "pos": "C",
      "number": "93",
      "shoots": "R",
      "DOB": "05/13/02",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697701.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Johansen",
      "pos": "C",
      "number": "12",
      "shoots": "R",
      "DOB": "07/31/92",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5434.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["CBJ", "NSH"]
    },
    {
      "name": "Nathan MacKinnon",
      "pos": "C",
      "number": "29",
      "shoots": "R",
      "DOB": "09/01/95",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041969.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Fredrik Olofsson",
      "pos": "C",
      "number": "22",
      "shoots": "L",
      "DOB": "05/27/96",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151772.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["DAL"]
    },
    {
      "name": "Jonathan Drouin",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "03/28/95",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041971.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["TBL", "MTL"]
    },
    {
      "name": "Gabriel Landeskog",
      "pos": "W",
      "number": "92",
      "shoots": "L",
      "DOB": "11/23/92",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562609.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Artturi Lehkonen",
      "pos": "W",
      "number": "62",
      "shoots": "L",
      "DOB": "07/04/95",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042050.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["MTL"]
    },
    {
      "name": "Tomas Tatar",
      "pos": "W",
      "number": "90",
      "shoots": "L",
      "DOB": "12/01/90",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5227.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SVK",
      "former": ["DET", "VGK", "MTL", "NJD"]
    },
    {
      "name": "Miles Wood",
      "pos": "W",
      "number": "28",
      "shoots": "L",
      "DOB": "09/13/95",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042110.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NJD"]
    },
    {
      "name": "Alex Beaucage",
      "pos": "W",
      "number": "74",
      "shoots": "R",
      "DOB": "07/25/01",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588183.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Valeri Nichushkin",
      "pos": "W",
      "number": "13",
      "shoots": "L",
      "DOB": "03/04/95",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042003.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["DAL"]
    },
    {
      "name": "Logan O'Connor",
      "pos": "W",
      "number": "25",
      "shoots": "R",
      "DOB": "08/14/96",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3988782.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Mikko Rantanen",
      "pos": "W",
      "number": "96",
      "shoots": "L",
      "DOB": "10/29/96",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899938.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Chris Wagner",
      "pos": "W",
      "number": "14",
      "shoots": "R",
      "DOB": "05/27/91",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590833.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Bowen Byram",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "06/13/01",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565225.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Samuel Girard",
      "pos": "D",
      "number": "49",
      "shoots": "L",
      "DOB": "05/12/98",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063401.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jack Johnson",
      "pos": "D",
      "number": "3",
      "shoots": "L",
      "DOB": "01/13/87",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3583.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["LAK", "CBJ", "PIT", "NYR", "COL"]
    },
    {
      "name": "Kurtis MacDermid",
      "pos": "D",
      "number": "56",
      "shoots": "L",
      "DOB": "03/25/94",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2992930.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Cale Makar",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "10/30/98",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233563.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Josh Manson",
      "pos": "D",
      "number": "42",
      "shoots": "R",
      "DOB": "10/07/91",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590829.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["ANA"]
    },
    {
      "name": "Devon Toews",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "02/21/94",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096249.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NYI"]
    },
    {
      "name": "Pavel Francouz",
      "pos": "G",
      "number": "39",
      "shoots": "R",
      "DOB": "06/03/90",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4294197.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Alexandar Georgiev",
      "pos": "G",
      "number": "40",
      "shoots": "L",
      "DOB": "02/10/96",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4239677.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "BGR",
      "former": ["NYR"]
    },
    {
      "name": "Ivan Prosvetov",
      "pos": "G",
      "number": "50",
      "shoots": "L",
      "DOB": "03/05/99",
      "team": "COL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587557.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["ARI"]
    },
    {
      "name": "Adam Fantilli",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "10/12/04",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5136617.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Liam Foudy",
      "pos": "C",
      "number": "19",
      "shoots": "L",
      "DOB": "02/04/00",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352758.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Boone Jenner",
      "pos": "C",
      "number": "38",
      "shoots": "L",
      "DOB": "06/15/93",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563054.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Kent Johnson",
      "pos": "C",
      "number": "91",
      "shoots": "L",
      "DOB": "10/18/02",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4781553.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Sean Kuraly",
      "pos": "C",
      "number": "7",
      "shoots": "L",
      "DOB": "01/20/93",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2564154.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["BOS", "CBJ"]
    },
    {
      "name": "Jack Roslovic",
      "pos": "C",
      "number": "96",
      "shoots": "R",
      "DOB": "01/29/97",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904098.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["WPG"]
    },
    {
      "name": "Cole Sillinger",
      "pos": "C",
      "number": "4",
      "shoots": "L",
      "DOB": "05/16/03",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874725.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alexandre Texier",
      "pos": "C",
      "number": "42",
      "shoots": "L",
      "DOB": "09/13/99",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233880.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FRA",
      "former": []
    },
    {
      "name": "Johnny Gaudreau",
      "pos": "W",
      "number": "13",
      "shoots": "L",
      "DOB": "08/13/93",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563039.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["CGY"]
    },
    {
      "name": "Kirill Marchenko",
      "pos": "W",
      "number": "86",
      "shoots": "R",
      "DOB": "08/21/00",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587996.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Emil Bemstrom",
      "pos": "W",
      "number": "52",
      "shoots": "R",
      "DOB": "06/01/99",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4423398.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Justin Danforth",
      "pos": "W",
      "number": "17",
      "shoots": "R",
      "DOB": "03/15/93",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4894355.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Patrik Laine",
      "pos": "W",
      "number": "29",
      "shoots": "R",
      "DOB": "04/19/98",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024820.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["WPG"]
    },
    {
      "name": "Mathieu Olivier",
      "pos": "W",
      "number": "24",
      "shoots": "R",
      "DOB": "02/11/97",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064781.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["NSH"]
    },
    {
      "name": "Jake Bean",
      "pos": "D",
      "number": "22",
      "shoots": "L",
      "DOB": "06/09/98",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024916.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Adam Boqvist",
      "pos": "D",
      "number": "27",
      "shoots": "R",
      "DOB": "08/15/00",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352706.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Erik Gudbranson",
      "pos": "D",
      "number": "44",
      "shoots": "R",
      "DOB": "01/07/92",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5503.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["FLA", "PIT", "VAN", "OTT", "ANA", "NSH", "CGY"]
    },
    {
      "name": "David Jiricek",
      "pos": "D",
      "number": "55",
      "shoots": "R",
      "DOB": "11/28/03",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080147.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Denton Mateychuk",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "07/12/04",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080156.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Andrew Peeke",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "03/17/98",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064936.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ivan Provorov",
      "pos": "D",
      "number": "9",
      "shoots": "L",
      "DOB": "01/13/97",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899939.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": ["PHI"]
    },
    {
      "name": "Damon Severson",
      "pos": "D",
      "number": "78",
      "shoots": "R",
      "DOB": "08/07/94",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3068087.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["NJD"]
    },
    {
      "name": "Zach Werenski",
      "pos": "D",
      "number": "8",
      "shoots": "L",
      "DOB": "07/19/97",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899972.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Elvis Merzlikins",
      "pos": "G",
      "number": "90",
      "shoots": "L",
      "DOB": "04/13/94",
      "team": "CBJ",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151038.png",
      "conference": "East",
      "division": "Met",
      "ctry": "LVA",
      "former": []
    },
    {
      "name": "Ty Dellandrea",
      "pos": "C",
      "number": "10",
      "shoots": "R",
      "DOB": "07/21/00",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352747.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Matt Duchene",
      "pos": "C",
      "number": "95",
      "shoots": "L",
      "DOB": "01/16/91",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5161.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["COL", "OTT", "CBJ", "NSH"]
    },
    {
      "name": "Radek Faksa",
      "pos": "C",
      "number": "12",
      "shoots": "L",
      "DOB": "01/09/94",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976842.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Roope Hintz",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "11/17/96",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904183.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Wyatt Johnston",
      "pos": "C",
      "number": "53",
      "shoots": "R",
      "DOB": "05/14/03",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874740.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Joe Pavelski",
      "pos": "C",
      "number": "16",
      "shoots": "R",
      "DOB": "07/11/84",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3451.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["SJS"]
    },
    {
      "name": "Tyler Seguin",
      "pos": "C",
      "number": "91",
      "shoots": "R",
      "DOB": "01/31/92",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5430.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["BOS"]
    },
    {
      "name": "Craig Smith",
      "pos": "C",
      "number": "15",
      "shoots": "R",
      "DOB": "09/05/89",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2570752.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NSH", "BOS", "WSH"]
    },
    {
      "name": "Sam Steel",
      "pos": "C",
      "number": "18",
      "shoots": "L",
      "DOB": "02/03/98",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024998.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["ANA", "MIN"]
    },
    {
      "name": "Chase Wheatcroft",
      "pos": "C",
      "number": "48",
      "shoots": "L",
      "DOB": "05/28/02",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5137011.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jamie Benn",
      "pos": "W",
      "number": "14",
      "shoots": "L",
      "DOB": "07/18/89",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3998.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mason Marchment",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "06/18/95",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4272192.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["TOR", "FLA"]
    },
    {
      "name": "Jason Robertson",
      "pos": "W",
      "number": "21",
      "shoots": "L",
      "DOB": "07/22/99",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233875.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Evgenii Dadonov",
      "pos": "W",
      "number": "63",
      "shoots": "L",
      "DOB": "03/12/89",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4969.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["FLA", "OTT", "VGK"]
    },
    {
      "name": "Jani Hakanpaa",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "03/31/92",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2960288.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["ANA", "CAR"]
    },
    {
      "name": "Joel Hanley",
      "pos": "D",
      "number": "44",
      "shoots": "R",
      "DOB": "06/08/91",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149612.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Thomas Harley",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "08/19/01",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565239.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Miro Heiskanen",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "07/18/99",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233536.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Esa Lindell",
      "pos": "D",
      "number": "23",
      "shoots": "L",
      "DOB": "05/23/94",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069352.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Nils Lundkvist",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "07/27/00",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352800.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["NYR"]
    },
    {
      "name": "Jerad Rosburg",
      "pos": "D",
      "number": "39",
      "shoots": "L",
      "DOB": "06/18/96",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4712026.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ryan Suter",
      "pos": "D",
      "number": "20",
      "shoots": "L",
      "DOB": "01/21/85",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3047.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["MIN", "NSH"]
    },
    {
      "name": "Jake Oettinger",
      "pos": "G",
      "number": "29",
      "shoots": "L",
      "DOB": "12/18/98",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4196914.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Scott Wedgewood",
      "pos": "G",
      "number": "41",
      "shoots": "L",
      "DOB": "08/14/92",
      "team": "DAL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5622.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NJD", "ARI"]
    },
    {
      "name": "Andrew Copp",
      "pos": "C",
      "number": "18",
      "shoots": "L",
      "DOB": "07/08/94",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042114.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["WPG", "NYR"]
    },
    {
      "name": "Austin Czarnik",
      "pos": "C",
      "number": "21",
      "shoots": "R",
      "DOB": "12/12/92",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024816.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Robby Fabbri",
      "pos": "C",
      "number": "14",
      "shoots": "L",
      "DOB": "01/22/96",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114774.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Klim Kostin",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "05/05/99",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233738.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": ["EDM"]
    },
    {
      "name": "Dylan Larkin",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "07/30/96",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114755.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Michael Rasmussen",
      "pos": "C",
      "number": "27",
      "shoots": "L",
      "DOB": "04/17/99",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233576.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Joe Veleno",
      "pos": "C",
      "number": "90",
      "shoots": "L",
      "DOB": "01/13/00",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352804.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "J.T. Compher",
      "pos": "W",
      "number": "37",
      "shoots": "R",
      "DOB": "04/08/95",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041995.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["COL"]
    },
    {
      "name": "David Perron",
      "pos": "W",
      "number": "57",
      "shoots": "R",
      "DOB": "05/28/88",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3792.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["EDM", "PIT", "ANA", "VGK", "STL"]
    },
    {
      "name": "Lucas Raymond",
      "pos": "W",
      "number": "23",
      "shoots": "R",
      "DOB": "03/28/02",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697385.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Alex DeBrincat",
      "pos": "W",
      "number": "93",
      "shoots": "R",
      "DOB": "12/18/97",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063433.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["CHI", "OTT"]
    },
    {
      "name": "Christian Fischer",
      "pos": "W",
      "number": "36",
      "shoots": "R",
      "DOB": "04/15/97",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904170.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["ARI"]
    },
    {
      "name": "Matt Luff",
      "pos": "W",
      "number": "22",
      "shoots": "R",
      "DOB": "05/05/97",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4062234.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Daniel Sprong",
      "pos": "W",
      "number": "88",
      "shoots": "R",
      "DOB": "03/17/97",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904181.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "NLD",
      "former": ["PIT", "ANA", "WSH", "SEA"]
    },
    {
      "name": "Ben Chiarot",
      "pos": "D",
      "number": "8",
      "shoots": "L",
      "DOB": "05/09/91",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5246.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["WPG", "MTL", "FLA"]
    },
    {
      "name": "Shayne Gostisbehere",
      "pos": "D",
      "number": "41",
      "shoots": "L",
      "DOB": "04/20/93",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025662.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["PHI", "ARI", "CAR"]
    },
    {
      "name": "Justin Holl",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "01/30/92",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5763.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["TOR"]
    },
    {
      "name": "Olli Maatta",
      "pos": "D",
      "number": "2",
      "shoots": "L",
      "DOB": "08/22/94",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976850.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": ["PIT", "CHI", "LAK"]
    },
    {
      "name": "Jeff Petry",
      "pos": "D",
      "number": "46",
      "shoots": "R",
      "DOB": "12/09/87",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5407.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["EDM", "MTL", "PIT"]
    },
    {
      "name": "Moritz Seider",
      "pos": "D",
      "number": "53",
      "shoots": "R",
      "DOB": "04/06/01",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565227.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Jake Walman",
      "pos": "D",
      "number": "96",
      "shoots": "L",
      "DOB": "02/20/96",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151136.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ville Husso",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "02/06/95",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151137.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": ["STL"]
    },
    {
      "name": "Alex Lyon",
      "pos": "G",
      "number": "34",
      "shoots": "L",
      "DOB": "12/09/92",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3654006.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["FLA"]
    },
    {
      "name": "James Reimer",
      "pos": "G",
      "number": "47",
      "shoots": "L",
      "DOB": "03/15/88",
      "team": "DET",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3870.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["TOR", "CAR", "FLA", "SJS"]
    },
    {
      "name": "Leon Draisaitl",
      "pos": "C",
      "number": "29",
      "shoots": "L",
      "DOB": "10/27/95",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114727.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Mattias Janmark",
      "pos": "C",
      "number": "13",
      "shoots": "L",
      "DOB": "12/08/92",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042085.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Connor McDavid",
      "pos": "C",
      "number": "97",
      "shoots": "L",
      "DOB": "01/13/97",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3895074.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan McLeod",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "09/21/99",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378669.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Nugent-Hopkins",
      "pos": "C",
      "number": "93",
      "shoots": "L",
      "DOB": "04/12/93",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562624.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Derek Ryan",
      "pos": "C",
      "number": "10",
      "shoots": "R",
      "DOB": "12/29/86",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3902133.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["CAR", "CGY"]
    },
    {
      "name": "Warren Foegele",
      "pos": "W",
      "number": "37",
      "shoots": "L",
      "DOB": "04/01/96",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151036.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CAR"]
    },
    {
      "name": "Dylan Holloway",
      "pos": "W",
      "number": "55",
      "shoots": "L",
      "DOB": "09/23/01",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697397.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Zach Hyman",
      "pos": "W",
      "number": "18",
      "shoots": "R",
      "DOB": "06/09/92",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5509.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["TOR"]
    },
    {
      "name": "Evander Kane",
      "pos": "W",
      "number": "91",
      "shoots": "L",
      "DOB": "08/02/91",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5251.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["WIN", "BUF", "SJS"]
    },
    {
      "name": "Connor Brown",
      "pos": "W",
      "number": "28",
      "shoots": "R",
      "DOB": "01/14/94",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067951.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["TOR", "OTT", "WSH"]
    },
    {
      "name": "Evan Bouchard",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "10/20/99",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352722.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Philip Broberg",
      "pos": "D",
      "number": "86",
      "shoots": "L",
      "DOB": "06/25/01",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565229.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Cody Ceci",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "12/21/93",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976843.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["OTT", "TOR", "PIT"]
    },
    {
      "name": "Vincent Desharnais",
      "pos": "D",
      "number": "73",
      "shoots": "R",
      "DOB": "05/29/96",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392260.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mattias Ekholm",
      "pos": "D",
      "number": "14",
      "shoots": "L",
      "DOB": "05/24/90",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2558631.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["NSH"]
    },
    {
      "name": "Brett Kulak",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "01/06/94",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3068665.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CGY", "MTL"]
    },
    {
      "name": "Darnell Nurse",
      "pos": "D",
      "number": "25",
      "shoots": "L",
      "DOB": "02/04/95",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041997.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jack Campbell",
      "pos": "G",
      "number": "36",
      "shoots": "L",
      "DOB": "01/09/92",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5473.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["LAK", "TOR"]
    },
    {
      "name": "Stuart Skinner",
      "pos": "G",
      "number": "74",
      "shoots": "L",
      "DOB": "11/01/98",
      "team": "EDM",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4268767.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Aleksander Barkov",
      "pos": "C",
      "number": "16",
      "shoots": "L",
      "DOB": "09/02/95",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041970.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Sam Bennett",
      "pos": "C",
      "number": "9",
      "shoots": "L",
      "DOB": "06/20/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114732.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CGY"]
    },
    {
      "name": "Nick Cousins",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "07/20/93",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563027.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["PHI", "ARI", "MTL", "VGK", "NSH"]
    },
    {
      "name": "Steven Lorentz",
      "pos": "C",
      "number": "18",
      "shoots": "L",
      "DOB": "04/13/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3939658.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CAR", "SJS"]
    },
    {
      "name": "Anton Lundell",
      "pos": "C",
      "number": "15",
      "shoots": "L",
      "DOB": "10/03/01",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697395.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Eetu Luostarinen",
      "pos": "C",
      "number": "27",
      "shoots": "L",
      "DOB": "09/02/98",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233877.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": ["CAR"]
    },
    {
      "name": "Sam Reinhart",
      "pos": "C",
      "number": "13",
      "shoots": "R",
      "DOB": "11/06/95",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114722.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["BUF"]
    },
    {
      "name": "Evan Rodrigues",
      "pos": "C",
      "number": "17",
      "shoots": "R",
      "DOB": "07/28/93",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3648008.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["BUF", "PIT", "COL"]
    },
    {
      "name": "Kevin Stenlund",
      "pos": "C",
      "number": "82",
      "shoots": "R",
      "DOB": "09/20/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904191.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["WPG"]
    },
    {
      "name": "Carter Verhaeghe",
      "pos": "C",
      "number": "23",
      "shoots": "L",
      "DOB": "08/14/95",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042088.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["TBL"]
    },
    {
      "name": "Ryan Lomberg",
      "pos": "W",
      "number": "94",
      "shoots": "L",
      "DOB": "12/09/94",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942548.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CGY"]
    },
    {
      "name": "Matthew Tkachuk",
      "pos": "W",
      "number": "19",
      "shoots": "L",
      "DOB": "12/11/97",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024854.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["CGY"]
    },
    {
      "name": "Mackie Samoskevich",
      "pos": "W",
      "number": "25",
      "shoots": "R",
      "DOB": "11/15/02",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874741.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Aaron Ekblad",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "02/07/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114717.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Oliver Ekman-Larsson",
      "pos": "D",
      "number": "91",
      "shoots": "L",
      "DOB": "07/17/91",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5488.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["ARI", "VAN"]
    },
    {
      "name": "Gustav Forsling",
      "pos": "D",
      "number": "42",
      "shoots": "L",
      "DOB": "06/12/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151784.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["CHI"]
    },
    {
      "name": "Dmitry Kulikov",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "10/29/90",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5328.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": ["FLA", "BUF", "WPG", "NJD", "EDM", "MIN", "PIT"]
    },
    {
      "name": "Josh Mahura",
      "pos": "D",
      "number": "28",
      "shoots": "L",
      "DOB": "05/05/98",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063504.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Niko Mikkola",
      "pos": "D",
      "number": "77",
      "shoots": "L",
      "DOB": "04/27/96",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942354.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": ["NYR"]
    },
    {
      "name": "Brandon Montour",
      "pos": "D",
      "number": "62",
      "shoots": "R",
      "DOB": "04/11/94",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3115032.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["ANA"]
    },
    {
      "name": "Mike Reilly",
      "pos": "D",
      "number": "6",
      "shoots": "L",
      "DOB": "07/13/93",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024796.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["MIN", "MTL", "OTT", "BOS"]
    },
    {
      "name": "Sergei Bobrovsky",
      "pos": "G",
      "number": "72",
      "shoots": "L",
      "DOB": "09/20/88",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5571.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": ["PHI", "CBJ"]
    },
    {
      "name": "Anthony Stolarz",
      "pos": "G",
      "number": "41",
      "shoots": "L",
      "DOB": "01/20/94",
      "team": "FLA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067313.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["ANA"]
    },
    {
      "name": "Quinton Byfield",
      "pos": "C",
      "number": "55",
      "shoots": "L",
      "DOB": "08/19/02",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697383.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Phillip Danault",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "02/24/93",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562602.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["MTL"]
    },
    {
      "name": "Adrian Kempe",
      "pos": "C",
      "number": "9",
      "shoots": "L",
      "DOB": "09/13/96",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114802.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Anze Kopitar",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "08/24/87",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3183.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SVN",
      "former": []
    },
    {
      "name": "Trevor Lewis",
      "pos": "C",
      "number": "61",
      "shoots": "R",
      "DOB": "01/08/87",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3454.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["WPG", "CGY"]
    },
    {
      "name": "Blake Lizotte",
      "pos": "C",
      "number": "46",
      "shoots": "L",
      "DOB": "12/13/97",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4316983.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Trevor Moore",
      "pos": "C",
      "number": "12",
      "shoots": "L",
      "DOB": "03/31/95",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096186.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["TOR"]
    },
    {
      "name": "Viktor Arvidsson",
      "pos": "W",
      "number": "33",
      "shoots": "R",
      "DOB": "04/08/93",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3120307.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["NSH"]
    },
    {
      "name": "Pierre-Luc Dubois",
      "pos": "W",
      "number": "80",
      "shoots": "L",
      "DOB": "06/24/98",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024833.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CBJ", "WPG"]
    },
    {
      "name": "Kevin Fiala",
      "pos": "W",
      "number": "22",
      "shoots": "L",
      "DOB": "07/22/96",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114743.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CHE",
      "former": ["NSH", "MIN"]
    },
    {
      "name": "Carl Grundstrom",
      "pos": "W",
      "number": "91",
      "shoots": "L",
      "DOB": "12/01/97",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4271565.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Alex Laferriere",
      "pos": "W",
      "number": "78",
      "shoots": "R",
      "DOB": "10/28/01",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697725.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Mikey Anderson",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "05/25/99",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588178.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Tobias Bjornfot",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "04/06/01",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565243.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Drew Doughty",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "12/08/89",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3995.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Andreas Englund",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "01/21/96",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114997.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["CHI"]
    },
    {
      "name": "Vladislav Gavrikov",
      "pos": "D",
      "number": "84",
      "shoots": "L",
      "DOB": "11/21/95",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942292.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Matt Roy",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "09/01/95",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942924.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Pheonix Copley",
      "pos": "G",
      "number": "29",
      "shoots": "L",
      "DOB": "01/18/92",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3095330.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "David Rittich",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "08/19/92",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063288.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": ["CGY", "TOR", "NSH", "WPG"]
    },
    {
      "name": "Cam Talbot",
      "pos": "G",
      "number": "39",
      "shoots": "L",
      "DOB": "07/05/87",
      "team": "LAK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5734.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYR", "EDM", "PHI", "CGY", "MIN", "OTT"]
    },
    {
      "name": "Connor Dewar",
      "pos": "C",
      "number": "26",
      "shoots": "L",
      "DOB": "06/26/99",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588004.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Joel Eriksson Ek",
      "pos": "C",
      "number": "14",
      "shoots": "L",
      "DOB": "01/29/97",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904091.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Frederick Gaudreau",
      "pos": "C",
      "number": "89",
      "shoots": "R",
      "DOB": "05/01/93",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149649.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NSH", "PIT"]
    },
    {
      "name": "Marco Rossi",
      "pos": "C",
      "number": "23",
      "shoots": "L",
      "DOB": "09/23/01",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697391.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "AUT",
      "former": []
    },
    {
      "name": "Matt Boldy",
      "pos": "W",
      "number": "12",
      "shoots": "L",
      "DOB": "04/05/01",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565233.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Marcus Foligno",
      "pos": "W",
      "number": "17",
      "shoots": "L",
      "DOB": "08/10/91",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5172.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["BUF"]
    },
    {
      "name": "Marcus Johansson",
      "pos": "W",
      "number": "90",
      "shoots": "L",
      "DOB": "10/06/90",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5714.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["NJD", "BOS", "BUF", "SEA", "MIN"]
    },
    {
      "name": "Kirill Kaprizov",
      "pos": "W",
      "number": "97",
      "shoots": "L",
      "DOB": "04/26/97",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942335.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Pat Maroon",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "04/23/88",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3853.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["ANA", "STL", "TBL"]
    },
    {
      "name": "Brandon Duhaime",
      "pos": "W",
      "number": "21",
      "shoots": "L",
      "DOB": "05/22/97",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4197009.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ryan Hartman",
      "pos": "W",
      "number": "38",
      "shoots": "R",
      "DOB": "09/20/94",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042063.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["CHI", "NSH", "PHI"]
    },
    {
      "name": "Mats Zuccarello",
      "pos": "W",
      "number": "36",
      "shoots": "L",
      "DOB": "09/01/87",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5560.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "NOR",
      "former": ["NYR", "DAL"]
    },
    {
      "name": "Calen Addison",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "04/11/00",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378684.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jonas Brodin",
      "pos": "D",
      "number": "25",
      "shoots": "L",
      "DOB": "07/12/93",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562600.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Brock Faber",
      "pos": "D",
      "number": "7",
      "shoots": "R",
      "DOB": "08/22/02",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697449.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alex Goligoski",
      "pos": "D",
      "number": "33",
      "shoots": "L",
      "DOB": "07/30/85",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3757.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["PIT", "DAL", "ARI"]
    },
    {
      "name": "Jon Merrill",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "02/03/92",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3059403.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NJD", "VGK", "DET", "MTL"]
    },
    {
      "name": "Jake Middleton",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "01/02/96",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149839.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jared Spurgeon",
      "pos": "D",
      "number": "46",
      "shoots": "R",
      "DOB": "11/29/89",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5079.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Marc-Andre Fleury",
      "pos": "G",
      "number": "29",
      "shoots": "L",
      "DOB": "11/28/84",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2346.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["PIT", "VGK", "CHI"]
    },
    {
      "name": "Filip Gustavsson",
      "pos": "G",
      "number": "32",
      "shoots": "L",
      "DOB": "06/07/98",
      "team": "MIN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4272674.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["OTT"]
    },
    {
      "name": "Kirby Dach",
      "pos": "C",
      "number": "77",
      "shoots": "R",
      "DOB": "01/21/01",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565224.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CHI"]
    },
    {
      "name": "Christian Dvorak",
      "pos": "C",
      "number": "28",
      "shoots": "L",
      "DOB": "02/02/96",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3115035.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["ARI"]
    },
    {
      "name": "Jake Evans",
      "pos": "C",
      "number": "71",
      "shoots": "R",
      "DOB": "06/02/96",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4393049.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Sean Monahan",
      "pos": "C",
      "number": "91",
      "shoots": "L",
      "DOB": "10/12/94",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041996.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CGY"]
    },
    {
      "name": "Alex Newhook",
      "pos": "C",
      "number": "15",
      "shoots": "L",
      "DOB": "01/28/01",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565237.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["COL"]
    },
    {
      "name": "Nick Suzuki",
      "pos": "C",
      "number": "14",
      "shoots": "R",
      "DOB": "08/10/99",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233594.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Rafael Harvey-Pinard",
      "pos": "W",
      "number": "49",
      "shoots": "L",
      "DOB": "01/06/99",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4391250.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tanner Pearson",
      "pos": "W",
      "number": "70",
      "shoots": "L",
      "DOB": "08/10/92",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976860.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["LAK", "VAN"]
    },
    {
      "name": "Michael Pezzetta",
      "pos": "W",
      "number": "55",
      "shoots": "L",
      "DOB": "03/13/98",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392215.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Juraj Slafkovsky",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "03/30/04",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4915349.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SVK",
      "former": []
    },
    {
      "name": "Josh Anderson",
      "pos": "W",
      "number": "17",
      "shoots": "R",
      "DOB": "05/07/94",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069687.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CBJ"]
    },
    {
      "name": "Cole Caufield",
      "pos": "W",
      "number": "22",
      "shoots": "R",
      "DOB": "01/02/01",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565236.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Brendan Gallagher",
      "pos": "W",
      "number": "11",
      "shoots": "R",
      "DOB": "05/06/92",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5614.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jesse Ylonen",
      "pos": "W",
      "number": "56",
      "shoots": "R",
      "DOB": "10/03/99",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378663.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Justin Barron",
      "pos": "D",
      "number": "52",
      "shoots": "R",
      "DOB": "11/15/01",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697408.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Kaiden Guhle",
      "pos": "D",
      "number": "21",
      "shoots": "L",
      "DOB": "01/18/02",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697399.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jordan Harris",
      "pos": "D",
      "number": "54",
      "shoots": "L",
      "DOB": "07/07/00",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4419724.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Johnathan Kovacevic",
      "pos": "D",
      "number": "26",
      "shoots": "R",
      "DOB": "07/12/97",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587933.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mike Matheson",
      "pos": "D",
      "number": "8",
      "shoots": "L",
      "DOB": "02/27/94",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976851.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["FLA", "PIT"]
    },
    {
      "name": "David Savard",
      "pos": "D",
      "number": "58",
      "shoots": "R",
      "DOB": "10/22/90",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5427.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CBJ", "TBL"]
    },
    {
      "name": "Chris Wideman",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "01/07/90",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2334912.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["OTT", "EDM", "FLA"]
    },
    {
      "name": "Arber Xhekaj",
      "pos": "D",
      "number": "72",
      "shoots": "L",
      "DOB": "01/30/01",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4893969.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jake Allen",
      "pos": "G",
      "number": "34",
      "shoots": "L",
      "DOB": "08/07/90",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5111.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["STL"]
    },
    {
      "name": "Sam Montembeault",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "10/30/96",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942714.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["FLA"]
    },
    {
      "name": "Carey Price",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "08/16/87",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3231.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Cayden Primeau",
      "pos": "G",
      "number": "30",
      "shoots": "L",
      "DOB": "08/11/99",
      "team": "MTL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587855.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Cody Glass",
      "pos": "C",
      "number": "8",
      "shoots": "R",
      "DOB": "04/01/99",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233568.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tommy Novak",
      "pos": "C",
      "number": "82",
      "shoots": "L",
      "DOB": "04/28/97",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942061.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ryan O'Reilly",
      "pos": "C",
      "number": "90",
      "shoots": "L",
      "DOB": "02/07/91",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5208.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["COL", "BUF", "STL", "TOR"]
    },
    {
      "name": "Juuso Parssinen",
      "pos": "C",
      "number": "75",
      "shoots": "L",
      "DOB": "02/01/01",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4867883.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Colton Sissons",
      "pos": "C",
      "number": "10",
      "shoots": "R",
      "DOB": "11/05/93",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2993474.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Philip Tomasino",
      "pos": "C",
      "number": "26",
      "shoots": "R",
      "DOB": "07/28/01",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565245.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Yakov Trenin",
      "pos": "C",
      "number": "13",
      "shoots": "L",
      "DOB": "01/13/97",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904188.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Samuel Fagemo",
      "pos": "W",
      "number": "11",
      "shoots": "R",
      "DOB": "03/14/00",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565273.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Filip Forsberg",
      "pos": "W",
      "number": "9",
      "shoots": "R",
      "DOB": "08/13/94",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2968772.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Gustav Nyquist",
      "pos": "W",
      "number": "14",
      "shoots": "L",
      "DOB": "09/01/89",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5823.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["DET", "SJS", "CBJ", "MIN"]
    },
    {
      "name": "Kiefer Sherwood",
      "pos": "W",
      "number": "44",
      "shoots": "R",
      "DOB": "03/31/95",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4391255.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Cole Smith",
      "pos": "W",
      "number": "36",
      "shoots": "L",
      "DOB": "10/28/95",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4197130.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Michael McCarron",
      "pos": "W",
      "number": "47",
      "shoots": "R",
      "DOB": "03/07/95",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042048.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["MTL"]
    },
    {
      "name": "Tyson Barrie",
      "pos": "D",
      "number": "22",
      "shoots": "R",
      "DOB": "07/26/91",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5181.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["COL", "TOR"]
    },
    {
      "name": "Alexandre Carrier",
      "pos": "D",
      "number": "45",
      "shoots": "R",
      "DOB": "10/08/96",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942064.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Dante Fabbro",
      "pos": "D",
      "number": "57",
      "shoots": "R",
      "DOB": "06/20/98",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024959.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Roman Josi",
      "pos": "D",
      "number": "59",
      "shoots": "L",
      "DOB": "06/01/90",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5436.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Jeremy Lauzon",
      "pos": "D",
      "number": "3",
      "shoots": "L",
      "DOB": "04/28/97",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904185.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["BOS", "SEA"]
    },
    {
      "name": "Ryan McDonagh",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "06/13/89",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4954.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NYR", "TBL"]
    },
    {
      "name": "Luke Schenn",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "11/02/89",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5092.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["TOR", "PHI", "LAK", "ARI", "ANA", "TBL", "TOR"]
    },
    {
      "name": "Kevin Lankinen",
      "pos": "G",
      "number": "32",
      "shoots": "L",
      "DOB": "04/28/95",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4341584.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["CHI"]
    },
    {
      "name": "Juuse Saros",
      "pos": "G",
      "number": "74",
      "shoots": "L",
      "DOB": "04/19/95",
      "team": "NSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042109.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Nico Hischier",
      "pos": "C",
      "number": "13",
      "shoots": "L",
      "DOB": "01/04/99",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233555.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Jack Hughes",
      "pos": "C",
      "number": "86",
      "shoots": "L",
      "DOB": "05/14/01",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565222.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Curtis Lazar",
      "pos": "C",
      "number": "42",
      "shoots": "R",
      "DOB": "02/02/95",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042023.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["OTT", "CGY", "BUF"]
    },
    {
      "name": "Michael McLeod",
      "pos": "C",
      "number": "20",
      "shoots": "R",
      "DOB": "02/03/98",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024902.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Dawson Mercer",
      "pos": "C",
      "number": "91",
      "shoots": "R",
      "DOB": "10/27/01",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697401.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Chris Tierney",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "07/01/94",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067870.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["MTL"]
    },
    {
      "name": "Jesper Bratt",
      "pos": "W",
      "number": "63",
      "shoots": "L",
      "DOB": "07/30/98",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4268771.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Nolan Foote",
      "pos": "W",
      "number": "25",
      "shoots": "L",
      "DOB": "11/29/00",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565248.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Erik Haula",
      "pos": "W",
      "number": "56",
      "shoots": "L",
      "DOB": "03/23/91",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2593311.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["MIN", "NSH", "CAR", "VGK", "FLA", "BOS"]
    },
    {
      "name": "Tomas Nosek",
      "pos": "W",
      "number": "92",
      "shoots": "L",
      "DOB": "09/01/92",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3112266.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": ["DET", "VGK", "BOS"]
    },
    {
      "name": "Ondrej Palat",
      "pos": "W",
      "number": "18",
      "shoots": "L",
      "DOB": "03/28/91",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590389.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": ["TBL"]
    },
    {
      "name": "Nathan Bastian",
      "pos": "W",
      "number": "14",
      "shoots": "R",
      "DOB": "12/06/97",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063279.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["SEA"]
    },
    {
      "name": "Alexander Holtz",
      "pos": "W",
      "number": "10",
      "shoots": "R",
      "DOB": "01/23/02",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697388.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Timo Meier",
      "pos": "W",
      "number": "28",
      "shoots": "L",
      "DOB": "10/08/96",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899978.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Tyler Toffoli",
      "pos": "W",
      "number": "73",
      "shoots": "R",
      "DOB": "04/24/92",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5550.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["LAK", "VAN", "MTL", "CGY"]
    },
    {
      "name": "Kevin Bahl",
      "pos": "D",
      "number": "88",
      "shoots": "L",
      "DOB": "06/27/00",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378686.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Dougie Hamilton",
      "pos": "D",
      "number": "7",
      "shoots": "R",
      "DOB": "06/17/93",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562605.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["BOS", "CAR"]
    },
    {
      "name": "Luke Hughes",
      "pos": "D",
      "number": "43",
      "shoots": "L",
      "DOB": "09/09/03",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874719.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "John Marino",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "05/21/97",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3941974.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["PIT"]
    },
    {
      "name": "Colin Miller",
      "pos": "D",
      "number": "24",
      "shoots": "R",
      "DOB": "10/29/92",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590796.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["BOS", "VGK", "BUF", "DAL"]
    },
    {
      "name": "Jonas Siegenthaler",
      "pos": "D",
      "number": "71",
      "shoots": "L",
      "DOB": "05/06/97",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904190.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Brendan Smith",
      "pos": "D",
      "number": "2",
      "shoots": "L",
      "DOB": "02/08/89",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4973.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["DET", "NYR", "CAR"]
    },
    {
      "name": "Nico Daws",
      "pos": "G",
      "number": "50",
      "shoots": "L",
      "DOB": "12/22/00",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4894673.png",
      "conference": "East",
      "division": "Met",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Akira Schmid",
      "pos": "G",
      "number": "40",
      "shoots": "L",
      "DOB": "05/12/00",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587845.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CHE",
      "former": []
    },
    {
      "name": "Vitek Vanecek",
      "pos": "G",
      "number": "41",
      "shoots": "L",
      "DOB": "01/09/96",
      "team": "NJD",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114996.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": ["WSH"]
    },
    {
      "name": "Mathew Barzal",
      "pos": "C",
      "number": "13",
      "shoots": "R",
      "DOB": "05/26/97",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899946.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Casey Cizikas",
      "pos": "C",
      "number": "53",
      "shoots": "L",
      "DOB": "02/27/91",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5262.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Bo Horvat",
      "pos": "C",
      "number": "14",
      "shoots": "L",
      "DOB": "04/05/95",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042002.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["VAN"]
    },
    {
      "name": "Brock Nelson",
      "pos": "C",
      "number": "29",
      "shoots": "L",
      "DOB": "10/15/91",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5798.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Jean-Gabriel Pageau",
      "pos": "C",
      "number": "44",
      "shoots": "R",
      "DOB": "11/11/92",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2593131.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["OTT"]
    },
    {
      "name": "Kyle Palmieri",
      "pos": "C",
      "number": "21",
      "shoots": "R",
      "DOB": "02/01/91",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5517.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["ANA", "NJD"]
    },
    {
      "name": "Pierre Engvall",
      "pos": "W",
      "number": "18",
      "shoots": "L",
      "DOB": "05/31/96",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151763.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["TOR"]
    },
    {
      "name": "Anders Lee",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "07/03/90",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5793.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Matt Martin",
      "pos": "W",
      "number": "17",
      "shoots": "L",
      "DOB": "05/08/89",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5050.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["TOR"]
    },
    {
      "name": "Cal Clutterbuck",
      "pos": "W",
      "number": "15",
      "shoots": "R",
      "DOB": "11/18/87",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3689.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["MIN"]
    },
    {
      "name": "Hudson Fasching",
      "pos": "W",
      "number": "20",
      "shoots": "R",
      "DOB": "07/28/95",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042134.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Julien Gauthier",
      "pos": "W",
      "number": "16",
      "shoots": "R",
      "DOB": "10/15/97",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024975.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["OTT", "USA"]
    },
    {
      "name": "Simon Holmstrom",
      "pos": "W",
      "number": "10",
      "shoots": "L",
      "DOB": "05/24/01",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565244.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Oliver Wahlstrom",
      "pos": "W",
      "number": "26",
      "shoots": "R",
      "DOB": "06/13/00",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352727.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Sebastian Aho",
      "pos": "D",
      "number": "25",
      "shoots": "L",
      "DOB": "02/17/96",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4272688.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Samuel Bolduc",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "12/09/00",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565279.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Noah Dobson",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "01/07/00",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352732.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Scott Mayfield",
      "pos": "D",
      "number": "24",
      "shoots": "R",
      "DOB": "10/14/92",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563069.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Adam Pelech",
      "pos": "D",
      "number": "3",
      "shoots": "L",
      "DOB": "08/16/94",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069621.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Pulock",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "10/06/94",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042019.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alexander Romanov",
      "pos": "D",
      "number": "28",
      "shoots": "L",
      "DOB": "01/06/00",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587854.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": ["MTL"]
    },
    {
      "name": "Ilya Sorokin",
      "pos": "G",
      "number": "30",
      "shoots": "L",
      "DOB": "08/04/95",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151981.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Semyon Varlamov",
      "pos": "G",
      "number": "40",
      "shoots": "L",
      "DOB": "04/27/88",
      "team": "NYI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3759.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": ["COL", "WSH"]
    },
    {
      "name": "Nick Bonino",
      "pos": "C",
      "number": "12",
      "shoots": "L",
      "DOB": "04/20/88",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4927.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["ANA", "VAN", "MIN", "PIT", "NSH"]
    },
    {
      "name": "Filip Chytil",
      "pos": "C",
      "number": "72",
      "shoots": "L",
      "DOB": "09/05/99",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233643.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Barclay Goodrow",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "02/26/93",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069411.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["SJS", "TBL"]
    },
    {
      "name": "Tyler Pitlick",
      "pos": "C",
      "number": "71",
      "shoots": "R",
      "DOB": "11/01/91",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5526.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["STL"]
    },
    {
      "name": "Vincent Trocheck",
      "pos": "C",
      "number": "16",
      "shoots": "R",
      "DOB": "07/11/93",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563036.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["FLA", "CGY"]
    },
    {
      "name": "Mika Zibanejad",
      "pos": "C",
      "number": "93",
      "shoots": "R",
      "DOB": "04/18/93",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562637.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["OTT"]
    },
    {
      "name": "Will Cuylle",
      "pos": "W",
      "number": "50",
      "shoots": "L",
      "DOB": "02/05/02",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697468.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Chris Kreider",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "04/30/91",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5833.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alexis Lafreniere",
      "pos": "W",
      "number": "13",
      "shoots": "L",
      "DOB": "10/11/01",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697382.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Artemi Panarin",
      "pos": "W",
      "number": "10",
      "shoots": "R",
      "DOB": "10/30/91",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3891952.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": ["CHI", "CBJ"]
    },
    {
      "name": "Jimmy Vesey",
      "pos": "W",
      "number": "26",
      "shoots": "L",
      "DOB": "05/26/93",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069397.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["BUF", "TOR", "VAN", "NJD"]
    },
    {
      "name": "Kaapo Kakko",
      "pos": "W",
      "number": "24",
      "shoots": "L",
      "DOB": "02/13/01",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565223.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Blake Wheeler",
      "pos": "W",
      "number": "17",
      "shoots": "R",
      "DOB": "08/31/86",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3961.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["ARI", "BOS", "WPG"]
    },
    {
      "name": "Adam Fox",
      "pos": "D",
      "number": "23",
      "shoots": "R",
      "DOB": "02/17/98",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4197146.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Erik Gustafsson",
      "pos": "D",
      "number": "56",
      "shoots": "L",
      "DOB": "03/14/92",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3891922.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["PHI", "MTL", "CHI", "WSH", "TOR"]
    },
    {
      "name": "Zac Jones",
      "pos": "D",
      "number": "6",
      "shoots": "L",
      "DOB": "10/18/00",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4781635.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ryan Lindgren",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "02/11/98",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4271998.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "K'Andre Miller",
      "pos": "D",
      "number": "79",
      "shoots": "L",
      "DOB": "01/21/00",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352770.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Braden Schneider",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "09/20/01",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697402.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jacob Trouba",
      "pos": "D",
      "number": "8",
      "shoots": "R",
      "DOB": "02/26/94",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976839.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["WPG"]
    },
    {
      "name": "Jonathan Quick",
      "pos": "G",
      "number": "32",
      "shoots": "L",
      "DOB": "01/21/86",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3634.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["VGK"]
    },
    {
      "name": "Igor Shesterkin",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "12/30/95",
      "team": "NYR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151297.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Rourke Chartier",
      "pos": "C",
      "number": "49",
      "shoots": "L",
      "DOB": "04/03/96",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151791.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ridly Greig",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "08/08/02",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697411.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mark Kastelic",
      "pos": "C",
      "number": "12",
      "shoots": "R",
      "DOB": "03/11/99",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587985.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Zack MacEwen",
      "pos": "C",
      "number": "17",
      "shoots": "R",
      "DOB": "07/08/96",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063569.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["LAK"]
    },
    {
      "name": "Josh Norris",
      "pos": "C",
      "number": "9",
      "shoots": "L",
      "DOB": "05/05/99",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233627.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Parker Kelly",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "05/14/99",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392309.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Dominik Kubalik",
      "pos": "W",
      "number": "81",
      "shoots": "L",
      "DOB": "08/21/95",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042245.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CZE",
      "former": ["CHI", "DET"]
    },
    {
      "name": "Tim Stutzle",
      "pos": "W",
      "number": "18",
      "shoots": "L",
      "DOB": "01/15/02",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697384.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Brady Tkachuk",
      "pos": "W",
      "number": "7",
      "shoots": "L",
      "DOB": "09/16/99",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4319858.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Drake Batherson",
      "pos": "W",
      "number": "19",
      "shoots": "R",
      "DOB": "04/27/98",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4271734.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Claude Giroux",
      "pos": "W",
      "number": "28",
      "shoots": "R",
      "DOB": "01/12/88",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3775.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["PHI", "FLA"]
    },
    {
      "name": "Mathieu Joseph",
      "pos": "W",
      "number": "21",
      "shoots": "R",
      "DOB": "02/09/97",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3941965.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["TBL"]
    },
    {
      "name": "Vladimir Tarasenko",
      "pos": "W",
      "number": "91",
      "shoots": "L",
      "DOB": "12/13/91",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5837.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": ["NYR"]
    },
    {
      "name": "Erik Brannstrom",
      "pos": "D",
      "number": "26",
      "shoots": "L",
      "DOB": "09/02/99",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233606.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Thomas Chabot",
      "pos": "D",
      "number": "72",
      "shoots": "L",
      "DOB": "01/30/97",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900219.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jakob Chychrun",
      "pos": "D",
      "number": "6",
      "shoots": "L",
      "DOB": "03/31/98",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024950.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Travis Hamonic",
      "pos": "D",
      "number": "23",
      "shoots": "R",
      "DOB": "08/16/90",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5073.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["NYI", "CGY", "VAN"]
    },
    {
      "name": "Jake Sanderson",
      "pos": "D",
      "number": "85",
      "shoots": "L",
      "DOB": "07/08/02",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697386.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Artem Zub",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "10/03/95",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4712021.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Anton Forsberg",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "11/27/92",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3036851.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["CBJ", "CHI", "CAR"]
    },
    {
      "name": "Joonas Korpisalo",
      "pos": "G",
      "number": "70",
      "shoots": "L",
      "DOB": "04/28/94",
      "team": "OTT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069266.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Sean Couturier",
      "pos": "C",
      "number": "14",
      "shoots": "L",
      "DOB": "12/07/92",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562601.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Morgan Frost",
      "pos": "C",
      "number": "48",
      "shoots": "L",
      "DOB": "05/14/99",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233685.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Scott Laughton",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "05/30/94",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976848.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Poehling",
      "pos": "C",
      "number": "25",
      "shoots": "L",
      "DOB": "01/03/99",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233668.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["MTL", "PIT"]
    },
    {
      "name": "Noah Cates",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "02/05/99",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4419682.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nicolas Deslauriers",
      "pos": "W",
      "number": "44",
      "shoots": "L",
      "DOB": "02/22/91",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5193.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["BUF", "MTL", "ANA", "MIN"]
    },
    {
      "name": "Joel Farabee",
      "pos": "W",
      "number": "86",
      "shoots": "L",
      "DOB": "02/25/00",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352750.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Cam Atkinson",
      "pos": "W",
      "number": "89",
      "shoots": "R",
      "DOB": "06/05/89",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2501107.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["CBJ"]
    },
    {
      "name": "Bobby Brink",
      "pos": "W",
      "number": "10",
      "shoots": "R",
      "DOB": "07/08/01",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565257.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Tyson Foerster",
      "pos": "W",
      "number": "71",
      "shoots": "R",
      "DOB": "01/18/02",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697406.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Garnet Hathaway",
      "pos": "W",
      "number": "19",
      "shoots": "R",
      "DOB": "11/23/91",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149633.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["CGY", "WSH", "BOS"]
    },
    {
      "name": "Travis Konecny",
      "pos": "W",
      "number": "11",
      "shoots": "R",
      "DOB": "03/11/97",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900169.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Owen Tippett",
      "pos": "W",
      "number": "74",
      "shoots": "R",
      "DOB": "02/16/99",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392072.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["FLA"]
    },
    {
      "name": "Ryan Ellis",
      "pos": "D",
      "number": "94",
      "shoots": "R",
      "DOB": "01/03/91",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5169.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Rasmus Ristolainen",
      "pos": "D",
      "number": "55",
      "shoots": "R",
      "DOB": "10/27/94",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3041999.png",
      "conference": "East",
      "division": "Met",
      "ctry": "FIN",
      "former": ["BUF"]
    },
    {
      "name": "Travis Sanheim",
      "pos": "D",
      "number": "6",
      "shoots": "L",
      "DOB": "03/29/96",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114757.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Nick Seeler",
      "pos": "D",
      "number": "24",
      "shoots": "L",
      "DOB": "06/03/93",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2564164.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Marc Staal",
      "pos": "D",
      "number": "18",
      "shoots": "L",
      "DOB": "01/13/87",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3250.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["NYR", "DET", "FLA"]
    },
    {
      "name": "Sean Walker",
      "pos": "D",
      "number": "26",
      "shoots": "R",
      "DOB": "11/13/94",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4272905.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["LAK"]
    },
    {
      "name": "Cam York",
      "pos": "D",
      "number": "8",
      "shoots": "L",
      "DOB": "01/05/01",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565235.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Egor Zamula",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "03/30/00",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392268.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Carter Hart",
      "pos": "G",
      "number": "79",
      "shoots": "L",
      "DOB": "08/13/98",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064582.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Felix Sandstrom",
      "pos": "G",
      "number": "32",
      "shoots": "L",
      "DOB": "01/12/97",
      "team": "PHI",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587820.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Noel Acciari",
      "pos": "C",
      "number": "55",
      "shoots": "R",
      "DOB": "12/01/91",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096237.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["BOS", "FLA", "TOR"]
    },
    {
      "name": "Jeff Carter",
      "pos": "C",
      "number": "77",
      "shoots": "R",
      "DOB": "01/01/85",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2383.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["PHI", "CBJ", "LAK"]
    },
    {
      "name": "Sidney Crosby",
      "pos": "C",
      "number": "87",
      "shoots": "L",
      "DOB": "08/07/87",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Lars Eller",
      "pos": "C",
      "number": "20",
      "shoots": "L",
      "DOB": "05/08/89",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3946.png",
      "conference": "East",
      "division": "Met",
      "ctry": "DNK",
      "former": ["MTL", "COL"]
    },
    {
      "name": "Jansen Harkins",
      "pos": "C",
      "number": "43",
      "shoots": "L",
      "DOB": "05/23/97",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900154.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Evgeni Malkin",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "07/31/86",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3124.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Jake Guentzel",
      "pos": "W",
      "number": "59",
      "shoots": "L",
      "DOB": "10/06/94",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042083.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Matt Nieto",
      "pos": "W",
      "number": "83",
      "shoots": "L",
      "DOB": "11/05/92",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563076.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["COL", "COL"]
    },
    {
      "name": "Drew O'Connor",
      "pos": "W",
      "number": "10",
      "shoots": "L",
      "DOB": "06/09/98",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4712146.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Rickard Rakell",
      "pos": "W",
      "number": "67",
      "shoots": "R",
      "DOB": "05/05/93",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562629.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["ANA"]
    },
    {
      "name": "Bryan Rust",
      "pos": "W",
      "number": "17",
      "shoots": "R",
      "DOB": "05/11/92",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2591155.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Reilly Smith",
      "pos": "W",
      "number": "19",
      "shoots": "L",
      "DOB": "04/01/91",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2500990.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["FLA", "VGK"]
    },
    {
      "name": "Ryan Graves",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "05/21/95",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042122.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["COL", "NJD"]
    },
    {
      "name": "Pierre-Olivier Joseph",
      "pos": "D",
      "number": "73",
      "shoots": "L",
      "DOB": "07/01/99",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233653.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Erik Karlsson",
      "pos": "D",
      "number": "65",
      "shoots": "R",
      "DOB": "05/31/90",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5164.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["OTT", "SJS"]
    },
    {
      "name": "Kris Letang",
      "pos": "D",
      "number": "58",
      "shoots": "R",
      "DOB": "04/24/87",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3539.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "John Ludvig",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "08/02/00",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4846923.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Marcus Pettersson",
      "pos": "D",
      "number": "28",
      "shoots": "L",
      "DOB": "05/08/96",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114995.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["ANA"]
    },
    {
      "name": "Chad Ruhwedel",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "05/07/90",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024837.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["BUF"]
    },
    {
      "name": "Ryan Shea",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "02/11/97",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942033.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Tristan Jarry",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "04/29/95",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042020.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alex Nedeljkovic",
      "pos": "G",
      "number": "39",
      "shoots": "L",
      "DOB": "01/07/96",
      "team": "PIT",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114994.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["DET"]
    },
    {
      "name": "Thomas Bordeleau",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "01/03/02",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697443.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Logan Couture",
      "pos": "C",
      "number": "39",
      "shoots": "L",
      "DOB": "03/28/89",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3773.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "William Eklund",
      "pos": "C",
      "number": "72",
      "shoots": "L",
      "DOB": "10/12/02",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874721.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Mikael Granlund",
      "pos": "C",
      "number": "64",
      "shoots": "L",
      "DOB": "02/26/92",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5831.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "FIN",
      "former": ["MIN", "NSH", "PIT"]
    },
    {
      "name": "Tomas Hertl",
      "pos": "C",
      "number": "48",
      "shoots": "L",
      "DOB": "11/12/93",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976844.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Luke Kunin",
      "pos": "C",
      "number": "11",
      "shoots": "R",
      "DOB": "12/04/97",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024933.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["MIN"]
    },
    {
      "name": "Jacob Peterson",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "07/19/99",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4894378.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Nico Sturm",
      "pos": "C",
      "number": "7",
      "shoots": "L",
      "DOB": "05/03/95",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4320625.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "DEU",
      "former": ["MIN"]
    },
    {
      "name": "Alexander Barabanov",
      "pos": "W",
      "number": "94",
      "shoots": "L",
      "DOB": "06/17/97",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4294800.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": ["TOR"]
    },
    {
      "name": "Anthony Duclair",
      "pos": "W",
      "number": "10",
      "shoots": "L",
      "DOB": "08/26/95",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042086.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYR", "ARI", "CHI", "OTT", "CBJ", "FLA"]
    },
    {
      "name": "Mike Hoffman",
      "pos": "W",
      "number": "68",
      "shoots": "L",
      "DOB": "11/24/89",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5103.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["OTT", "FLA", "STL", "MTL"]
    },
    {
      "name": "Fabian Zetterlund",
      "pos": "W",
      "number": "20",
      "shoots": "R",
      "DOB": "08/25/99",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587837.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Kevin Labanc",
      "pos": "W",
      "number": "62",
      "shoots": "R",
      "DOB": "12/12/95",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151792.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Givani Smith",
      "pos": "W",
      "number": "54",
      "shoots": "L",
      "DOB": "02/27/98",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063602.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["FLA"]
    },
    {
      "name": "Filip Zadina",
      "pos": "W",
      "number": "18",
      "shoots": "L",
      "DOB": "11/27/99",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352700.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": ["DET"]
    },
    {
      "name": "Matt Benning",
      "pos": "D",
      "number": "5",
      "shoots": "R",
      "DOB": "05/25/94",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069449.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["EDM", "NSH"]
    },
    {
      "name": "Kyle Burroughs",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "07/12/95",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042255.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["COL", "VAN"]
    },
    {
      "name": "Ty Emberson",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "05/23/00",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587566.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Mario Ferraro",
      "pos": "D",
      "number": "38",
      "shoots": "L",
      "DOB": "09/17/98",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233884.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Nikolai Knyzhov",
      "pos": "D",
      "number": "71",
      "shoots": "L",
      "DOB": "03/20/98",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565857.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Jacob MacDonald",
      "pos": "D",
      "number": "9",
      "shoots": "L",
      "DOB": "02/26/93",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4268768.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nikita Okhotiuk",
      "pos": "D",
      "number": "83",
      "shoots": "L",
      "DOB": "12/04/00",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565283.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Jan Rutta",
      "pos": "D",
      "number": "44",
      "shoots": "R",
      "DOB": "07/29/90",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4230583.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": ["CHI", "TBL", "PIT"]
    },
    {
      "name": "Radim Simek",
      "pos": "D",
      "number": "51",
      "shoots": "L",
      "DOB": "09/20/92",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4226783.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Henry Thrun",
      "pos": "D",
      "number": "3",
      "shoots": "L",
      "DOB": "03/12/01",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4997138.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Marc-Edouard Vlasic",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "03/30/87",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3371.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mackenzie Blackwood",
      "pos": "G",
      "number": "29",
      "shoots": "L",
      "DOB": "12/09/96",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904177.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NJD"]
    },
    {
      "name": "Kaapo Kahkonen",
      "pos": "G",
      "number": "36",
      "shoots": "L",
      "DOB": "08/16/96",
      "team": "SJS",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151341.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "FIN",
      "former": ["MIN"]
    },
    {
      "name": "Matty Beniers",
      "pos": "C",
      "number": "10",
      "shoots": "L",
      "DOB": "11/05/02",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4781552.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Yanni Gourde",
      "pos": "C",
      "number": "37",
      "shoots": "L",
      "DOB": "12/15/91",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3094261.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["TBL"]
    },
    {
      "name": "Jaden Schwartz",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "06/25/92",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5835.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["STL"]
    },
    {
      "name": "Alex Wennberg",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "09/22/94",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042017.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["CBJ", "FLA"]
    },
    {
      "name": "Andre Burakovsky",
      "pos": "W",
      "number": "95",
      "shoots": "L",
      "DOB": "02/09/95",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042044.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "AUT",
      "former": ["WSH", "COL"]
    },
    {
      "name": "Tye Kartye",
      "pos": "W",
      "number": "52",
      "shoots": "R",
      "DOB": "04/30/01",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588750.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jared McCann",
      "pos": "W",
      "number": "19",
      "shoots": "L",
      "DOB": "05/31/96",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114777.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["PIT"]
    },
    {
      "name": "Brandon Tanev",
      "pos": "W",
      "number": "13",
      "shoots": "L",
      "DOB": "12/31/91",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096236.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Oliver Bjorkstrand",
      "pos": "W",
      "number": "22",
      "shoots": "R",
      "DOB": "04/10/95",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042095.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "DNK",
      "former": ["CBJ"]
    },
    {
      "name": "Jordan Eberle",
      "pos": "W",
      "number": "7",
      "shoots": "R",
      "DOB": "05/15/90",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5032.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["EDM", "NYI"]
    },
    {
      "name": "Eeli Tolvanen",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "04/22/99",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233720.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Kailer Yamamoto",
      "pos": "W",
      "number": "56",
      "shoots": "R",
      "DOB": "09/29/98",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233648.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["EDM"]
    },
    {
      "name": "Will Borgen",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "12/19/96",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3941946.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["BUF"]
    },
    {
      "name": "Vince Dunn",
      "pos": "D",
      "number": "29",
      "shoots": "L",
      "DOB": "10/29/96",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904189.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["STL"]
    },
    {
      "name": "Adam Larsson",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "11/12/92",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562610.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["NJD", "EDM"]
    },
    {
      "name": "Jaycob Megna",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "12/10/92",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3020228.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Jamie Oleksiak",
      "pos": "D",
      "number": "24",
      "shoots": "L",
      "DOB": "12/21/92",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562625.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["DAL", "PIT"]
    },
    {
      "name": "Justin Schultz",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "07/06/90",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2501067.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["EDM", "PIT"]
    },
    {
      "name": "Joey Daccord",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "08/19/96",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942073.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Philipp Grubauer",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "11/25/91",
      "team": "SEA",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5657.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "DEU",
      "former": ["COL", "WSH"]
    },
    {
      "name": "Kevin Hayes",
      "pos": "C",
      "number": "12",
      "shoots": "L",
      "DOB": "05/08/92",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5767.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NYR", "WPG", "PHI"]
    },
    {
      "name": "Jordan Kyrou",
      "pos": "C",
      "number": "25",
      "shoots": "R",
      "DOB": "05/05/98",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4062251.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Brayden Schenn",
      "pos": "C",
      "number": "10",
      "shoots": "L",
      "DOB": "08/22/91",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5219.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["PHI"]
    },
    {
      "name": "Robert Thomas",
      "pos": "C",
      "number": "18",
      "shoots": "R",
      "DOB": "07/02/99",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233637.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Nikita Alexandrov",
      "pos": "W",
      "number": "59",
      "shoots": "L",
      "DOB": "09/16/00",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565284.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "DEU",
      "former": []
    },
    {
      "name": "Sammy Blais",
      "pos": "W",
      "number": "79",
      "shoots": "L",
      "DOB": "06/17/96",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149697.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NYR"]
    },
    {
      "name": "Pavel Buchnevich",
      "pos": "W",
      "number": "89",
      "shoots": "L",
      "DOB": "04/17/95",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042081.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["NYR"]
    },
    {
      "name": "Jake Neighbours",
      "pos": "W",
      "number": "63",
      "shoots": "L",
      "DOB": "03/29/02",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697409.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Brandon Saad",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "10/27/92",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563065.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["CHI", "CBJ", "COL"]
    },
    {
      "name": "Jakub Vrana",
      "pos": "W",
      "number": "15",
      "shoots": "L",
      "DOB": "02/28/96",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114747.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CZE",
      "former": ["WSH"]
    },
    {
      "name": "Kasperi Kapanen",
      "pos": "W",
      "number": "42",
      "shoots": "R",
      "DOB": "07/23/96",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114775.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["TOR", "PIT"]
    },
    {
      "name": "Oskar Sundqvist",
      "pos": "W",
      "number": "70",
      "shoots": "R",
      "DOB": "03/23/94",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069277.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": ["PIT", "STL", "MIN"]
    },
    {
      "name": "Alexey Toropchenko",
      "pos": "W",
      "number": "13",
      "shoots": "L",
      "DOB": "06/25/99",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392454.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Robert Bortuzzo",
      "pos": "D",
      "number": "41",
      "shoots": "R",
      "DOB": "03/18/89",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4916.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["PIT"]
    },
    {
      "name": "Justin Faulk",
      "pos": "D",
      "number": "72",
      "shoots": "R",
      "DOB": "03/20/92",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5746.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["CAR"]
    },
    {
      "name": "Torey Krug",
      "pos": "D",
      "number": "47",
      "shoots": "L",
      "DOB": "04/12/91",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2952729.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["BOS"]
    },
    {
      "name": "Nick Leddy",
      "pos": "D",
      "number": "4",
      "shoots": "L",
      "DOB": "03/20/91",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5447.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NYI", "DET", "CHI"]
    },
    {
      "name": "Colton Parayko",
      "pos": "D",
      "number": "55",
      "shoots": "R",
      "DOB": "05/12/93",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069341.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Scott Perunovich",
      "pos": "D",
      "number": "48",
      "shoots": "R",
      "DOB": "08/18/98",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4320665.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Marco Scandella",
      "pos": "D",
      "number": "6",
      "shoots": "L",
      "DOB": "02/23/90",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5218.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tyler Tucker",
      "pos": "D",
      "number": "75",
      "shoots": "L",
      "DOB": "03/01/00",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587904.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jordan Binnington",
      "pos": "G",
      "number": "50",
      "shoots": "L",
      "DOB": "07/11/93",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590874.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Joel Hofer",
      "pos": "G",
      "number": "30",
      "shoots": "L",
      "DOB": "07/30/00",
      "team": "STL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587909.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alex Barre-Boulet",
      "pos": "C",
      "number": "12",
      "shoots": "L",
      "DOB": "05/21/97",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4270820.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Anthony Cirelli",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "07/15/97",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3941973.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Michael Eyssimont",
      "pos": "C",
      "number": "23",
      "shoots": "L",
      "DOB": "09/09/96",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4270247.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Luke Glendening",
      "pos": "C",
      "number": "11",
      "shoots": "R",
      "DOB": "04/28/89",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2335062.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["DET", "DAL"]
    },
    {
      "name": "Tyler Motte",
      "pos": "C",
      "number": "64",
      "shoots": "L",
      "DOB": "03/10/95",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042138.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["CHI", "CBJ", "VAN", "NYR"]
    },
    {
      "name": "Brayden Point",
      "pos": "C",
      "number": "21",
      "shoots": "R",
      "DOB": "03/13/96",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151187.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Steven Stamkos",
      "pos": "C",
      "number": "91",
      "shoots": "R",
      "DOB": "02/07/90",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5037.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Brandon Hagel",
      "pos": "W",
      "number": "38",
      "shoots": "L",
      "DOB": "08/27/98",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4065019.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CHI"]
    },
    {
      "name": "Tanner Jeannot",
      "pos": "W",
      "number": "84",
      "shoots": "L",
      "DOB": "05/29/97",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064780.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["NSH"]
    },
    {
      "name": "Nicholas Paul",
      "pos": "W",
      "number": "20",
      "shoots": "L",
      "DOB": "03/20/95",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042111.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["OTT"]
    },
    {
      "name": "Conor Sheary",
      "pos": "W",
      "number": "73",
      "shoots": "L",
      "DOB": "06/08/92",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149827.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["PIT", "BUF", "WSH"]
    },
    {
      "name": "Austin Watson",
      "pos": "W",
      "number": "51",
      "shoots": "R",
      "DOB": "01/13/92",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5556.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["NSH", "OTT"]
    },
    {
      "name": "Nikita Kucherov",
      "pos": "W",
      "number": "86",
      "shoots": "R",
      "DOB": "06/17/93",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563060.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Waltteri Merela",
      "pos": "W",
      "number": "39",
      "shoots": "R",
      "DOB": "07/06/98",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5146592.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Zach Bogosian",
      "pos": "D",
      "number": "24",
      "shoots": "R",
      "DOB": "07/15/90",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4002.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Erik Cernak",
      "pos": "D",
      "number": "81",
      "shoots": "R",
      "DOB": "05/28/97",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904178.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SVK",
      "former": []
    },
    {
      "name": "Calvin de Haan",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "05/09/91",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5266.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["NYI", "CAR", "CAR"]
    },
    {
      "name": "Haydn Fleury",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "07/08/96",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114735.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Victor Hedman",
      "pos": "D",
      "number": "77",
      "shoots": "L",
      "DOB": "12/18/90",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5157.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Nick Perbix",
      "pos": "D",
      "number": "48",
      "shoots": "R",
      "DOB": "07/15/98",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4419638.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Darren Raddysh",
      "pos": "D",
      "number": "43",
      "shoots": "R",
      "DOB": "02/28/96",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149843.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mikhail Sergachev",
      "pos": "D",
      "number": "98",
      "shoots": "L",
      "DOB": "06/25/98",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024868.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Jonas Johansson",
      "pos": "G",
      "number": "31",
      "shoots": "L",
      "DOB": "09/19/95",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3115546.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["COL"]
    },
    {
      "name": "Matt Tomkins",
      "pos": "G",
      "number": "90",
      "shoots": "L",
      "DOB": "06/19/94",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069614.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Andrei Vasilevskiy",
      "pos": "G",
      "number": "88",
      "shoots": "L",
      "DOB": "07/25/94",
      "team": "TBL",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976847.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Max Domi",
      "pos": "C",
      "number": "11",
      "shoots": "L",
      "DOB": "03/02/95",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042014.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["ARI", "MTL", "CBJ", "CAR", "DAL"]
    },
    {
      "name": "Noah Gregor",
      "pos": "C",
      "number": "18",
      "shoots": "L",
      "DOB": "07/28/98",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063460.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["SJS"]
    },
    {
      "name": "Calle Jarnkrok",
      "pos": "C",
      "number": "19",
      "shoots": "R",
      "DOB": "09/25/91",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2555316.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["SEA", "NSH"]
    },
    {
      "name": "David Kampf",
      "pos": "C",
      "number": "64",
      "shoots": "L",
      "DOB": "01/12/95",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4267976.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CZE",
      "former": ["CHI"]
    },
    {
      "name": "Auston Matthews",
      "pos": "C",
      "number": "34",
      "shoots": "L",
      "DOB": "09/17/97",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024123.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Bobby McMann",
      "pos": "C",
      "number": "74",
      "shoots": "L",
      "DOB": "06/15/96",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5048894.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Fraser Minten",
      "pos": "C",
      "number": "39",
      "shoots": "L",
      "DOB": "07/05/04",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5080207.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "John Tavares",
      "pos": "C",
      "number": "91",
      "shoots": "L",
      "DOB": "09/20/90",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5160.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["NYI"]
    },
    {
      "name": "Tyler Bertuzzi",
      "pos": "W",
      "number": "59",
      "shoots": "L",
      "DOB": "02/24/95",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042056.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["BOS"]
    },
    {
      "name": "Matthew Knies",
      "pos": "W",
      "number": "23",
      "shoots": "L",
      "DOB": "10/17/02",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4874919.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Easton Cowan",
      "pos": "W",
      "number": "53",
      "shoots": "L",
      "DOB": "05/20/05",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5149211.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mitchell Marner",
      "pos": "W",
      "number": "16",
      "shoots": "R",
      "DOB": "05/05/97",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899937.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "William Nylander",
      "pos": "W",
      "number": "88",
      "shoots": "R",
      "DOB": "05/01/96",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114736.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ryan Reaves",
      "pos": "W",
      "number": "75",
      "shoots": "R",
      "DOB": "01/20/87",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3683.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["STL", "PIT", "VGK", "NYR", "MIN"]
    },
    {
      "name": "TJ Brodie",
      "pos": "D",
      "number": "78",
      "shoots": "L",
      "DOB": "06/07/90",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5162.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CGY"]
    },
    {
      "name": "Mark Giordano",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "10/03/83",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3006.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["CGY", "SEA"]
    },
    {
      "name": "John Klingberg",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "08/14/92",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590751.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": ["DAL", "MIN"]
    },
    {
      "name": "Timothy Liljegren",
      "pos": "D",
      "number": "37",
      "shoots": "R",
      "DOB": "04/30/99",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233618.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Jake McCabe",
      "pos": "D",
      "number": "22",
      "shoots": "L",
      "DOB": "10/12/93",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3020803.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": ["BUF", "CHI"]
    },
    {
      "name": "Jake Muzzin",
      "pos": "D",
      "number": "8",
      "shoots": "L",
      "DOB": "02/21/89",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4906.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["LAK"]
    },
    {
      "name": "Morgan Rielly",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "03/09/94",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2976833.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Conor Timmins",
      "pos": "D",
      "number": "25",
      "shoots": "R",
      "DOB": "09/18/98",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233868.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["COL"]
    },
    {
      "name": "Matt Murray",
      "pos": "G",
      "number": "30",
      "shoots": "L",
      "DOB": "05/25/94",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067862.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "CAN",
      "former": ["PIT", "OTT"]
    },
    {
      "name": "Ilya Samsonov",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "02/22/97",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900505.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "RUS",
      "former": ["WSH"]
    },
    {
      "name": "Joseph Woll",
      "pos": "G",
      "number": "60",
      "shoots": "L",
      "DOB": "07/12/98",
      "team": "TOR",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4271575.png",
      "conference": "East",
      "division": "Atl",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Teddy Blueger",
      "pos": "C",
      "number": "53",
      "shoots": "L",
      "DOB": "08/15/94",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024916.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "LVA",
      "former": ["VGK"]
    },
    {
      "name": "Dakota Joshua",
      "pos": "C",
      "number": "81",
      "shoots": "L",
      "DOB": "05/15/96",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4588638.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["STL"]
    },
    {
      "name": "Sam Lafferty",
      "pos": "C",
      "number": "28",
      "shoots": "R",
      "DOB": "03/06/95",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3151322.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["PIT", "CHI", "TOR"]
    },
    {
      "name": "J.T. Miller",
      "pos": "C",
      "number": "9",
      "shoots": "L",
      "DOB": "03/14/93",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590852.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["NYR", "TBL"]
    },
    {
      "name": "Elias Pettersson",
      "pos": "C",
      "number": "40",
      "shoots": "L",
      "DOB": "11/12/98",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233566.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Pius Suter",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "05/24/96",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4271732.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CHE",
      "former": ["CHI", "DET"]
    },
    {
      "name": "Anthony Beauvillier",
      "pos": "W",
      "number": "72",
      "shoots": "L",
      "DOB": "06/08/97",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3904107.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYI"]
    },
    {
      "name": "Phillip Di Giuseppe",
      "pos": "W",
      "number": "34",
      "shoots": "L",
      "DOB": "10/09/93",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3069834.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CAR", "NYR"]
    },
    {
      "name": "Nils Hoglander",
      "pos": "W",
      "number": "21",
      "shoots": "L",
      "DOB": "12/20/00",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565263.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Andrei Kuzmenko",
      "pos": "W",
      "number": "96",
      "shoots": "R",
      "DOB": "02/04/96",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5077308.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Brock Boeser",
      "pos": "W",
      "number": "6",
      "shoots": "R",
      "DOB": "02/25/97",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899979.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Conor Garland",
      "pos": "W",
      "number": "8",
      "shoots": "R",
      "DOB": "03/11/96",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3939718.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["ARI"]
    },
    {
      "name": "Guillaume Brisebois",
      "pos": "D",
      "number": "55",
      "shoots": "L",
      "DOB": "07/21/97",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942866.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ian Cole",
      "pos": "D",
      "number": "82",
      "shoots": "L",
      "DOB": "02/21/89",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4991.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["STL", "PIT", "CBJ", "COL", "MIN", "CAR", "TBL"]
    },
    {
      "name": "Akito Hirose",
      "pos": "D",
      "number": "41",
      "shoots": "L",
      "DOB": "04/09/99",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4755752.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Filip Hronek",
      "pos": "D",
      "number": "17",
      "shoots": "R",
      "DOB": "11/02/97",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063607.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CZE",
      "former": []
    },
    {
      "name": "Quinn Hughes",
      "pos": "D",
      "number": "43",
      "shoots": "L",
      "DOB": "10/14/99",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4320548.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Noah Juulsen",
      "pos": "D",
      "number": "47",
      "shoots": "R",
      "DOB": "04/02/97",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3900504.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Tyler Myers",
      "pos": "D",
      "number": "57",
      "shoots": "R",
      "DOB": "02/01/90",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5052.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Carson Soucy",
      "pos": "D",
      "number": "7",
      "shoots": "L",
      "DOB": "07/27/94",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042198.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["MIN", "SEA"]
    },
    {
      "name": "Casey DeSmith",
      "pos": "G",
      "number": "1",
      "shoots": "L",
      "DOB": "08/13/91",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025540.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["PIT"]
    },
    {
      "name": "Thatcher Demko",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "12/08/95",
      "team": "VAN",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3096217.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Ivan Barbashev",
      "pos": "C",
      "number": "49",
      "shoots": "L",
      "DOB": "12/14/95",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114985.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Paul Cotter",
      "pos": "C",
      "number": "43",
      "shoots": "L",
      "DOB": "11/16/99",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4331579.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Jack Eichel",
      "pos": "C",
      "number": "9",
      "shoots": "R",
      "DOB": "10/28/96",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3648002.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["BUF"]
    },
    {
      "name": "Brett Howden",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "03/29/98",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024989.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["NYR"]
    },
    {
      "name": "William Karlsson",
      "pos": "C",
      "number": "71",
      "shoots": "L",
      "DOB": "01/08/93",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563057.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "SWE",
      "former": ["CBJ"]
    },
    {
      "name": "Nicolas Roy",
      "pos": "C",
      "number": "10",
      "shoots": "R",
      "DOB": "02/05/97",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3943996.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["CAR"]
    },
    {
      "name": "Chandler Stephenson",
      "pos": "C",
      "number": "20",
      "shoots": "L",
      "DOB": "04/22/94",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3067858.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["WSH"]
    },
    {
      "name": "William Carrier",
      "pos": "W",
      "number": "28",
      "shoots": "L",
      "DOB": "12/20/94",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042054.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["BUF"]
    },
    {
      "name": "Pavel Dorofeyev",
      "pos": "W",
      "number": "16",
      "shoots": "L",
      "DOB": "10/26/00",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587588.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Michael Amadio",
      "pos": "W",
      "number": "22",
      "shoots": "R",
      "DOB": "05/13/96",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3149829.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["LAK", "OTT", "TOR"]
    },
    {
      "name": "Keegan Kolesar",
      "pos": "W",
      "number": "55",
      "shoots": "R",
      "DOB": "04/08/97",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3941546.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Jonathan Marchessault",
      "pos": "W",
      "number": "81",
      "shoots": "R",
      "DOB": "12/27/90",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2967072.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["FLA", "CBJ", "TBL"]
    },
    {
      "name": "Mark Stone",
      "pos": "W",
      "number": "61",
      "shoots": "R",
      "DOB": "05/13/92",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5545.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["OTT"]
    },
    {
      "name": "Nicolas Hague",
      "pos": "D",
      "number": "14",
      "shoots": "L",
      "DOB": "12/05/98",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233870.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Ben Hutton",
      "pos": "D",
      "number": "17",
      "shoots": "L",
      "DOB": "04/20/93",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3571762.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["VAN", "LAK", "ANA", "TOR"]
    },
    {
      "name": "Kaedan Korczak",
      "pos": "D",
      "number": "6",
      "shoots": "R",
      "DOB": "01/29/01",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565264.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alec Martinez",
      "pos": "D",
      "number": "23",
      "shoots": "L",
      "DOB": "07/26/87",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3927.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "USA",
      "former": ["LAK"]
    },
    {
      "name": "Brayden McNabb",
      "pos": "D",
      "number": "3",
      "shoots": "L",
      "DOB": "01/21/91",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5202.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["BUF", "LAK"]
    },
    {
      "name": "Brayden Pachal",
      "pos": "D",
      "number": "94",
      "shoots": "R",
      "DOB": "08/23/99",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4392769.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alex Pietrangelo",
      "pos": "D",
      "number": "7",
      "shoots": "R",
      "DOB": "01/18/90",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4013.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["STL"]
    },
    {
      "name": "Shea Theodore",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "08/03/95",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042055.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["ANA"]
    },
    {
      "name": "Zach Whitecloud",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "11/28/96",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4312877.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Adin Hill",
      "pos": "G",
      "number": "33",
      "shoots": "L",
      "DOB": "05/11/96",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3939714.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": ["SJS"]
    },
    {
      "name": "Logan Thompson",
      "pos": "G",
      "number": "36",
      "shoots": "R",
      "DOB": "02/25/97",
      "team": "VGK",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4272888.png",
      "conference": "West",
      "division": "Pac",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Nicklas Backstrom",
      "pos": "C",
      "number": "19",
      "shoots": "L",
      "DOB": "11/23/87",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3666.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Evgeny Kuznetsov",
      "pos": "C",
      "number": "92",
      "shoots": "L",
      "DOB": "05/19/92",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5647.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Connor McMichael",
      "pos": "C",
      "number": "24",
      "shoots": "L",
      "DOB": "01/15/01",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565246.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Matthew Phillips",
      "pos": "C",
      "number": "45",
      "shoots": "R",
      "DOB": "04/06/98",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4064864.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["CGY"]
    },
    {
      "name": "Aliaksei Protas",
      "pos": "C",
      "number": "21",
      "shoots": "L",
      "DOB": "01/06/01",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587943.png",
      "conference": "East",
      "division": "Met",
      "ctry": "BLR",
      "former": []
    },
    {
      "name": "Dylan Strome",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "03/07/97",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899933.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["ARI", "CHI"]
    },
    {
      "name": "Beck Malenstyn",
      "pos": "W",
      "number": "47",
      "shoots": "L",
      "DOB": "02/04/98",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4063240.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Sonny Milano",
      "pos": "W",
      "number": "15",
      "shoots": "L",
      "DOB": "05/12/96",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114756.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Alex Ovechkin",
      "pos": "W",
      "number": "8",
      "shoots": "R",
      "DOB": "09/17/85",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3101.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "Max Pacioretty",
      "pos": "W",
      "number": "67",
      "shoots": "L",
      "DOB": "11/20/88",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4005.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nic Dowd",
      "pos": "W",
      "number": "26",
      "shoots": "R",
      "DOB": "05/27/90",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025616.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["LAK", "VAN"]
    },
    {
      "name": "Anthony Mantha",
      "pos": "W",
      "number": "39",
      "shoots": "L",
      "DOB": "09/16/94",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042037.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["DET"]
    },
    {
      "name": "T.J. Oshie",
      "pos": "W",
      "number": "77",
      "shoots": "R",
      "DOB": "12/23/86",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5034.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["STL"]
    },
    {
      "name": "Tom Wilson",
      "pos": "W",
      "number": "43",
      "shoots": "R",
      "DOB": "03/29/94",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2970615.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Alexander Alexeyev",
      "pos": "D",
      "number": "27",
      "shoots": "L",
      "DOB": "11/15/99",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352805.png",
      "conference": "East",
      "division": "Met",
      "ctry": "RUS",
      "former": []
    },
    {
      "name": "John Carlson",
      "pos": "D",
      "number": "74",
      "shoots": "R",
      "DOB": "01/10/90",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5118.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Joel Edmundson",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "06/28/93",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563034.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["MTL"]
    },
    {
      "name": "Martin Fehervary",
      "pos": "D",
      "number": "42",
      "shoots": "L",
      "DOB": "10/06/99",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378677.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SVK",
      "former": []
    },
    {
      "name": "Nick Jensen",
      "pos": "D",
      "number": "3",
      "shoots": "R",
      "DOB": "09/21/90",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025608.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["DET"]
    },
    {
      "name": "Lucas Johansen",
      "pos": "D",
      "number": "46",
      "shoots": "L",
      "DOB": "11/16/97",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024994.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Rasmus Sandin",
      "pos": "D",
      "number": "38",
      "shoots": "L",
      "DOB": "03/07/00",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352803.png",
      "conference": "East",
      "division": "Met",
      "ctry": "SWE",
      "former": ["TOR"]
    },
    {
      "name": "Trevor van Riemsdyk",
      "pos": "D",
      "number": "57",
      "shoots": "R",
      "DOB": "07/24/91",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3025524.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["CHI", "CAR"]
    },
    {
      "name": "Darcy Kuemper",
      "pos": "G",
      "number": "35",
      "shoots": "L",
      "DOB": "05/05/90",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2610321.png",
      "conference": "East",
      "division": "Met",
      "ctry": "CAN",
      "former": ["MIN", "ARI", "COL"]
    },
    {
      "name": "Charlie Lindgren",
      "pos": "G",
      "number": "79",
      "shoots": "R",
      "DOB": "12/18/93",
      "team": "WSH",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3095975.png",
      "conference": "East",
      "division": "Met",
      "ctry": "USA",
      "former": ["MTL", "STL"]
    },
    {
      "name": "Mason Appleton",
      "pos": "C",
      "number": "22",
      "shoots": "R",
      "DOB": "01/15/96",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3942043.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Morgan Barron",
      "pos": "C",
      "number": "36",
      "shoots": "R",
      "DOB": "12/02/98",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4316970.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["NYR"]
    },
    {
      "name": "David Gustafsson",
      "pos": "C",
      "number": "19",
      "shoots": "L",
      "DOB": "04/11/00",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4378695.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "SWE",
      "former": []
    },
    {
      "name": "Rasmus Kupari",
      "pos": "C",
      "number": "15",
      "shoots": "R",
      "DOB": "03/15/00",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4352762.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": ["LAK"]
    },
    {
      "name": "Adam Lowry",
      "pos": "C",
      "number": "17",
      "shoots": "L",
      "DOB": "03/29/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2563066.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Vladislav Namestnikov",
      "pos": "C",
      "number": "7",
      "shoots": "L",
      "DOB": "11/22/92",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562622.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "RUS",
      "former": ["NYR", "OTT", "COL", "DET"]
    },
    {
      "name": "Cole Perfetti",
      "pos": "C",
      "number": "91",
      "shoots": "L",
      "DOB": "01/01/02",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4697392.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Mark Scheifele",
      "pos": "C",
      "number": "55",
      "shoots": "R",
      "DOB": "03/15/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2562632.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Gabriel Vilardi",
      "pos": "C",
      "number": "13",
      "shoots": "R",
      "DOB": "08/16/99",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233583.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["LAK"]
    },
    {
      "name": "Kyle Connor",
      "pos": "W",
      "number": "81",
      "shoots": "L",
      "DOB": "12/09/96",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3899952.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nikolaj Ehlers",
      "pos": "W",
      "number": "27",
      "shoots": "L",
      "DOB": "02/14/96",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3114741.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "DNK",
      "former": []
    },
    {
      "name": "Alex Iafallo",
      "pos": "W",
      "number": "9",
      "shoots": "L",
      "DOB": "12/21/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3652598.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["LAK"]
    },
    {
      "name": "Nino Niederreiter",
      "pos": "W",
      "number": "62",
      "shoots": "L",
      "DOB": "09/08/92",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/5511.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CHE",
      "former": ["NYI", "MIN", "NSH", "CAR"]
    },
    {
      "name": "Declan Chisholm",
      "pos": "D",
      "number": "45",
      "shoots": "L",
      "DOB": "01/12/00",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4587929.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Dylan DeMelo",
      "pos": "D",
      "number": "2",
      "shoots": "R",
      "DOB": "05/01/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2590861.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["SJS", "OTT"]
    },
    {
      "name": "Brenden Dillon",
      "pos": "D",
      "number": "5",
      "shoots": "L",
      "DOB": "11/13/90",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/2554903.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["DAL", "SJS", "WSH"]
    },
    {
      "name": "Ville Heinola",
      "pos": "D",
      "number": "14",
      "shoots": "L",
      "DOB": "03/02/01",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4565241.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "FIN",
      "former": []
    },
    {
      "name": "Josh Morrissey",
      "pos": "D",
      "number": "44",
      "shoots": "L",
      "DOB": "03/28/95",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3042016.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Neal Pionk",
      "pos": "D",
      "number": "4",
      "shoots": "R",
      "DOB": "07/29/95",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3988847.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["NYR"]
    },
    {
      "name": "Dylan Samberg",
      "pos": "D",
      "number": "54",
      "shoots": "L",
      "DOB": "01/24/99",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4233878.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    },
    {
      "name": "Nate Schmidt",
      "pos": "D",
      "number": "88",
      "shoots": "L",
      "DOB": "07/16/91",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3024798.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": ["WSH", "VGK", "VAN"]
    },
    {
      "name": "Logan Stanley",
      "pos": "D",
      "number": "64",
      "shoots": "L",
      "DOB": "05/26/98",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/4024968.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": []
    },
    {
      "name": "Laurent Brossoit",
      "pos": "G",
      "number": "39",
      "shoots": "L",
      "DOB": "03/23/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3026526.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "CAN",
      "former": ["VGK"]
    },
    {
      "name": "Connor Hellebuyck",
      "pos": "G",
      "number": "37",
      "shoots": "L",
      "DOB": "05/19/93",
      "team": "WPG",
      "img": "https://a.espncdn.com/i/headshots/nhl/players/full/3020225.png",
      "conference": "West",
      "division": "Cen",
      "ctry": "USA",
      "former": []
    }
  ]


export function getName(data: any) {
    return data?.name;
  }
  
  export function getCtry(player: any): string {
    return player?.ctry;
  }
  
  export function getAge(player: any): number {
    const today = new Date();
    const birthDate = new Date(player.DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  
  export function getNumber(player: any): number {
    const number: number = +player.number;
    return number;
  }
  
  