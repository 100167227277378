import { MyModal } from "./../modals/MyModal";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { SettingsData } from "../../hooks/useSettings";
import { Share } from "../../helpers/Share";
import "./../../styles/GameOverModal.css";
import { getAge, getCtry, Player, players } from "../../core/players2023";

interface GameOverModalProps {
  isOpen: boolean;
  close: () => void;
  winner: boolean;
  guessNumber: number;
  settingsData: SettingsData;
  player: Player;
  setPlayer: Dispatch<SetStateAction<Player>>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setWinner: Dispatch<SetStateAction<boolean>>;
}

export function GameOverModal({
  isOpen,
  close,
  winner,
  guessNumber,
  settingsData,
  player,
  setPlayer,
  setDisabled,
  setWinner,
}: GameOverModalProps) {
  const src = player?.img;
  const [statsOpen, setStats] = useState(false);

  function handleStats() {
    setStats(!statsOpen);
  }

  const [mode, setMode] = useState("streak");

  function getRanArr(lngth: number) {
    let arr: number[] = [];
    do {
      const ran = Math.floor(Math.random() * lngth);
      arr = arr.indexOf(ran) > -1 ? arr : arr.concat(ran);
    } while (arr.length < lngth);

    return arr;
  }

  function handleMode(mode: string) {
    setMode(mode);
    document
      .querySelectorAll(".results-mode-button")[0]
      .classList.remove("activeMode");
    document
      .querySelectorAll(".results-mode-button")[1]
      .classList.remove("activeMode");
    document
      .querySelectorAll(".results-mode-button")[0]
      .classList.add("inactiveMode");
    document
      .querySelectorAll(".results-mode-button")[1]
      .classList.add("inactiveMode");

    document.getElementById(mode)?.classList.remove("inactiveMode");

    document.getElementById(mode)?.classList.add("activeMode");
  }

  function nextPlayer() {
    // const data = JSON.parse(localStorage.getItem('randomArray') || '{}');

    // const numberArray = data.map(Number);

    // numberArray.shift()

    // localStorage.setItem("randomArray", JSON.stringify(numberArray))

    let data = JSON.parse(localStorage.getItem("randomArray") || "{}");

    let numberArray = data.map(Number);

    numberArray.shift();

    if (numberArray.length == 0) {
      localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
      data = JSON.parse(localStorage.getItem("randomArray") || "{}");
      numberArray = data.map(Number);
    } else {
      localStorage.setItem("randomArray", JSON.stringify(numberArray));
    }

    localStorage.removeItem("unlimited");
    close();
    setDisabled(false);
    setWinner(false);
    setTimeout(() => {
      setPlayer(players[numberArray[0]]);
    }, 300);
  }
  const current = JSON.parse(localStorage.getItem("currentStreak") || "{}");

  const bestStreak = JSON.parse(localStorage.getItem("bestStreak") || "{}");

  function nextGame() {
    let data = JSON.parse(localStorage.getItem("randomArray") || "{}");

    let numberArray = data.map(Number);

    numberArray.shift();

    // const current = JSON.parse(localStorage.getItem("currentStreak") || "{}");

    // const bestStreak = JSON.parse(localStorage.getItem("bestStreak") || "{}");

    setWinner(false);

    // if (bestStreak < current) {
    //   // localStorage.setItem("bestStreak", current);
    //   setBest(true);
    // } else if (bestStreak == current) {
    //   setTie(true);
    // } else {
    //   setBest(false);
    //   setTie(false);
    // }

    if (numberArray.length == 0) {
      localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
      data = JSON.parse(localStorage.getItem("randomArray") || "{}");
      numberArray = data.map(Number);
    } else {
      localStorage.setItem("randomArray", JSON.stringify(numberArray));
    }

    // localStorage.setItem("randomArray", JSON.stringify(numberArray))
    localStorage.setItem("currentStreak", JSON.stringify(0));
    localStorage.removeItem("unlimited");
    close();
    setDisabled(false);
    setTimeout(() => {
      setPlayer(players[numberArray[0]]);
    }, 300);
  }

  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container">
        {winner && (
          <div>
            <div
              className="silhouette-image reveal"
              style={{
                backgroundImage: "url(" + src + ")",
                backgroundSize: "contain",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
              }}
            />
            {/* <img alt="player headshot" className="silhouette-image reveal" src={player?.img} /> */}
            <hr className={winner ? "green" : "red"}></hr>

            <h3 className="result-h3">
              {winner ? "Great job!" : "The correct answer was"}
            </h3>
            <h2 className="result-player-name">{player?.name}</h2>
            <h3 className="result-h3">
              {guessNumber === 1 && "You solved it in " + 1 + " guess!"}
              {guessNumber > 1 &&
                "You solved it in " + guessNumber + " guesses!"}
            </h3>
            <hr className={winner ? "green" : "red"}></hr>

            <h3 className="result-h3">
              Your streak is currently at:{" "}
              {localStorage.getItem("currentStreak")}
            </h3>
          </div>
        )}

        {!winner && (
          <div>
            <div className="mode-buttons">
              <button
                className="results-mode-button activeMode"
                id="streak"
                onClick={() => handleMode("streak")}
              >
                RESULTS
              </button>
              <button
                className="results-mode-button inactiveMode"
                id="info"
                onClick={() => handleMode("info")}
              >
                PLAYER INFO
              </button>
            </div>

            {mode == "streak" && (
              <div>
                {bestStreak < current && (
                  <h3 className="result-h3">Wow! You just beat your streak!</h3>
                )}

                {bestStreak === current && (
                  <h3 className="result-h3">
                    You tied your best streak! Nice!
                  </h3>
                )}

                {bestStreak > current && (
                  <h3 className="result-h3">Almost there! Keep trying!</h3>
                )}

                <div className="streak-main-container">
                  <div className="streak-item">
                    <div className="streak-data">
                      {localStorage.getItem("currentStreak")}
                    </div>
                    <div className="streak-label">Current Streak</div>
                  </div>

                  <div className="streak-item">
                    <div className="streak-data">
                      {localStorage.getItem("bestStreak")}
                    </div>
                    <div className="streak-label">Best Streak</div>
                  </div>
                </div>
              </div>
            )}
            {mode == "info" && (
              <div className="unlimited-info">
                <div
                  className="silhouette-image reveal"
                  style={{
                    backgroundImage: "url(" + src + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "50% 50%",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {/* <img alt="player headshot" className="silhouette-image reveal" src={player?.img} /> */}
                <hr className={winner ? "green" : "red"}></hr>

                <h3 className="result-h3">
                  {winner ? "Great job!" : "The correct answer was"}
                </h3>
                <h2 className="result-player-name">{player?.name}</h2>
                <h3 className="result-h3">
                  {winner &&
                    guessNumber === 1 &&
                    "You solved it in " + 1 + " guess!"}
                  {winner &&
                    guessNumber > 1 &&
                    "You solved it in " + guessNumber + " guesses!"}
                  {!winner && "Better luck next time!"}
                </h3>
                {/* <Share winner={winner} guesses={guesses} dayString={dayString} settingsData={settingsData}/> */}

                <hr className={winner ? "green" : "red"}></hr>

                <div className="table-container2">
                  <div className="table-head2">
                    <div className="table-row2">
                      <div>Team</div>
                      <div>Conf</div>
                      <div>Div</div>
                      <div>Pos</div>
                    </div>
                  </div>
                  <div className="table-body2">
                    <div className="table-row2">
                      <div className="table-cell2">
                        <div>
                          <img
                            alt="team logo"
                            className="table-img"
                            src={"nhl/" + player?.team + ".png"}
                          />
                        </div>
                      </div>
                      <div className="table-cell2">
                        <div>
                          <img
                            alt="team logo"
                            className="conference-logo"
                            src={"nhl/" + player?.conference + ".png"}
                          />
                        </div>
                      </div>
                      <div className="table-cell2">
                        <div>
                          <img
                            alt="team logo"
                            className="division-logo"
                            src={"nhl/" + player?.division + ".png"}
                          />
                        </div>
                      </div>
                      <div className="table-cell2">{player?.pos}</div>
                    </div>
                  </div>
                  <div className="table-head2">
                    <div className="table-row2">
                      <div>Sh</div>
                      <div>Age</div>
                      <div>Ctry</div>
                      <div>#</div>
                    </div>
                  </div>
                  <div className="table-body2">
                    <div className="table-row2">
                      <div className="table-cell2">{player?.shoots}</div>

                      <div className="table-cell2">{getAge(player)}</div>

                      <div className="table-cell2">
                        <div>
                          <img
                            alt="player country"
                            className="table-img"
                            src={"flags/" + player?.ctry + ".svg"}
                          />
                        </div>{" "}
                      </div>
                      <div className="table-cell2">{player?.number}</div>
                    </div>
                  </div>
                  {/* <button className="toggle-button" onClick={handleStats}>Toggle Stats</button> */}
                </div>
              </div>
            )}
          </div>
        )}

        {winner && (
          <button className="new-game-button" onClick={nextPlayer}>
            Next Player
          </button>
        )}
        {!winner && (
          <button className="new-game-button" onClick={nextGame}>
            New Game
          </button>
        )}
      </div>
    </MyModal>
  );
}
