import { MyModal } from "./MyModal";
import React from "react";
import "./../../styles/HowTo.css";

interface AnnouncementProps {
  isOpen: boolean;
  close: () => void;
}

export function Announcements({ isOpen, close }: AnnouncementProps) {
  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container">
        <h2 className="how-to-h2">Announcements</h2>

        <h3 className="how-to-h3">New Season - 10/17/23</h3>
        <ul className="how-to-ul" style={{ textAlign: "left" }}>
          <li>
            A new season of Dangle is here!
          </li>
          <li>
            The player pool has been updated to the new season.
          </li>
          <li>All rules remain the same.</li>
          <li>
            For up to the minute news on Dangle follow at{" "}
            <a
              className="twitter-link"
              href="https://twitter.com/Dangle_Game"
              target="_blank"
              rel="noreferrer"
            >
              @Dangle_Game
            </a>{" "}
            on Twitter!
          </li>
        </ul>
      </div>
    </MyModal>
  );
}
