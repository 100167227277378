import React from "react";
import { loadAllGuesses } from "../../core/guess";
import { SettingsData } from "../../hooks/useSettings";
import { MyModal } from "./MyModal";
import "./../../styles/Settings.css";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface SettingsProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
  mode: string;
  updateSettings: (newSettings: Partial<SettingsData>) => void;
}

export function Settings({
  isOpen,
  close,
  settingsData,
  mode,
  updateSettings,
}: SettingsProps) {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  const todaysDate = yyyy + "-" + mm + "-" + dd;

  let disabled = false;

  if (loadAllGuesses()[todaysDate] !== undefined) {
    disabled = true;
  }
  else if(mode == "unlimited"){
    disabled = true;
  }

  return (
    <MyModal isOpen={isOpen} close={close}>
      <h2 className="how-to-h2">Settings</h2>
      <div className="hardMode-toggle">
        {window.innerWidth >= 768 ? (
          <a className="hardModeIcon" data-tip data-for="hardModeInfo">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        ) : (
          <a
            className="hardModeIcon"
            data-tip
            data-for="hardModeInfo"
            data-event="click focus"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        )}
        <h3>Hard Mode</h3>
        <label className="switch">
          <input
            disabled={disabled}
            type="checkbox"
            id="setting-hardMode"
            checked={settingsData.hardMode}
            onChange={(e) => updateSettings({ hardMode: e.target.checked })}
          />
          <span
            style={{ opacity: disabled ? "0.3" : "1" }}
            className="slider"
          ></span>
        </label>

        {window.innerWidth >= 768 ? (
          <ReactTooltip
            id="hardModeInfo"
            place="top"
            type="dark"
            effect="solid"
          >
            <ul style={{ paddingLeft: 0 }}>
              <li>
                The toggle can only be used before the game begins, at that
                point your decision is locked until the next day.
              </li>
              <li>
                If toggled to <span style={{ color: "#28d478" }}>green</span>,
                hard mode is active. When active, there will be no hints besides
                a <span style={{ color: "#28d478" }}>green </span>
                match. There will be no arrows,{" "}
                <span style={{ color: "#ffd000" }}>no yellow</span> hints, and
                no silhouette.
              </li>
            </ul>
          </ReactTooltip>
        ) : (
          <ReactTooltip
            id="hardModeInfo"
            place="top"
            type="dark"
            effect="solid"
            globalEventOff="click"
          >
            <ul style={{ paddingLeft: 0 }}>
              <li>
                The toggle can only be used before the game begins, at that
                point your decision is locked until the next day.
              </li>
              <li>
                If toggled to <span style={{ color: "#28d478" }}>green</span>,
                hard mode is active. When active, there will be no hints besides
                a <span style={{ color: "#28d478" }}>green </span>
                match. There will be no arrows,{" "}
                <span style={{ color: "#ffd000" }}>no yellow</span> hints, and
                no silhouette.
              </li>
            </ul>
          </ReactTooltip>
        )}
      </div>
    </MyModal>
  );
}
