import { MyModal } from "./../modals/MyModal";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { SettingsData } from "../../hooks/useSettings";
import "../../styles/Game.css";
import { Player, players } from "../../core/players2023";

interface ConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
  setPlayer: Dispatch<SetStateAction<Player>>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setWinner: Dispatch<SetStateAction<boolean>>;
}

export function ConfirmModal({
  isOpen,
  close,
  settingsData,
  setPlayer,
  setDisabled,
  setWinner,
}: ConfirmModalProps) {
  function getRanArr(lngth: number) {
    let arr: number[] = [];
    do {
      const ran = Math.floor(Math.random() * lngth);
      arr = arr.indexOf(ran) > -1 ? arr : arr.concat(ran);
    } while (arr.length < lngth);

    return arr;
  }
  function handleNo() {
    close();
  }

  function handleYes() {
    let data = JSON.parse(localStorage.getItem("randomArray") || "{}");

    let numberArray = data.map(Number);

    numberArray.shift();

    const current = JSON.parse(localStorage.getItem("currentStreak") || "{}");

    const bestStreak = JSON.parse(localStorage.getItem("bestStreak") || "{}");

    if (current > bestStreak) {
      localStorage.setItem("bestStreak", current);
    }
    setWinner(false);

    if (numberArray.length == 0) {
      localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
      data = JSON.parse(localStorage.getItem("randomArray") || "{}");
      numberArray = data.map(Number);
    } else {
      localStorage.setItem("randomArray", JSON.stringify(numberArray));
    }

    localStorage.setItem("currentStreak", JSON.stringify(0));
    localStorage.removeItem("unlimited");
    close();
    setDisabled(false);
    setTimeout(() => {
      setPlayer(players[numberArray[0]]);
    }, 300);
  }

  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container">
        <h2 className="confirm-h2">
          ARE YOU SURE?
        </h2>
        <p className="confirm-p">Your current streak will be reset to 0.</p>
        <div className="buttons-container-confirm">
          <button className="yes-button" onClick={handleYes}>
            Yes
          </button>
          <button className="no-button" onClick={handleNo}>
            No
          </button>
        </div>
      </div>
    </MyModal>
  );
}
