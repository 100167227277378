import { MyModal } from "./MyModal";
import React from "react";
import "./../../styles/HowTo.css";
import { CountDownTimer } from "../../helpers/CountDownTimer";
import "./../../styles/About.css";

interface AboutProps {
  isOpen: boolean;
  close: () => void;
}

export function About({ isOpen, close }: AboutProps) {
  const date: Date = new Date();
  let hours: number = date.getHours();
  let minutes: number = date.getMinutes();
  let seconds: number = date.getSeconds();

  if (seconds === 0) {
    minutes = 60 - minutes;
  } else {
    minutes = 59 - minutes;
  }

  if (minutes === 0) {
    hours = 24 - hours;
  } else {
    hours = 23 - hours;
  }

  seconds = 60 - seconds;

  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container about">
        <h2 className="how-to-h2">About Dangle</h2>
        <div style={{ textAlign: "left" }}>
          <p>
            Dangle is a daily hockey player guessing game. You get a new mystery
            player to guess everyday!
          </p>
          <p>
            I have taken heavy influence from{" "}
            <a
              href="https://poeltl.dunk.town/"
              rel="noreferrer"
              target="_blank"
            >
              Poeltl
            </a>{" "}
            , as a diehard hockey fan I felt it was needed that we have our own
            version of the viral game. I also was guided by{" "}
            <a
              href="https://worldle.teuteuf.fr/"
              rel="noreferrer"
              target="_blank"
            >
              Worldle
            </a>{" "}
            and of course the viral{" "}
            <a
              href="https://www.nytimes.com/games/wordle/index.html"
              rel="noreferrer"
              target="_blank"
            >
              Wordle
            </a>
            . Without them this wouldn&apos;t be possible!
          </p>

          <p>
            Check out my other work at{" "}
            <a
              href="https://www.ryanjschwarz.com/"
              rel="noreferrer"
              target="_blank"
            >
              ryanjschwarz.com!
            </a>
          </p>
        </div>

        <p style={{ marginBottom: "0" }}>Next Dangle in:</p>
        <CountDownTimer hours={hours} minutes={minutes} seconds={seconds} />
        <p style={{ margin: "0",fontSize: "10px" }}>*Dangle is no way affiliated with the NHL, it is a fan made site made for fun and for the enjoyment of hockey fans around the globe.</p>
      </div>
    </MyModal>
  );
}
