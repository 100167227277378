import { DateTime } from "luxon";

export interface StatsData {
  currentStreak: number;
  maxStreak: number;
  gamesPlayed: number;
  winRatio: number;
  guessDistribution: Record<1 | 2 | 3 | 4 | 5 | 6, number>;
  totalGuesses: number;
  winCount: number;
}

export interface Results {
  attempts: number;
  result: string;
}

export function saveResultsData(dayString: string, result: Results): void {
  const allResults = loadResultsData();
  localStorage.setItem(
    "results",
    JSON.stringify({
      ...allResults,
      [dayString]: result,
    })
  );
}

export function loadResultsData(): Record<string, Results> {
  const storedResults = localStorage.getItem("results");
  return storedResults != null ? JSON.parse(storedResults) : {};
}

export function savePlayoffResultsData(dayString: string, result: Results): void {
  const allResults = loadPlayoffResultsData();
  localStorage.setItem(
    "playoffResults2023",
    JSON.stringify({
      ...allResults,
      [dayString]: result,
    })
  );
}

export function loadPlayoffResultsData(): Record<string, Results> {
  const storedResults = localStorage.getItem("playoffResults2023");
  return storedResults != null ? JSON.parse(storedResults) : {};
}

export function getStats(): StatsData {
  let totalGuesses = 0;
  const allResults = loadResultsData();

  const allResultsEntries = Object.entries(allResults);
  const gamesPlayed = allResultsEntries.length;

  const guessDistribution = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0
  };

  let currentStreak = 0;
  let maxStreak = 0;
  let previousDate: DateTime | undefined;

  for (const [dayString, results] of allResultsEntries) {
    const currentDate = DateTime.fromFormat(dayString, "yyyy-MM-dd");
    totalGuesses += results.attempts;

    const won = results.result === "won";

    if (won) {
      const winIndex = results.attempts;
      const tryCount = winIndex as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
      guessDistribution[tryCount]++;

      if (previousDate == null ||
        previousDate.plus({ days: 1 }).hasSame(currentDate, "day")
      ) {
        currentStreak++;
      } 
      else {
        currentStreak = 1;
      }
    } 
    else {
      currentStreak = 0;
    }

    if (currentStreak > maxStreak) {
      maxStreak = currentStreak;
    }
    previousDate = currentDate;
  }

  const winCount = Object.values(guessDistribution).reduce(
    (total, win) => total + win
  );

  return {
    currentStreak: currentStreak,
    maxStreak: maxStreak,
    gamesPlayed,
    winRatio: winCount / (gamesPlayed || 1),
    guessDistribution: guessDistribution,
    totalGuesses,
    winCount: winCount,
  };
}
