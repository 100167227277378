import { Game } from "./components/Game";
import React, { useMemo, useState } from "react";
import { HowToPlay } from "./components/modals/HowToPlay";
import { useSettings } from "./hooks/useSettings";
import { Dangle } from "./helpers/Dangle";
import { Stats } from "./components/modals/Stats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faChartColumn,
  faCircleQuestion,
  faBullhorn,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import "./styles/footer.css";
import { About } from "./components/modals/About";
import { Settings } from "./components/modals/Settings";
import { loadAllGuesses, loadAllPlayoffGuesses } from "./core/guess";
import { Announcements } from "./components/modals/Announcements";
import { Unlimited } from "./components/unlimited/Unlimited";
import { Playoff } from "./components/playoff/Game";

function App() {
  const [infoOpen, setInfoOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [announcementsOpen, setAnnouncementsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [settingsData, updateSettings] = useSettings();

  useMemo(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    const todaysDate = yyyy + "-" + mm + "-" + dd;

    const storedResults = localStorage.getItem("results");
    const myResults = storedResults != null ? JSON.parse(storedResults) : {};

    const storedGuesses = localStorage.getItem("guesses");
    const myGuesses = storedGuesses != null ? JSON.parse(storedGuesses) : {};

    if (loadAllGuesses()[todaysDate] === undefined) {
      setAnnouncementsOpen(true);
    } 
    
    if (
      loadAllGuesses()["2023-06-03"]?.length === 8 &&
      myResults["2023-06-03"] &&
      myResults["2023-06-03"].result == "fail"
    ) {
      // if(myResults[todaysDate] && myResults[todaysDate].result == "fail"){

      delete myGuesses["2023-06-03"]
      delete myResults["2023-06-03"];
      // console.log(myResults);
      localStorage.setItem(
        "guesses",
        JSON.stringify({
          ...myGuesses,
        })
      );

      localStorage.setItem(
        "results",
        JSON.stringify({
          ...myResults,
        })
      );
    }
  }, []);

  const [mode, setMode] = useState("classic");

  function handleMode(mode: string) {
    setMode(mode);
    document
      .querySelectorAll(".main-mode-button")[0]
      .classList.remove("activeMode");
    document
      .querySelectorAll(".main-mode-button")[1]
      .classList.remove("activeMode");
    // document
    //   .querySelectorAll(".main-mode-button")[2]
    //   .classList.remove("activeMode");
    document
      .querySelectorAll(".main-mode-button")[0]
      .classList.add("inactiveMode");
    document
      .querySelectorAll(".main-mode-button")[1]
      .classList.add("inactiveMode");
    // document
    //   .querySelectorAll(".main-mode-button")[2]
    //   .classList.add("inactiveMode");

    document.getElementById(mode)?.classList.remove("inactiveMode");

    document.getElementById(mode)?.classList.add("activeMode");
  }

  return (
    <>
      <About isOpen={aboutOpen} close={() => setAboutOpen(false)} />
      <HowToPlay isOpen={infoOpen} close={() => setInfoOpen(false)} />
      <Announcements
        isOpen={announcementsOpen}
        close={() => setAnnouncementsOpen(false)}
      />
      <Settings
        isOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        settingsData={settingsData}
        updateSettings={updateSettings}
        mode={mode}
      />
      <Stats isOpen={statsOpen} close={() => setStatsOpen(false)} />
      <div className="main-container">
        <div className="header-container">
          <header className="main-header">
            <div className="header">
              <div className="link-container">
                <a className="head-link" onClick={() => setAboutOpen(true)}>
                  About
                </a>
                <a
                  className="head-link"
                  onClick={() => setAnnouncementsOpen(true)}
                >
                  Announcements
                </a>
                <a className="head-link" onClick={() => setInfoOpen(true)}>
                  How To Play
                </a>
                <a className="head-link" onClick={() => setSettingsOpen(true)}>
                  Settings
                </a>
                <a className="head-link" onClick={() => setStatsOpen(true)}>
                  Stats
                </a>
              </div>
              <Dangle />
            </div>

            <div className="header-mobile">
              <a
                className="head-link mobile-left-one"
                onClick={() => setAboutOpen(true)}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </a>
              <a
                className="head-link mobile-left"
                onClick={() => setAnnouncementsOpen(true)}
              >
                <FontAwesomeIcon icon={faBullhorn} />
              </a>
              <a
                className="head-link mobile-left-two"
                onClick={() => setInfoOpen(true)}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </a>
              <Dangle />
              <a
                className="head-link mobile-right"
                onClick={() => setSettingsOpen(true)}
              >
                <FontAwesomeIcon icon={faGear} />
              </a>
              <a
                className="head-link mobile-right-one"
                onClick={() => setStatsOpen(true)}
              >
                <FontAwesomeIcon icon={faChartColumn} />
              </a>
            </div>
            <div className="mode-buttons">
              <button
                className="mode-button main-mode-button activeMode"
                id="classic"
                onClick={() => handleMode("classic")}
              >
                CLASSIC
              </button>
              <button
                className="mode-button main-mode-button inactiveMode"
                id="unlimited"
                onClick={() => handleMode("unlimited")}
              >
                UNLIMITED
              </button>
              {/* <button
                className="mode-button main-mode-button inactiveMode"
                id="playoffs"
                onClick={() => handleMode("playoffs")}
              >
                PLAYOFFS
              </button> */}
            </div>
          </header>

          {mode == "classic" && (
            <Game settingsData={settingsData} updateSettings={updateSettings} />
          )}

          {mode == "unlimited" && (
            <Unlimited
              settingsData={settingsData}
              updateSettings={updateSettings}
            />
          )}

          {/* {mode == "playoffs" && (
            <Playoff
              settingsData={settingsData}
              updateSettings={updateSettings}
            />
          )} */}
        </div>
      </div>
    </>
  );
}

export default App;
