import { MyModal } from "./../modals/MyModal";
import React, { useMemo } from "react";
import { SettingsData } from "../../hooks/useSettings";
import "../../styles/Game.css";
import { Player } from "../../core/players2023";

interface SilhouetteModalProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
  player: Player;
}

export function SilhouetteModal({
  isOpen,
  close,
  settingsData,
  player
}: SilhouetteModalProps) {

  const src =  player?.img;

  // function toggleFinalModal(){

  // }
  
  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="modal-container silhouette-container">
        <div className="silhouette-image" style={{ 
      backgroundImage: "url(" + src + ")", backgroundSize: "contain", backgroundPosition: "50% 50%",
      backgroundRepeat: "no-repeat"
    }} />
        {/* <img draggable="false" alt="Player Silhouette" className="silhouette-image" src={player?.img} /> */}
        <hr style={{ border: "1px solid #b1c2d2" }}></hr>
        <h2>
          Who is the <span>mystery player</span>?
        </h2>
        <hr style={{ border: "1px solid #b1c2d2" }}></hr>
        {/* <button onClick={toggleFinalModal}>Quit Game</button> */}
      </div>
    </MyModal>
  );
}
