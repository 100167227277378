import React, { useState } from "react";
import { getStats } from "./../../core/stats";
import { getPlayoffStats } from "./../playoff/stats";
import { MyModal } from "./../modals/MyModal";
import "./../../styles/Stats.css";

interface StatsProps {
  isOpen: boolean;
  close: () => void;
}

export function Stats({ isOpen, close }: StatsProps) {
  const [mode, setMode] = useState("classic-stats");

  function handleMode(mode: string){
    setMode(mode)
    document.querySelectorAll(".mode-button-stats")[0].classList.remove("activeMode")
    document.querySelectorAll(".mode-button-stats")[1].classList.remove("activeMode")
    document.querySelectorAll(".mode-button-stats")[0].classList.add("inactiveMode")
    document.querySelectorAll(".mode-button-stats")[1].classList.add("inactiveMode")

    document.getElementById(mode)?.classList.remove("inactiveMode")

    document.getElementById(mode)?.classList.add("activeMode")
  }

  const {
    gamesPlayed,
    winRatio,
    currentStreak,
    maxStreak,
    guessDistribution,
    totalGuesses,
    winCount,
  } = getStats();

  const {
    gamesPlayed2,
    winRatio2,
    currentStreak2,
    maxStreak2,
    guessDistribution2,
    totalGuesses2,
    winCount2,
  } = getPlayoffStats();

  const avgGuess = (totalGuesses / gamesPlayed).toFixed(1);

  const maxDistribution = Math.max(...Object.values(guessDistribution));

  const avgGuess2 = (totalGuesses2 / gamesPlayed2).toFixed(1);

  const maxDistribution2 = Math.max(...Object.values(guessDistribution2));

  return (
    <MyModal isOpen={isOpen} close={close}>
      <div className="stats-modal-container">
                    <div className="mode-buttons">
            <button className="mode-button mode-button-stats activeMode" id="classic-stats" onClick={() => handleMode("classic-stats")}>CLASSIC</button>
            <button className="mode-button mode-button-stats inactiveMode" id="playoffs 23" onClick={() => handleMode("playoffs 23")}>PLAYOFFS 23</button>
          </div>
      <h2 style={{textAlign: "center", fontFamily:'"Oswald", sans-serif', textTransform: "uppercase"}}>{mode.split("-")[0]} Mode Stats</h2>

        {mode == "classic-stats" &&
        <div className="stats-main-container">
          <div className="stats-top-container">
            <h2>STATS</h2>
            <div className="stats-grid-container">
              <div className="stats-item">
                <div className="stat-data">{gamesPlayed}</div>
                <div className="stat-label">Games Played</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{Math.round(winRatio * 100)}</div>
                <div className="stat-label">Win %</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{currentStreak}</div>
                <div className="stat-label">Current Streak</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{maxStreak}</div>
                <div className="stat-label">Max Streak</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{avgGuess}</div>
                <div className="stat-label">Avg Guess</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{totalGuesses}</div>
                <div className="stat-label">Total Guesses</div>
              </div>
            </div>
          </div>

          <div className="guess-main-container">
            <h2>GUESS DISTRIBUTION</h2>
            <div className="guess-container">
              {Object.entries(guessDistribution).map(([index, count]) => {
                let percent = (count / maxDistribution) * 100 + "%";
                if (count / maxDistribution <= 0.08) {
                  percent = "8%";
                }
                return (
                  <div key={index} className="bar-container">
                    <div>{index}</div>
                    <div
                      className="graph-bar"
                      style={{ height: percent, background: "#b1c2d2" }}
                    >
                      <div
                        className="graph-bar-inner"
                        style={{
                          height: "100%",
                        }}
                      >
                        <div className="guess-attempts">{count}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        }


{mode == "playoffs 23" &&
        <div className="stats-main-container">

          <div className="stats-top-container">
            <h2>STATS</h2>
            <div className="stats-grid-container">
              <div className="stats-item">
                <div className="stat-data">{gamesPlayed2}</div>
                <div className="stat-label">Games Played</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{Math.round(winRatio2 * 100)}</div>
                <div className="stat-label">Win %</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{currentStreak2}</div>
                <div className="stat-label">Current Streak</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{maxStreak2}</div>
                <div className="stat-label">Max Streak</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{avgGuess2}</div>
                <div className="stat-label">Avg Guess</div>
              </div>

              <div className="stats-item">
                <div className="stat-data">{totalGuesses2}</div>
                <div className="stat-label">Total Guesses</div>
              </div>
            </div>
          </div>

          <div className="guess-main-container">
            <h2>GUESS DISTRIBUTION</h2>
            <div className="guess-container">
              {Object.entries(guessDistribution2).map(([index, count]) => {
                let percent = (count / maxDistribution2) * 100 + "%";
                if (count / maxDistribution2 <= 0.08) {
                  percent = "8%";
                }
                return (
                  <div key={index} className="bar-container">
                    <div>{index}</div>
                    <div
                      className="graph-bar"
                      style={{ height: percent, background: "#b1c2d2" }}
                    >
                      <div
                        className="graph-bar-inner"
                        style={{
                          height: "100%",
                        }}
                      >
                        <div className="guess-attempts">{count}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        }


      </div>
    </MyModal>
  );
}
