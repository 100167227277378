import { Guess } from "../../core/guess";
import React, { useEffect, useState } from "react";
import { players, getCtry, getName, getAge, getNumber } from "../../core/players2023";
import "../../styles/GuessRow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { countries } from "../../core/countries";
import { SettingsData } from "../../hooks/useSettings";
import { Player } from "../../core/players2023";

interface GuessRowProps {
  guess?: Guess;
  settingsData: SettingsData;
  player: Player;
}

export function GuessRow({ guess, settingsData, player }: GuessRowProps) {
  const guessedPlayer = players.find(
    (player: { name: any }) => guess?.name === player.name
  );

  const country: string = getCtry(guessedPlayer);

  const [stylingArray, setStylingArray] = useState({
    name: "",
    team: "",
    conference: "",
    division: "",
    pos: "",
    shoots: "",
    age: "",
    ctry: "",
    number: "",
    ageArrow: "",
    numberArrow: "",
  });
  useEffect(() => {
    setStylingArray((obj) => {
      const green = "#28d478";
      const yellow = "#ffd000";

      const white = "rgba(255,255,255,0.1)";
      if (guessedPlayer !== undefined && player !== undefined) {
        guessedPlayer?.name === getName(player)
          ? (obj.name = green)
          : (obj.name = white);

        guessedPlayer?.conference === player?.conference
          ? (obj.conference = green)
          : (obj.conference = white);
        guessedPlayer?.division === player?.division
          ? (obj.division = green)
          : (obj.division = white);

        getAge(guessedPlayer) === getAge(player)
          ? (obj.age = green)
          : (obj.age = white);

        getAge(guessedPlayer) - getAge(player) >= -2 &&
        getAge(guessedPlayer) - getAge(player) <= 2
          ? (obj.age = yellow)
          : (obj.age = white);

        getAge(guessedPlayer) < getAge(player)
          ? (obj.ageArrow = "up")
          : (obj.ageArrow = "down");

        if (getAge(guessedPlayer) === getAge(player)) {
          obj.ageArrow = "none";
          obj.age = green;
        }

        guessedPlayer?.pos === player?.pos
          ? (obj.pos = green)
          : (obj.pos = white);

        guessedPlayer?.ctry === player?.ctry
          ? (obj.ctry = green)
          : (obj.ctry = white);

        // guessedPlayer?.team === player?.team
        //   ? (obj.team = green)
        //   : (obj.team = white);

        if (guessedPlayer.team === player.team) {
          obj.team = green;
        } else if (player.former.includes(guessedPlayer.team)) {
          obj.team = yellow;
        } else {
          obj.team = white;
        }

        guessedPlayer?.shoots === player?.shoots
          ? (obj.shoots = green)
          : (obj.shoots = white);

          getNumber(guessedPlayer)- getNumber(player) >= -5 &&
          getNumber(guessedPlayer) - getNumber(player) <= 5
          ? (obj.number = yellow)
          : (obj.number = white);

          getNumber(guessedPlayer) < getNumber(player)
          ? (obj.numberArrow = "up")
          : (obj.numberArrow = "down");

        if (getNumber(guessedPlayer) === getNumber(player)) {
          obj.numberArrow = "none";
          obj.number = green;
        }
      }
      return { ...obj };
    });
  }, []);

  const team = guessedPlayer?.team;

  return (
    <div className="table-row">
      <div
        className="table-cell"
        style={{ backgroundColor: stylingArray.name }}
      >
        <div>
          <div>{guess?.name}</div>
        </div>
      </div>

      <div className="table-cell" style={{ background: stylingArray.team }}>
        <div>
          <div>
            <img
              alt="team logo"
              className={"table-img " + team}
              src={"nhl/" + guessedPlayer?.team + ".png"}
            />
          </div>
          <div>{guess && guessedPlayer?.team}</div>
        </div>
      </div>

      <div
        className="table-cell"
        style={{ background: stylingArray.conference }}
      >
        <div>
          <img
            alt="conference logo"
            className="conference-logo"
            src={"nhl/" + guessedPlayer?.conference + ".png"}
          />
          {/* <div>{guess && myPlayer?.conference}</div> */}
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.division }}>
        <div>
          <img
            alt="division logo"
            className="division-logo"
            src={"nhl/" + guessedPlayer?.division + ".png"}
          />

          {/* <div>{guess && myPlayer?.division}</div> */}
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.pos }}>
        <div>
          <div>{guess && guessedPlayer?.pos}</div>
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.shoots }}>
        <div>
          <div>{guess && guessedPlayer?.shoots}</div>
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.age }}>
        <div>
          <div>
            {guess && getAge(guessedPlayer)}{" "}
            {stylingArray.ageArrow === "up" && (
              <FontAwesomeIcon icon={faArrowUp} />
            )}
            {stylingArray.ageArrow === "down" && (
              <FontAwesomeIcon icon={faArrowDown} />
            )}
          </div>
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.ctry }}>
        <div>
          <div>
            {guess && (
              <img
                alt="player country flag"
                className="table-img"
                src={"flags/" + country + ".svg"}
              />
            )}
          </div>
          <div>{guess && countries[country].name}</div>
        </div>
      </div>
      <div className="table-cell" style={{ background: stylingArray.number }}>
        <div>
          <div>
            {guess && getNumber(guessedPlayer)}{" "}
            {stylingArray.numberArrow === "up" && (
              <FontAwesomeIcon icon={faArrowUp} />
            )}
            {stylingArray.numberArrow === "down" && (
              <FontAwesomeIcon icon={faArrowDown} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
