import { useCallback } from "react";
import { Guess, loadUnlimitedData, saveUnlimitedData } from "../../core/guess";

export function useUnlimited() {
  const addUnlimitedGuess = useCallback((newGuess: Guess) => {
    saveUnlimitedData(newGuess);
  }, []);

  return addUnlimitedGuess;
}
