import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { Guess, loadAllGuesses } from "../core/guess";
import { getAge, Player, players, getNumber } from "../core/players2023";
import { SettingsData } from "../hooks/useSettings";
import { useTodays } from "../hooks/useTodays";
import { loadResultsData } from "./../core/stats";

const START_DATE = DateTime.fromISO("2022-10-13");

interface ShareProps {
  guesses: Guess[];
  winner: boolean;
  dayString: string;
  settingsData: SettingsData;
}

export function Share({
  guesses,
  winner,
  dayString,
  settingsData,
}: ShareProps) {
  let string = "";

  const myGuess = loadAllGuesses();

  const [todays] = useTodays(dayString);
  const { player } = todays;

  function getShareLine(guessedPlayer: Player | undefined) {
    const obj = {
      team: "",
      conference: "",
      division: "",
      pos: "",
      shoots: "",
      age: "",
      ctry: "",
      number: "",
    };

    if (guessedPlayer !== undefined && player !== undefined) {
      if (guessedPlayer.team === player.team) {
        obj.team = "green";
      } else if (
        !settingsData.hardMode &&
        player.former.includes(guessedPlayer.team)
      ) {
        obj.team = "yellow";
      } else {
        obj.team = "white";
      }

      {
        guessedPlayer?.conference === player?.conference
          ? (obj.conference = "green")
          : (obj.conference = "white");
      }

      {
        guessedPlayer?.division === player?.division
          ? (obj.division = "green")
          : (obj.division = "white");
      }

      if (getAge(guessedPlayer) === getAge(player)) {
        obj.age = "green";
      } else if (
        !settingsData.hardMode &&
        getAge(guessedPlayer) - getAge(player) >= -2 &&
        getAge(guessedPlayer) - getAge(player) <= 2
      ) {
        obj.age = "yellow";
      } else {
        obj.age = "white";
      }

      {
        guessedPlayer?.pos === player?.pos
          ? (obj.pos = "green")
          : (obj.pos = "white");
      }

      {
        guessedPlayer?.ctry === player?.ctry
          ? (obj.ctry = "green")
          : (obj.ctry = "white");
      }

      {
        guessedPlayer?.shoots === player?.shoots
          ? (obj.shoots = "green")
          : (obj.shoots = "white");
      }

      if (getNumber(guessedPlayer)=== getNumber(player)) {
        obj.number = "green";
      } else if (
        !settingsData.hardMode &&
        getNumber(guessedPlayer) - getNumber(player) >= -5 &&
        getNumber(guessedPlayer) - getNumber(player) <= 5
      ) {
        obj.number = "yellow";
      } else {
        obj.number = "white";
      }
    }
    return obj;
  }

  for (let i = 0; i < myGuess[dayString].length; i++) {
    const guessedPlayer = players.find(
      (player) => myGuess[dayString][i].name === player.name
    );

    const obj = getShareLine(guessedPlayer);

    for (const [key, val] of Object.entries(obj)) {
      if (val === "green") {
        string += "🟩";
      } else if (val === "yellow") {
        string += "🟨";
      } else if (val === "white") {
        string += "⬜️";
      }
    }
    string += "\n";
  }

  const results = loadResultsData();

  const win = results[dayString].result === "won";

  const guessCount = win ? results[dayString].attempts : "X";

  const dayCount = Math.floor(
    Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
      "day"
    )
  );

  const title = `Dangle S2 #${dayCount} ${guessCount}/8`;
  let hardModeString = "";
  if (settingsData.hardMode === true) {
    hardModeString = " (Hard Mode)";
  } else {
    hardModeString = "";
  }
  const shareData = {
    title: "Dangle",
    text: title + hardModeString + "\n" + string + "\n",
    url: "https://www.dangle.games/",
  };

  async function handleShare() {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigator.clipboard.writeText(
        title +
          hardModeString +
          "\n" +
          string +
          "\n" +
          "https://www.dangle.games/"
      );
      const share = document.getElementsByClassName("share-button")[0];
      const text = document.createElement("p");
      text.innerHTML = "Copied to clipboard!";
      text.style.color = winner ? "#28d478" : "red";
      text.style.fontFamily = "Cairo, sans-serif";
      share.parentNode?.insertBefore(text, share.nextSibling);
      setTimeout(() => {
        document
          .getElementsByClassName("share-button")[0]
          .parentNode?.removeChild(text);
      }, 2000);
      return;
    }
  }

  return (
    <button
      className="share-button"
      style={{ backgroundColor: winner ? "#28d478" : "red" }}
      onClick={handleShare}
    >
      Share My Result
    </button>
  );
}
