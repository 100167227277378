export interface Guess {
  name: string;
}

export function loadAllGuesses(): Record<string, Guess[]> {
  const storedGuesses = localStorage.getItem("guesses");
  return storedGuesses != null ? JSON.parse(storedGuesses) : {};
}

export function saveGuesses(dayString: string, guesses: Guess[]): void {
  const allGuesses = loadAllGuesses();
  localStorage.setItem(
    "guesses",
    JSON.stringify({
      ...allGuesses,
      [dayString]: guesses,
    })
  );
}

export function loadAllPlayoffGuesses(): Record<string, Guess[]> {
  const storedGuesses = localStorage.getItem("playoffGuesses23");
  return storedGuesses != null ? JSON.parse(storedGuesses) : {};
}

export function savePlayoffGuesses(dayString: string, guesses: Guess[]): void {
  const allGuesses = loadAllPlayoffGuesses();
  localStorage.setItem(
    "playoffGuesses23",
    JSON.stringify({
      ...allGuesses,
      [dayString]: guesses,
    })
  );
}




export function loadUnlimitedData(): Guess[] {
  const storedResults = localStorage.getItem("unlimited");
  return storedResults != null ? JSON.parse(storedResults) : [];
}

export function saveUnlimitedData(guess: Guess): void {
  const data = loadUnlimitedData()
  data.push(guess);
  localStorage.setItem("unlimited", JSON.stringify(data));
}