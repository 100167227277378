import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { players, getName } from "../../core/players2023";
import { PlayerInput } from "./../PlayerInput";
import { Guesses } from "./GuessesUnlimited";
import { SettingsData } from "../../hooks/useSettings";
import { getDayString, useTodays } from "../../hooks/useTodays";
import "../../styles/Search.css";
import { GameOverModal } from "./GameOverModalUnlimited";
import "../../styles/header.css";
import { SilhouetteModal } from "./SilhouetteModalUnlimited";
import { saveResultsData } from "./../../core/stats";
import ReactGA from "react-ga";
import { browserName } from "react-device-detect";
import { useUnlimited } from "./useUnlimited";
import { loadUnlimitedData } from "../../core/guess";
import "../../styles/unlimited.css";
import { ConfirmModal } from "./ConfirmModal";

const MAX_TRY_COUNT = 8;

interface GameProps {
  settingsData: SettingsData;
  updateSettings: (newSettings: Partial<SettingsData>) => void;
}

export function Unlimited({ settingsData, updateSettings }: GameProps) {
  const dayString = useMemo(
    () => getDayString(settingsData.shiftDayCount),
    [settingsData.shiftDayCount]
  );
  function getRanArr(lngth: number) {
    let arr: number[] = [];
    do {
      const ran = Math.floor(Math.random() * lngth);
      arr = arr.indexOf(ran) > -1 ? arr : arr.concat(ran);
    } while (arr.length < lngth);

    return arr;
  }

  if (
    localStorage.getItem("randomArray") === null ||
    localStorage.getItem("randomArray") === "[]"
  ) {
    localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
  }
  const data = JSON.parse(localStorage.getItem("randomArray") || "{}");

  const numberArray = data.map(Number);

  if (localStorage.getItem("currentIndex") === null) {
    localStorage.setItem("currentIndex", "0");
  }

  const currentIndex = JSON.parse(localStorage.getItem("currentIndex") || "{}");

  if (localStorage.getItem("currentStreak") === null) {
    localStorage.setItem("currentStreak", "0");
  }

  if (localStorage.getItem("bestStreak") === null) {
    localStorage.setItem("bestStreak", "0");
  }

  const loadedCurrentStreak = JSON.parse(
    localStorage.getItem("currentStreak") || "{}"
  );


  const [player, setPlayer] = useState(players[numberArray[+0]]);
  
  

  const addUnlimitedGuess = useUnlimited();

  const playerInputRef = useRef<HTMLInputElement>(null);

  const guesses = loadUnlimitedData();
     
  const [currentGuess, setCurrentGuess] = useState("");

  const [currentStreak, setCurrentStreak] = useState(+loadedCurrentStreak);

  const [winner, setWinner] = useState(false);
  const [gameOverModalOpen, setGameOverModalOpen] = useState(false);

  const [silhouetteModalOpen, setSilhouetteModalOpen] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [isDisabled, setDisabled] = useState(false);

  const gameEnded =
    guesses.length === MAX_TRY_COUNT ||
    guesses[guesses.length - 1]?.name === player?.name;

  if (`${browserName}` === "Safari" || `${browserName}` === "Mobile Safari") {
    if (
      document.getElementsByClassName("suggestions-dropdown")[0] !== undefined
    ) {
      document
        .getElementsByClassName("suggestions-dropdown")[0]
        .classList.add("not-safari");
    }
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (player == null) {
        return;
      }
      e.preventDefault();
      const guessedPlayer = players.find(
        (item) => getName(item) === currentGuess
      );

      ReactGA.event({
        category: "Buttons",
        action: "Guess Player",
        label: "Guess player button clicked",
        value: 1,
      });

      if (guessedPlayer == null) {
        document
          .getElementsByClassName("search-bar")[0]
          .classList.add("red-border");
        const p = document.createElement("p");
        p.innerHTML = "Invalid player name, try again!";
        p.style.color = "red";
        p.style.fontFamily = "Cairo, sans-serif";

        document
          .getElementsByClassName("search-bar")[0]
          .parentNode?.appendChild(p);
        setTimeout(() => {
          document
            .getElementsByClassName("search-bar")[0]
            .classList.remove("red-border");
          document
            .getElementsByClassName("search-bar")[0]
            .parentNode?.removeChild(p);
        }, 2000);

        return;
      }

      const newGuess = {
        name: currentGuess,
      };

      addUnlimitedGuess(newGuess);
      setCurrentGuess("");

      if (newGuess.name === getName(player)) {
        setWinner(true);
        setGameOverModalOpen(true);
        setDisabled(true);
        const current = JSON.parse(
          localStorage.getItem("currentStreak") || "{}"
        );
        localStorage.setItem("currentStreak", JSON.stringify(current + 1));

        // localStorage.setItem("currentStreak", JSON.stringify(localStorage.getItem("currentStreak")))
        // setPlayer(players[numberArray[+0]])
        // localStorage.removeItem("unlimited")
        // currentIndex++;
        // localStorage.setItem("currentIndex", currentIndex)
      } else if (
        newGuess.name !== getName(player) &&
        guesses.length === MAX_TRY_COUNT - 1
      ) {
        // saveResultsData(dayString, { attempts: 8, result: "fail" });
        setWinner(false);
        setDisabled(true);
        setGameOverModalOpen(true);

        const bestStreak = JSON.parse(
          localStorage.getItem("bestStreak") || "{}"
        );
        const current = JSON.parse(
          localStorage.getItem("currentStreak") || "{}"
        );

        if (bestStreak < current) {
          localStorage.setItem("bestStreak", current);
        }
      }
    },
    [addUnlimitedGuess, player, currentGuess]
  );

  useEffect(() => {
    const player = players[numberArray[currentIndex]];
    const guesses = loadUnlimitedData();
    if (
      guesses.length >= 1 &&
      player &&
      guesses[guesses.length - 1].name === getName(player)
    ) {
      setWinner(true);
      setDisabled(true);
      setGameOverModalOpen(true);

    } else if (
      player &&
      guesses.length === MAX_TRY_COUNT &&
      guesses[guesses.length - 1].name !== getName(player)
    ) {
      setWinner(false);
      setDisabled(true);
      setGameOverModalOpen(true);
    }
  }, []);

  function gameOverModalHandler() {
    setGameOverModalOpen(true);
  }

  function inGameModalHandler() {
    setSilhouetteModalOpen(true);
  }

  function handleNewGame() {
    setConfirmModalOpen(true);
  }

  function nextGame() {
    let data = JSON.parse(localStorage.getItem("randomArray") || "{}");

    let numberArray = data.map(Number);

    numberArray.shift();

    const current = JSON.parse(localStorage.getItem("currentStreak") || "{}");

    const bestStreak = JSON.parse(localStorage.getItem("bestStreak") || "{}");

    setWinner(false);

    if (numberArray.length == 0) {
      localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
      data = JSON.parse(localStorage.getItem("randomArray") || "{}");
      numberArray = data.map(Number);
    } else {
      localStorage.setItem("randomArray", JSON.stringify(numberArray));
    }

    // localStorage.setItem("randomArray", JSON.stringify(numberArray))
    localStorage.setItem("currentStreak", JSON.stringify(0));
    localStorage.removeItem("unlimited");
    setDisabled(false);
    setTimeout(() => {
      setPlayer(players[numberArray[0]]);
    }, 300);
  }

  function nextPlayer() {
    // const data = JSON.parse(localStorage.getItem('randomArray') || '{}');

    // const numberArray = data.map(Number);

    // numberArray.shift()

    // localStorage.setItem("randomArray", JSON.stringify(numberArray))

    let data = JSON.parse(localStorage.getItem("randomArray") || "{}");

    let numberArray = data.map(Number);

    numberArray.shift();

    if (numberArray.length == 0) {
      localStorage.setItem("randomArray", JSON.stringify(getRanArr(600)));
      data = JSON.parse(localStorage.getItem("randomArray") || "{}");
      numberArray = data.map(Number);
    } else {
      localStorage.setItem("randomArray", JSON.stringify(numberArray));
    }

    localStorage.removeItem("unlimited");
    setDisabled(false);
    setWinner(false);
    setPlayer(players[numberArray[0]]);
  }

  return (
    <div className="game-container">
      <div className="search-bar-container">
        <GameOverModal
          isOpen={gameOverModalOpen}
          winner={winner}
          guessNumber={guesses.length}
          close={() => setGameOverModalOpen(false)}
          settingsData={settingsData}
          player={player}
          setPlayer={setPlayer}
          setDisabled={setDisabled}
          setWinner={setWinner}
        />
        <SilhouetteModal
          isOpen={silhouetteModalOpen}
          close={() => setSilhouetteModalOpen(false)}
          settingsData={settingsData}
          player={player}
        />

        <ConfirmModal
          isOpen={confirmModalOpen}
          close={() => setConfirmModalOpen(false)}
          settingsData={settingsData}
          setPlayer={setPlayer}
          setDisabled={setDisabled}
          setWinner={setWinner}
        />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-container">
              <PlayerInput
                inputRef={playerInputRef}
                currentGuess={currentGuess}
                setCurrentGuess={setCurrentGuess}
                disabled={isDisabled}
                placeholder={
                  gameEnded
                    ? "Round over!"
                    : "Guess " + (guesses.length + 1) + " of " + MAX_TRY_COUNT
                }
              />
              <button
                className="search-button"
                type="submit"
                disabled={isDisabled}
              >
                Guess
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="buttons-container">
   

            {gameEnded && winner && <button className="main-toggle-button" onClick={handleNewGame}>
              Restart Game
            </button>}

            {!gameEnded && <button className="main-toggle-button" onClick={handleNewGame}>
              Restart Game
            </button>}

            {gameEnded && !winner && <button className="main-toggle-button" onClick={nextGame}>
              New Game
            </button>}

            {gameEnded && (
              <a className="modal-reveal-link" onClick={gameOverModalHandler}>
                Show Results
              </a>
            )}

            {!gameEnded && (
              <a className="modal-reveal-link" onClick={inGameModalHandler}>
                Show Silhouette
              </a>
            )}

            <button
              disabled={!gameEnded || !winner}
              className={
                gameEnded && winner ? "main-toggle-button" : "main-toggle-button disabled"
              }
              onClick={nextPlayer}
            >
              Next Player
            </button>


          </div>

          <div className="streak-main-container">
            <div className="streak-item">
              <div className="streak-data">
                {localStorage.getItem("currentStreak")}
              </div>
              <div className="streak-label">Current Streak</div>
            </div>

            <div className="streak-item">
              <div className="streak-data">
                {localStorage.getItem("bestStreak")}
              </div>
              <div className="streak-label">Best Streak</div>
            </div>
          </div>

      <Guesses
        rowCount={MAX_TRY_COUNT}
        guesses={guesses}
        settingsData={settingsData}
        playerInputRef={playerInputRef}
        player={player}
      />
    </div>
  );
}
